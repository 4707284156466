import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../Provider/NavbarProvider'
import { fetchRegions } from '../services/fetchRegions';
import LandingNav from '../components/LandingNav';
import { useLocation } from 'react-router-dom';

function HomeLayout({ children }) {
  const {query, setQuery, setFirst} = useContext(Context)
  const [regions, setRegions] = useState([]);
  const localtion = useLocation()


  useEffect(() => {
    setFirst(true)
    setTimeout(() => {
      setFirst(false)
    }, 3000)

  }, [localtion.pathname])

  useEffect(() => {
    const getRegions = async () => {
      const regions = await fetchRegions();
      setRegions(regions);
    }
    getRegions();
  }, [])

  if((localtion.pathname.includes('campaign-detail') && window.innerWidth <= 768) ||
    localtion.pathname === '/welcome' ||
    localtion.pathname === '/login' ||
    localtion.pathname.startsWith('/signup')
  
  )
  return (
    <>
    {children}
    </>
  )

  return (
    <>
         <LandingNav setQuery={setQuery} query={query} regions={regions} />
         {children}
    </>
  )
}

export default HomeLayout

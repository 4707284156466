import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft } from 'react-icons/fa'
import { Link, useLocation, useNavigate } from 'react-router-dom'

function Payment() {
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
   const status = queryParams.get('status') === 'true'

    return (
        <div className='flex justify-center items-center flex-1 h-screen'>
      
            {status ?
                <div className='flex justify-center items-center gap-4 flex-col'>
                    <h2 className='font-bold text-3xl text-themePink text-center'>{t('paymmentCompleted')}</h2>
                    <Link to='/manage' className='flex w-full justify-center items-center py-2 border-[1px] border-themeGrey-70 rounded-lg text-white text-sm flex-1 bg-themeGrey-50/70 font-bold cursor-pointer'>
                            {t('got_tomanage')}
                        </Link>
                </div>
                :
                <div className='flex justify-center items-center gap-4 flex-col'>
                    <h2 className='font-bold text-3xl text-themePink text-center'>{t('paymentCompleted')}</h2>
                    <div className='flex gap-2 w-full'>
                        <div onClick={() => navigate(-1)} className='flex justify-center items-center py-2 border-[1px] border-themeGrey-70 rounded-lg text-white text-sm flex-1 bg-themeGrey-50/70 font-bold cursor-pointer'>
                            {t('TryAgain')}
                        </div>
                        <Link to='/manage' className='flex justify-center items-center py-2 border-[1px] border-themeGrey-70 rounded-lg text-white text-sm flex-1 bg-themeGrey-50/70 font-bold cursor-pointer'>
                            {t('cancel')}
                        </Link>
                    </div>
                </div>
            }
        </div>
    )
}

export default Payment

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tab from '../Tab'
import { getCategories } from '../../services/getCategories'
import InputGroup from '../InputGroup'
import Select from '../Select'
import Guide from '../Guide'
import Button from '../Button'
import { createUpdateCampaign } from '../../services/createUpdateCampaign'
import { Context } from '../../Provider/LangaugeProvider'

function Basic({ campaignData, setActive, setCampaignID }) {
    const { siteLang } = React.useContext(Context)
    const [campaign, setCampaign] = useState(campaignData ?? {
        campaign_type: 'Product',
        campaign_option: 'Review',
        campaign_method: "Smart",
        paymentProccessed: false
    })
    const [categories, setCategories] = React.useState([])
    const [t] = useTranslation("global")
    const [activeBtn, setActiveBtn] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const onChange = (e) => {
        setCampaign({
            ...campaign,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async() => {
        setLoading(true)
        setError(null)
        const response = await createUpdateCampaign(campaign, 'Basic')
        const res = await response.json()
        if(response?.status === 200){
            setCampaignID(res.campaign._id)
            localStorage.setItem('campaign_id', res.campaign._id)
            setActive(2)
        }
        else if(response?.status !== 200){
            setError(res.message)
        }
        else{
            setError("Some error occured, please try again")
        }

        setLoading(false)
    }

    useEffect(() => {
        const categoryoption = async () => {
            const res = await getCategories(true, siteLang )
            setCategories(res)
            const beautyCategory = res.filter(r => r.key.toLowerCase() === 'beauty')[0]
            console.log(res);
            
            if(campaignData?.category)
                setCampaign(prevData => ({...prevData, category: campaignData.category}))
            else if(beautyCategory){
                setCampaign(prevData => ({...prevData, category: beautyCategory.value}))
            }
        }
        categoryoption()
    }, [])    

    useEffect(() => {
        if(campaignData){
            setCampaign(campaignData)
        }
    }, [campaignData])

    
    useEffect(() => {
        if(campaign.campaign_type === "Product"){
            setCampaign({
                ...campaign,
                campaign_option: 'Review',
            })
        }
    }, [campaign.campaign_type])

    useEffect(()=>{
        if(campaign?.campaign_option && campaign?.category && campaign?.campaign_method && campaign?.campaign_type)
            setActiveBtn(true)
        else
            setActiveBtn(false)
    }, [campaign])

    return (
        <div className='flex flex-col gap-10 py-6 sm:pt-0 relative sm:pb-20'>
            <div className='flex flex-col gap-1 px-6'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("campaign_type")}</h3>
                <p className='text-xs text text-themeBlack-300'>{t('Selectcampaigntype')}</p>
                <div className='flex justify-start items-stretch gap-4 mt-2'>
                    <Tab
                        onChange={onChange}
                        name="campaign_type"
                        value={campaign?.campaign_type || ""}
                        text={t("Product")}
                        toValue={'Product'}
                        readOnly={campaign.paymentProccessed}
                    />
                    <Tab
                        onChange={onChange}
                        name="campaign_type"
                        value={campaign?.campaign_type || ""}
                        text={t("Store")}
                        toValue={'Store'}
                        readOnly={campaign.paymentProccessed}
                    />
                   
                </div>
            </div>
            <div className='w-[55%] -my-4  px-6 sm:w-full'>
                <Guide heading={t("Guide")} text={t("cate_guide")} />
            </div>
            <div className='w-[55%] flex flex-col gap-1.5  px-6  sm:w-full'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("Category")}</h3>
                <Select
                    options={categories}
                    name={"category"}
                    onChange={onChange}
                    placeholder={t("category_placeholder")}
                    value={campaign?.category?._id ? campaign?.category._id : campaign?.category}
                    simple={true}
                    activeVal={campaign?.category ? true : false}
                    readOnly={campaign.paymentProccessed}
                />
            </div>
            <hr className='w-full h-1 border-t-0 -my-5 border-b-[2px] border-themeGrey-70 shadow-sm' />
            <div className='flex flex-col gap-1  px-6'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("expMeth")}</h3>
                <p className='text-xs text text-themeBlack-300'>{t('selexpMeth')}</p>
                <div className='flex justify-start items-stretch gap-4 mt-2'>
                    <Tab
                        onChange={onChange}
                        name="campaign_option"
                        value={campaign?.campaign_option || ""}
                        text={t("pexp")}
                        toValue={'Review'}
                        readOnly={campaign.paymentProccessed}
                    />
                    <Tab
                        onChange={onChange}
                        name="campaign_option"
                        value={campaign?.campaign_option || ""}
                        text={t("cexp")}
                        toValue={'Payback'}
                        readOnly={campaign.paymentProccessed}
                    />
                   {campaign.campaign_type === "Store" && <Tab
                        onChange={onChange}
                        name="campaign_option"
                        value={campaign?.campaign_option || ""}
                        text={t("iexp")}
                        toValue={'10%'}
                        readOnly={campaign.paymentProccessed}
                    />}
                </div>
            </div>
            <div className='flex flex-col gap-1  px-6'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("WheretoUpload")}</h3>
                <p className='text-xs text text-themeBlack-300'>{t('Selectwheretoupload')}</p>
                <div className='flex justify-start items-stretch gap-4 mt-2'>
                    <Tab
                        onChange={onChange}
                        name="campaign_method"
                        value={campaign?.campaign_method || ""}
                        text={t("impact")}
                        toValue={'Smart'}
                        readOnly={campaign.paymentProccessed}
                    />
                    <Tab
                        onChange={onChange}
                        name="campaign_method"
                        value={campaign?.campaign_method || ""}
                        text={t("quick")}
                        toValue={'Quick'}
                        readOnly={campaign.paymentProccessed}
                    />
                    <Tab
                        onChange={onChange}
                        name="campaign_method"
                        value={campaign?.campaign_method || ""}
                        text={t("Manual")}
                        toValue={'Manual'}
                        readOnly={campaign.paymentProccessed}
                    />
                </div>
            </div>
     
            {error && <p className='text-red-600 text-sm -mt-5 sm:mb-3 px-6'>{error}</p>}
            <div className='w-1/3 -mt-3 sm:mt-0 px-6 sm:w-[130px] sm:p-3 sm:absolute sm:-top-[116px] sm:-right-0  '>
                <Button text={t("save")} active={activeBtn} disabled={loading} onClick={onSubmit} />
            </div>

        </div>
    )
}

export default Basic

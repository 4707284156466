import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ProfileTabs from './ProfileTabs'
import InputGroup from './InputGroup'
import Button from './Button'
import { getCategories } from '../services/getCategories'
import { updateMyProfile } from '../services/updateMyProfile'
import { constraints, validateAddress, validateBio, validateBRegNo, validateComapnyName, validateName, validateNickname, validateNumber, validateRegistrationDate, validateTags, validateURL } from '../constants/validations'
import { updateUserSocials } from '../services/updateUserSocials'
import { toast } from 'react-toastify'
import Input from './Input'
import { getMyProfile } from '../services/getMyProfile'
import Nickname from './Nickname'
import Address from './Address'
import Guide from './Guide'
import { fetchRegions } from '../services/fetchRegions'
import { Context } from '../Provider/LangaugeProvider'
import { PLATFORMS } from '../constants'

function MyData() {
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')))
  const [t] = useTranslation("global")

  const [active, setActive] = React.useState(user?.role === 'customer' ? 0 : 1)

  useEffect(() => {
    // if (user?.approved === false) {
    //   if (user.socials && Object.keys(user.socials).length > 0) {
    //     setActive(0);
    //   } else if (user.address) {
    //     setActive(2);
    //   } else if (user.registration_date) {
    //     setActive(1);
    //   } else {
    //     setActive(0);
    //   }
    // }

    const getData = async () => {
      const data = await getMyProfile()
      setUser(data)
    }

    getData()

  }, [active])

  return (
    <div className='flex flex-col gap-4 sm:gap-0'>

      <div className='px-5 pt-6 pb-6 flex flex-col gap-6 bg-white rounded-lg border-[1px] border-themeGrey-500 overflow-hidden sm:border-none sm:pt-0'>
        <ProfileTabs user={user} setActive={setActive} active={active} />
        <div className='flex flex-col gap-6'>
          {active === 0 && <Form1 _user={user} setActive={setActive} />}
          {active === 1 && <Form2 _user={user} setActive={setActive} />}
          {active === 2 && <Form3 _user={user} setActive={setActive} />}

        </div>
      </div>
    </div>
  )
}

export default MyData


const Form1 = ({ _user, setActive }) => {
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')))
  const [t] = useTranslation("global")
  const [loading, setLoading] = React.useState(false)
  const [activeBtn, setActiveBtn] = React.useState(false)
  const [error, setError] = React.useState(null)

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onSubmit = async () => {
    setLoading(true)
    setError(null)
    const res = await updateMyProfile(user, 'basic')
    if (res === true) {
      toast.success("Profile updated successfully")
      if (!_user.approved)
        setActive(1)
    }
    else {
      setError(res.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (
      validateComapnyName(user.company_name ?? '') &&
      validateBRegNo(user.business_reg_no ?? '') &&
      new Date(user.dob) < new Date() &&
      validateName(user.name ?? '') &&
      validateNumber(user.phone ?? '')
    )
      setActiveBtn(true)
    else
      setActiveBtn(false)
  }, [user])

  return (
    <div className='flex flex-col gap-4 sm:gap-5 relative  sm:pb-16'>
      <span className='w-[80%] sm:w-full'>
        <Guide heading={t("basic_guid")} text={t("basic_guide_text")} />
      </span>

      <InputGroup
        label={t("email") + " *"}
        type="email"
        required={true}
        value={user?.email}
        disabled={true}
        readOnly={true}
        flex="flex-col gap-1.5"
      >
      </InputGroup>
      <InputGroup
        label={t("name") + " *"}
        type="text"
        required={true}
        value={user?.name}
        name="name"
        onChange={onChange}
        flex="flex-col gap-1.5"
        placeholder={t("Profile_title_placeholder")}
      >
        <p className='text-xs text-themeGrey-50'>{t("profile_title_constraint")}</p>
      </InputGroup>
      <InputGroup
        label={t("phone") + " *"}
        type="phone"
        required={true}
        value={user?.phone}
        name="phone"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
      </InputGroup>
      <InputGroup
        label={t("dob") + " *"}
        type="date"
        required={true}
        value={user?.dob?.substr(0, 10)}
        name="dob"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
      </InputGroup>
      {user.role === 'customer' && <>
        <h3 className='text-base font-semibold text-themeBlack-500 mt-5 sm:my-2 mb-3'>{t("c_info")}</h3>
        <InputGroup
          label={t("c_name")}
          type="text"
          required={true}
          value={user?.company_name}
          name="company_name"
          onChange={onChange}
          placeholder={t("c_name_placeholder")}
        />
        <InputGroup
          label={t("c_designation")}
          type="select"
          required={true}
          value={user?.company_designation}
          name="company_designation"
          onChange={onChange}
          options={[{ value: "Executives", label: "Executives" }, { value: "Employee", label: "Employee" }, { value: "Agency", label: "Agency" }]}
          placeholder={t("Select")}
        />
        <InputGroup
          label={t("b_reg_no")}
          type="text"
          required={true}
          value={user?.business_reg_no}
          name="business_reg_no"
          onChange={onChange}
          placeholder={t("XXXXX-XXXXXX-XX")}
        />

      </>}
      {/* <InputGroup
        label={t("c_address")}
        type="text"
        required={true}
        value={user?.company_address}
        name="company_address"
        onChange={onChange}
      />
      <InputGroup
        label={t("c_contact")}
        type="phone"
        required={true}
        value={user?.company_contact}
        name="company_contact"
        onChange={onChange}
      />
      <InputGroup
        label={t("reg_date")}
        type="date"
        required={true}
        value={user?.registration_date}
        name="registration_date"
        onChange={onChange}
      /> */}
      {error && <p className='text-red-600 text-sm'>{error}</p>}
      <div className='w-1/3 mt-2 sm:w-full sm:fixed sm:left-0 sm:bottom-0'>
        <div className='sm:px-4 sm:py-4 sm:bg-white'>
          <Button text={t("save_")} active={activeBtn} disabled={loading} onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
}

const Form2 = ({ _user, setActive }) => {
  const { siteLang } = React.useContext(Context)
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')))
  const [t] = useTranslation("global")
  const [loading, setLoading] = React.useState(false)
  const [activeBtn, setActiveBtn] = React.useState(false)
  const [categories, setCategories] = React.useState([])
  const [error, setError] = React.useState(null)
  const [regions, setRegions] = useState([]);
  const [selectedStete, setSelectedState] = useState(null);


  const onChange = (e) => {
    if (e.target.name === 'state') {
      setUser({ ...user, state: e.target.value, city: '' })
    }
    else
      setUser({ ...user, [e.target.name]: e.target.value })
  }

  const onSubmit = async () => {
    setLoading(true)
    setError(null)
    const res = await updateMyProfile(user, 'info')
    await getMyProfile()
    if (res === true) {
      toast.success("Profile updated successfully")
      if (!_user.approved)
        setActive(2)
    }
    else {
      setError(res.message)
    }
    setLoading(false)
  }


  useEffect(() => {
    const categoryoption = async () => {
      const res = await getCategories(true, siteLang)
      setCategories(res)
    }
    const getRegions = async () => {
      const regions = await fetchRegions();
      const options = regions.map(region => ({ value: region.name, label: region.name, cities: region.cities }))
      const userState = regions.filter(r => r.name === user.state)[0]
      setSelectedState(userState)
      setRegions(options);
    }
    getRegions();
    categoryoption()
  }, [])


  useEffect(() => {
    if (
      validateNickname(user.nickname ?? '') &&
      validateBio(user.bio ?? '') &&
      validateTags(user.tags ?? '') &&
      user.category?.length > 1 &&
      validateAddress(user.address ?? '') &&
      user.state?.length > 1 &&
      (selectedStete?.cities.length > 0 ? user.city?.length > 1 : true)

    )
      setActiveBtn(true)
    else
      setActiveBtn(false)
  }, [user])



  return (
    <div className='flex flex-col gap-4 sm:gap-5 relative sm:pb-16'>
      <Nickname user={user} />


      <InputGroup
        label={t("ProfileDescription") + " *"}
        type="textarea"
        required={true}
        value={user?.bio}
        name="bio"
        onChange={onChange}
        flex="flex-col gap-1.5"
        textAreaSimple={true}
        placeholder={t("Profile_description_placeholder")}
      >
        <p className='text-xs text-themeGrey-50'>{t("profile_description_constraint")}</p>
      </InputGroup>
      <InputGroup
        label={t("tags") + " *"}
        type="tag"
        required={true}
        value={user?.tags}
        name="tags"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
        <p className='text-xs text-themeGrey-50'>{t("tags_constraint")}</p>
      </InputGroup>
      <InputGroup
        label={t("category") + " *"}
        type="select"
        required={true}
        value={user?.category}
        name="category"
        onChange={onChange}
        options={categories}
        flex="flex-col gap-1.5"
        placeholder={t("category_placeholder")}
      />
      <Address setUser={setUser} user={user} />
      <div className='flex gap-4'>
        <InputGroup
          label={t("state") + " *"}
          type="select"
          required={true}
          value={user?.state}
          name="state"
          onChange={(e) => { onChange(e); setSelectedState(e.target) }}
          options={regions}
          simple={true}
          flex="flex-col gap-1.5"
          placeholder={t("select_state")}
        />
        {selectedStete?.cities.length > 0 && <InputGroup
          label={t("city") + " *"}
          type="select"
          required={true}
          value={user?.city}
          name="city"
          onChange={onChange}
          simple={true}
          options={selectedStete.cities.map(c => ({ value: c.name, label: c.name }))}
          flex="flex-col gap-1.5"
          placeholder={t("select_city")}
        />}
      </div>

      <InputGroup
        label={t("Shopping_mall_url")}
        type="url"
        required={true}
        value={user?.shopping_mall_url}
        name="shopping_mall_url"
        onChange={onChange}
        flex="flex-col gap-1.5"
      >
        <p className='text-xs text-themeGrey-50'>{t("optional")}</p>
      </InputGroup>

      {user?.role === 'creator' && <>
        <h2 className='text-themeBlack-300 text-sm font-semibold'>{t('sPrefre')}</h2>
        <Input
          type="switch"
          placeholder={t("onlineServer")}
          value={user?.online_service}
          name="online_service"
          onChange={onChange}
        />
        <Input
          type="switch"
          placeholder={t("offlineServer")}
          value={user?.offline_service}
          name="offline_service"
          onChange={onChange}
        />
      </>}

      {error && <p className='text-red-600 text-sm'>{error}</p>}
      <div className='w-1/3 mt-2 sm:w-full sm:fixed sm:left-0 sm:bottom-0'>
        <div className='sm:px-4 sm:py-4 sm:bg-white'>
          <Button text={t("save_")} active={activeBtn} disabled={loading} onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
}

const Form3 = ({ _user, setActive }) => {
  const { siteLang } = React.useContext(Context)


  const { t } = useTranslation("global");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(_user.socials ? _user.socials : {
    youtube: {  account_address: '', },
    instagram: {  account_address: '', },
    tiktok: {  account_address: '', },
    naver: {  account_address: '', },
    x: {  account_address: '', },
  });

  const onChange = (e, platformKey, fieldName) => {
    const newValue = e.target.value;
    setData(prevData => ({
      ...prevData,
      [platformKey]: {
        ...prevData[platformKey],
        [fieldName]: newValue
      }
    }));
  };


  const onSubmit = async () => {
    setLoading(true);
    setError(null)
    const res = await updateUserSocials({ socials: data });
    if (res === true) {
      toast.success("Social medias updated successfully")
      if (!_user.approved)
        setActive(0)
    }
    else {
      setError(res.message)
    }
    setLoading(false);
  };

  return (
    <div className='flex flex-col gap-4 sm:pb-16 relative'>
      <span className='w-[80%]  sm:w-full'>
        <Guide heading={t("sns_guide")} text={t("sns_guide_text")} />
      </span>
      {PLATFORMS.map((platform, index) => (
        <div key={platform.key} className='flex flex-col gap-2'>
          <div className='flex justify-start items-start gap-2 mb-0'>
            <img src={platform.logo} className='w-8 h-8 rounded-full object-cover' alt={platform.title} />
            <p className='text-base font-semibold text-themeBlack-500 mt-1'>{siteLang === 'kor'? platform.kor_title: platform.title}</p>
          </div>
          <div className='flex justify-between items-end gap-2'>
            <InputGroup
              label={t("AccountAddress")}
              type="url"
              required={true}
              value={data[platform.key]?.account_address}
              name={`${platform.key}_account_address`}
              flex='flex-col gap-1.5'
              onChange={(e) => onChange(e, platform.key, 'account_address')}
            />
            <span className='w-1/4'>
              <Button text={t('register')} active={validateURL(data[platform.key]?.account_address)} disabled={loading} onClick={onSubmit} />
            </span>
          </div>
        </div>
      ))}
      {/* {error && <p className='text-red-600 text-sm'>{error}</p>}
      <div className='w-1/3 mt-5 sm:w-full sm:fixed sm:left-0 sm:bottom-0'>
        <div className='sm:px-4 sm:py-4 sm:bg-white'>
          <Button text={t("save")} active={true} disabled={loading} onClick={onSubmit} />
        </div>
      </div> */}
    </div>
  );
};

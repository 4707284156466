import { IMAGE_URL } from "../constants";

export const imageUpload = async (files) => {
    try {
        let formData = new FormData();

        if (Array.isArray(files)) {
            for (const file of files) {
                formData.append('file', file);
            }
        } else {
            formData.append('file', files);
        }


        const response = await fetch(`${IMAGE_URL}/`, {
            method: "POST",
            body: formData,
        });
        const res = await response.json();
        if (response.status === 200)
            return res.files;
        else
            return null;
    } catch (error) {
        console.error(error.message);
    }

    return null;
}
import { toast } from "react-toastify";
import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const  handleAutoApprove = async (id) => {
    
    try {
        const response = await fetch(`${BASE_URL}/campaign/handleAutoApprove/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
        if(response.status === 200){
           toast.success(res.message)
        }
        else
            toast.error(res.message)
    } catch (error) {
        console.log(error);
    }
    return []
}

import React from 'react'

function SecondaryButton({
  onClick = () => {},
  text = "Button",
  disabled = false,
  active = true,
}) {
  return (
    <button
      disabled={disabled}
      onClick={()=> {
        if(active && !disabled)
          onClick()
      }}
      className={`w-full border-[1px] ${!active ? "border-themeGrey-150 cursor-not-allowed text-themeGrey-150" : "border-themePink !cursor-pointer text-themePink"} py-3 px-1 rounded-lg outline-none font-bold tracking-wide text-sm disabled:opacity-50 disabled:!cursor-wait ${active && !disabled && "hover:scale-[0.98]"} transition-all ease-in duration-100`}>
      {text}
    </button>
  )
}

export default SecondaryButton

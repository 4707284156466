import React, { useEffect, useRef, useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import { sendMessage } from '../services/sendMessage';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { getChatTime } from '../utils/getChatTime';
import { formatDate } from '../utils/formatDate';

function SendMessage({ to,setRefresh, setMessageItems }) {
    const textareaRef = useRef(null);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation("global");

    useEffect(() => {
        if (textareaRef.current) {
            adjustHeight(textareaRef.current);
        }
        if (value === '')
            textareaRef.current.style.height = '40px';
    }, [value]);

    const adjustHeight = (textarea) => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    };

    const handleInput = (e) => {
        setValue(e.target.value);
    };


    const insertNew = (value) => {
        let currentUser = JSON.parse(localStorage.getItem('user'));
        let newMessage = {
            sender: {
                nickname: currentUser.nickname,
                profilePic: currentUser.profilePic
            },
            content: value,
            createdAt: new Date(),
        }
        setMessageItems(prevItems => {
            const formattedDate = formatDate(newMessage.createdAt); 
            const newItems = [...prevItems];
            const lastMessageItem = [...prevItems].reverse().find(item => item.type === 'message');
            const lastMessageDate = lastMessageItem ? formatDate(lastMessageItem.message.createdAt) : null;
        
            if (lastMessageDate === formattedDate) {
                newItems.push({ type: 'message', message: newMessage });
            } else {
                newItems.push({ type: 'date', date: formattedDate });
                newItems.push({ type: 'message', message: newMessage });
            }
            
            return newItems;
        });
    }
    

    const onSubmit = async (e) => {
        e.preventDefault();
        if (value === '' || loading) return;

        setLoading(true);
        const sent = await sendMessage({
            to: to,
            content: value
        })
        if (!sent)
            return;

        sessionStorage.clear()
        insertNew(value)
        setRefresh(prevState => !prevState);
        setValue('');
        setLoading(false);
    }

    return (
        <form
            onSubmit={onSubmit}
            className='px-4 pb-1 pt-3 absolute bottom-0 w-full bg-[#f5f5f5] sm:bg-white sm:pn-2 sm:pt-4'>
            <textarea
                ref={textareaRef}
                value={value}
                placeholder={t('type_message')}
                onChange={handleInput}
                className='send px-4 py-2 border-[1px] border-themeGrey-70 z-0 relative rounded-md w-full h-10 max-h-24 outline-none resize-none text-themeBlack-500 font-medium text-sm placeholder:text-themeBlack-500'
            />{
                loading ?
                    <AiOutlineLoading3Quarters className='rotating-element text-themePink text-2xl absolute bottom-3.5 z-10 right-5 cursor-pointer' />
                    :
                    <IoIosSend onClick={onSubmit} className='text-themeBlue text-3xl absolute bottom-3.5 z-10 right-5 cursor-pointer' />
            }
        </form>
    );
}

export default SendMessage;

import React from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Section1 from '../components/signup/Section1'
import Section2 from '../components/signup/Section2'
import Section3 from '../components/signup/Section3'
import Section4 from '../components/signup/Section4'
import Section5 from '../components/signup/Section5'
import Section0 from '../components/signup/Section0'

function SignUp() {
    return (
        <div className='w-full h-screen flex flex-1 bg-themeGrey-400/30 items-center justify-center overflow-hidden relative sm:bg-white'>
            <Link to='/' className='w-12 h-12 absolute left-16 top-3 sm:top-8 sm:left-8'>
                    <img src={'/logo.png'} className='w-10 h-10 object-contain' />
                </Link>
            {/* <div className='px-14 py-10 w-[60%] sm:hidden'>
                <div className='w-[70%] mt-20'>
                    <h2 className='text-white text-[45px] leading-[55px] mt-5 font-bold'>Top Local Influencer at one place</h2>
                    <p className='text-white font-semibold leading-6 text-lg w-[60%] mt-12'>Welcome to the premier marketplace where your brand’s vision comes to life! Create impactful campaigns and connect with top influencers to promote your products through authentic collaborations. Join us to elevate your brand and reach new audiences with powerful sponsored partnerships.</p>
                </div>
                <div className='absolute -bottom-14 left-[28%]'>
                    <img src={require('../assets/Group 2.png')} alt='refresh window' className='h-[70%] object-contain drop-shadow-2xl' />
                </div>
            </div> */}
            <div className='w-[35%] sm:w-full border-[1px] sm:border-none sm:mt-4 border-themeGrey-70 bg-white  rounded-[50px]'>
                
                <AnimatePresence>
                    <Routes>
                            <Route index element={<Navigate to='/signup/email' />} />
                            <Route path='/' element={<Section0 />} />
                            <Route path='/select-role' element={<Section1 />} />
                            <Route path='/ID' element={<Section2 />} />
                            <Route path='/email' element={<Section3 />} />
                            <Route path='/verify-email' element={<Section4 />} />
                            <Route path='/create-password' element={<Section5 />} />
                    </Routes>
                </AnimatePresence>
            </div>
        </div>
    )
}

export default SignUp


import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const fetchRegions = async () => {
    try {
        const response = await fetch(`${BASE_URL}/region`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        const res = await response.json()
        if (response.status === 200){
            return res.regions
        }
    } catch (error) {
        console.log(error);
    }
    return []
}
import React from 'react'
import { useTranslation } from 'react-i18next'
import PaymentStats from './PaymentStats'

function AccountStatus({user}) {
    const [t] = useTranslation("global")
  return (
    <div className='bg-white rounded-lg border-[1px] border-themeGrey-70 py-4 flex flex-col gap-4'>
        <h2 className='text-themeBlack-300 text-sm font-bold  px-5 '>{t('account_Status')}</h2>
        <PaymentStats user={user}/>
        <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-themeGrey-70 -mt-2' />
        <h2 className='text-themeBlack-300 text-sm font-bold  px-5 '>{t('order_Status')}</h2>
        <div className='flex flex-col gap-2 px-5 -mt-2'>
                <div className='flex justify-between items-center gap-4'>
                        <p className='text-xs font-medium text-themeBlack-300'>{t('active')}</p>
                        <p className='text-xs font-medium text-themeBlack-300'>{user.totalActiveOrders?.toLocaleString() || 0}</p>
                </div>
                <div className='flex justify-between items-center gap-4'>
                        <p className='text-xs font-medium text-themeBlack-300'>{t('Disputed_')}</p>
                        <p className='text-xs font-medium text-themeBlack-300'>{user.totalDisputedOrders?.toLocaleString() || 0}</p>
                </div>
                <div className='flex justify-between items-center gap-4'>
                        <p className='text-xs font-medium text-themeBlack-300'>{t('submitted_')}</p>
                        <p className='text-xs font-medium text-themeBlack-300'>{user.totalsubmiitedOrders?.toLocaleString() || 0}</p>
                </div>
                <div className='flex justify-between items-center gap-4'>
                        <p className='text-xs font-medium text-themeBlack-300'>{t('completed_')}</p>
                        <p className='text-xs font-medium text-themeBlack-300'>{user.totalCompletedOrders?.toLocaleString() || 0}</p>
                </div>
        </div>
    </div>
  )
}

export default AccountStatus

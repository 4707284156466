import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

function ChatSeleton() {
    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#E5E5E5">
            <div className='flex justify-start items- gap-4 px-6 py-3 border-b-[1px] border-themeGrey-70 cursor-pointer'>
                <Skeleton count={1} width={"48px"} height={"48px"} style={{ borderRadius: "100%" }} />
                <div className='flex flex-col gap-1 pt-1 w-full'>
                    <span className='flex justify-between items-center w-full' >
                        <Skeleton count={1} width={"150px"} height={"10px"} style={{ borderRadius: "12px" }} />
                        <Skeleton count={1} width={"30px"} height={"10px"} style={{ borderRadius: "12px" }} />
                    </span>
                    <Skeleton count={1} width={"100%"} height={"10px"} style={{ borderRadius: "12px" }} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default ChatSeleton

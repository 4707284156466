import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxCross1 } from 'react-icons/rx'
import Button from './Button'
import StarRatings from 'react-star-ratings';
import Textarea from './Textarea';
import Input from './Input';
import { postReview } from '../services/postReview';


function FeedbackPopup({ campaign, orders, setRefresh, isCreator = false }) {
    const [reviewPopup, setReviewPopup] = useState(false)
    const [all, setAll] = useState(true)
    const [loading, setLoading] = useState(false)
    const [activeBtn, setActiveBtn] = useState(false)
    const [stars, setStars] = useState(0)
    const [feedback, setFeedback] = useState('')
    const [t] = useTranslation('global')
    const _creators = orders.filter(o => !o.review_given).map(order => {
            return order.creator
    })

    const handleCreatorChange = (creatorId) => {
        setCreators((prevCreators) => {
            if (prevCreators.includes(creatorId)) {
                return prevCreators.filter(id => id !== creatorId);
            } else {
                return [...prevCreators, creatorId];
            }
        });
    };

    const [creators, setCreators] = useState(_creators.length > 0 ? _creators?.map(creator => creator?._id) : []);

    useEffect(() => {
        if (all) {
            setCreators(_creators.length > 0 ? _creators.map(creator => creator?._id) : [])
        } else {
            setCreators([])
        }
    }, [all])


    useEffect(() => {
        if (stars > 0 && feedback.length >= 3 && (isCreator  ||  creators.length > 0)) {
            setActiveBtn(true)
        }
        else {
            setActiveBtn(false)
        }
    }, [creators, stars, feedback])

    const post = async () => {
        setLoading(true)
        const posted = await postReview({
            campaign_id: campaign._id,
            creators: isCreator? [] : creators,
            stars,
            feedback
        })
        if(posted){
            setReviewPopup(false)
            setRefresh(state => !state)
        }
        setLoading(false)
    }

    return (
        <>
            {campaign.status !== 'Confirm Period' &&
                <p onClick={() => setReviewPopup(true)} className={`flex items-center justify-center px-4 py-2.5 text-center w-full text-sm border-[1px] shadow-sm cursor-pointer ${isCreator?  "bg-themePink text-white border-themePink"  :'border-themeGrey-70 text-themeBlack-200'} rounded-lg`}>
                    {t('Feedback')}
                </p>}
            {reviewPopup &&
                <div className='w-full left-0 top-0 z-[60] flex justify-center items-center h-screen overflow-hidden fixed bg-black/80'>
                    <div className={`w-[40%] sm:w-[90%] transition-all bg-white rounded-xl border-[1px] border-themeGrey-70`}>
                        <div className='flex justify-between items-center px-6 pb-4 pt-5'>
                            <h2 className='text-themeBlack-300 font-semibold text-lg sm:text-base leading-3'>{t('LeaveFeedback')}</h2>
                            <RxCross1 className='text-themePink text-lg cursor-pointer' onClick={() => setReviewPopup(false)} />
                        </div>
                        <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-b-themeGrey-100' />
                       {!isCreator && <div className='flex flex-col gap-2 px-6 py-2 relative w-full max-h-40 overflow-y-scroll scrollbar-hide'>
                            {_creators?.length > 1 && <span className='flex gap-2 justify-start items-center absolute right-4 top-2'>
                                <input onChange={() => setAll(!all)} type='checkbox' className='checkbox' checked={all} />
                                <label className='text-themeBlack-200 text-sm'>{'All'}</label>
                            </span>}
                            {_creators.map(creator => (
                                <span className='flex gap-2 justify-start items-center'>
                                    <input onChange={() => handleCreatorChange(creator._id)} type='checkbox' className='checkbox' checked={creators.some(c => c.toString() === creator._id)} />
                                    <label className='text-themeBlack-200 text-sm'>{creator.name}</label>
                                </span>
                            ))}
                        </div>}

                        <div className={`px-6 pb-2 flex flex-col gap-2 ${isCreator && 'pt-2'}`}>
                            <span className='flex justify-between items-center'>
                                <p className='text-themeBlack-300 text-sm font-medium'>{t('StartRating')}</p>
                                <StarRatings
                                    rating={stars}
                                    changeRating={(newRating) => setStars(newRating)}
                                    numberOfStars={5}
                                    name='rating'
                                    starRatedColor="#FFC542"
                                    starEmptyColor="#cacaca"
                                    starDimension="20px"
                                    starHoverColor="#FFC542"
                                    starSpacing="2px"
                                />
                            </span>
                            <Input
                                placeholder={t('leeveFeedback')}
                                type='textarea'
                                onChange={(e) => setFeedback(e.target.value)}
                            />
                        </div>

                        <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-b-themeGrey-100' />
                        <div className='px-4 py-2 justify-end flex'>
                            <span className='w-1/3'>
                                <Button text={t('post')} disabled={loading} active={activeBtn} onClick={post} />
                            </span>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default FeedbackPopup

import React from 'react'
import { IMAGE_URL, PLATFORMS } from '../constants'
import { defaultCP } from '../utils/defaultCP'

function OrderCampaignHeader({ campaign }) {
  return (
    <div className='rounded-xl flex justify-start items-start sm:items-stretch gap-4 border-[1px] border-themeGrey-70 px-6 py-8 sm:p-4 bg-white sm:shadow-sm'>
    <div className='w-24 h-24 sm:w-28'>
        <img src={`${IMAGE_URL}/uploads/${campaign?.product_images[0]}` || defaultCP()} className='w-full h-full object-cover rounded-lg' />
    </div>
    <div className='w-[90%]'>
        <span className='flex justify-between items-center sm:flex-col sm:items-start sm:w-full sm:h-full'>
            <h2 className='text-base text-themeBlack-200 font-bold'>{campaign.campaign_title}</h2>
            <span className='flex justify-end items-start gap-2 sm:justify-between sm:w-full sm:flex-row-reverse'>
                <img src={PLATFORMS.filter(p => p.title === campaign?.platform)[0]?.logo} alt='refresh' className='w-5 h-5' />
                <p className='text-themePink bg-themePink/10 font-medium text-xs px-2 py-1 rounded-md'>{campaign?.category?.name}</p>
            </span>
        </span>
        <p className='text-sm mt-1 text-themeGrey-300 sm:hidden'>{campaign?.description.length > 240 ? `${campaign?.description.substr(0, 240)}...` : campaign?.description}</p>
    </div>
</div>
  )
}

export default OrderCampaignHeader

import { BASE_URL } from "../constants"
import { getToken } from "../utils/getToken";

export const updateMyProfile = async (data, type) => {
    try {
        const response = await fetch(`${BASE_URL}/user/updateMyProfile?type=${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            },
            body: JSON.stringify(data)
        })
    
        const res = await response.json()
        if (response.status === 200){
            return true;
        }
        return res
    } catch (error) {
        console.log(error);
    }
}
export const formatDate = (date) => {
    const now = new Date();
    const messageDate = new Date(date);
    const diffInDays = Math.floor((now - messageDate) / (1000 * 60 * 60 * 24));

    if (diffInDays < 1) {
        return 'Today';
    } else if (diffInDays === 1) {
        return 'Yesterday';
    } else {
        return messageDate.toISOString().split('T')[0];
    }
};
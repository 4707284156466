import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { VscVerifiedFilled } from 'react-icons/vsc'
import { formateDate } from '../utils/formateDate'
import Loader from './Loader'
import ReviewCard from './ReviewCard'
import { BASE_URL } from '../constants'
import { getToken } from '../utils/getToken'
import PaymentStats from './PaymentStats'
import { getMyProfile } from '../services/getMyProfile'
import AccountStatus from './AccountStatus'

function MyPage() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user') || '{}'))
    const [t] = useTranslation('global')
    const [reviews, setReviews] = useState(null)


    useLayoutEffect(() => {
        const getMyReviews = async () => {
            try {
                const response = await fetch(`${BASE_URL}/user/myreviews`,{
                    method:'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'token': getToken()
                    }
                })
    
                const data = await response.json()
                setReviews(data?.reviews || [])
                
            } catch (error) {
                console.log(error);
                
            }
        }

        const myProfile = async() => {
            const data = await getMyProfile()
            setUser(data)
        }
        myProfile()
        getMyReviews()
    }, [])

    return (
        <div className='flex flex-col gap-4 sm:gap-4 sm:px-6 sm:pb-24'>
            <div className='py-3 px-3  bg-themeBlue/10 rounded-md flex justify-between items-center'>
                <p className='text-themeBlue text-sm'>{user?.basic_verified ? t("basicVerfied") : t("basicNotVerified")}</p>
                {user?.basic_verified && <VscVerifiedFilled className='text-themeBlue text-xl' />}
            </div>
            <div className='py-3 px-3  bg-themeBlue/10 rounded-md flex justify-between items-center -mt-2'>
                <p className='text-themeBlue text-sm'>{user?.info_verified ? t("profile_verified") : t("profile_not_verified")}</p>
                {user?.info_verified && <VscVerifiedFilled className='text-themeBlue text-xl' />}
            </div>
            <div className='py-3 px-3  bg-themeBlue/10 rounded-md flex justify-between items-center -mt-2'>
                <p className='text-themeBlue text-sm'>{user?.sns_verified ? t("sns_verified") : t("sns_not_verified")}</p>
                {user?.sns_verified && <VscVerifiedFilled className='text-themeBlue text-xl' />}
            </div>

            <div className='px-5 pt-6 pb-6 sm:py-4 flex flex-col gap-8 sm:gap-1.5 sm:bg-themeGrey-400/70 bg-white rounded-lg border-[1px] border-themeGrey-500 overflow-hidden sm:border-none'>
                <div className='flex justify-between items-center gap-14 sm:flex-col sm:gap-1.5'>
                    <div className='flex justify-between items-center w-1/2 sm:w-full'>
                        <p className='text-xs font-medium text-themeBlack-400'>{t('myLevvel')}:</p>
                        <p className='text-xs font-medium text-themeBlack-400'>{user.level}</p>
                    </div>
                    <div className='flex justify-between items-center w-1/2 sm:w-full'>
                        <p className='text-xs font-medium text-themeBlack-400'>{t('myCTI')}:</p>
                        <p className='text-xs font-medium text-themeBlack-400'>{user.ctiScore}</p>
                    </div>
                </div>
                <div className='flex justify-between items-center gap-14 sm:flex-col sm:gap-1.5'>
                    <div className='flex justify-between items-center w-1/2 sm:w-full'>
                        <p className='text-xs font-medium text-themeBlack-400'>{user.role === 'creator' ? t('TotalCompltedOrders') : t('totalCompletedCampaigns')}:</p>
                        <p className='text-xs font-medium text-themeBlack-400'>{user.role === 'creator' ? (user.totalCompletedOrders || 0) : (user.totalCompletedCampaigns || 0)}</p>
                    </div>
                    <div className='flex justify-between items-center w-1/2 sm:w-full'>
                        <p className='text-xs font-medium text-themeBlack-400'>{t('CreatedOn')}:</p>
                        <p className='text-xs font-medium text-themeBlack-400'>{formateDate(user.createdAt || new Date())}</p>
                    </div>
                </div>
            </div>

           {user.role === 'creator' && <AccountStatus user={user} />}

            <div className='flex flex-col gap-3'>
                <p className='text-themeBlack-300 font-bold text-sm'>{t('reviews')}</p>
                <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-themeGrey-70' />
                <div className='flex justify-start items-start gap-4 flex-wrap sm:flex-col'>
                    {!reviews && <Loader />}
                    {reviews?.length === 0 && <p className='text-xs text-themeGrey-300'>{t('no_activity')}</p>}
                    {reviews?.map((review, index) => (
                        <ReviewCard review={review} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MyPage

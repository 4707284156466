import React, { useEffect, useRef, useState } from 'react'
import { defaultCP } from '../utils/defaultCP';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { IMAGE_URL } from '../constants';
import { GoDotFill } from 'react-icons/go';

function ImageSlider({ images, classes }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);
    const handleArrowClick = (direction) => {
        if (direction === 'left' && currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        } else if (direction === 'right' && currentIndex < images.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handleDragEnd = (e) => {
        const slider = sliderRef.current;
        const currentScroll = slider.scrollLeft;
        const imageWidth = slider.offsetWidth;
        const threshold = imageWidth / 2;

        if (currentScroll % imageWidth >= threshold) {
            setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
        } else {
            setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        }
    };

    useEffect(() => {
        const slider = sliderRef.current;
        slider?.scrollTo({
            left: currentIndex * slider.offsetWidth,
            behavior: 'smooth',
        });
    }, [currentIndex]);
    return (
        <>
            {images.length === 0 && <img src={defaultCP()} alt='refresh window' className={`${classes} object-cover overflow-hidden rounded-lg sm:h-72 sm:w-full`} />}
            {images?.length > 0 && <div className="relative overflow-hidden">
                <div
                    ref={sliderRef}
                    className={`${classes} flex overflow-x-scroll whitespace-nowrap scrollbar-hide overflow-hidden rounded-lg slider sm:h-72 sm:w-full ${window.location.pathname !== '/' && !window.location.pathname.includes('detail') && '!w-[225px] md:w-48 sm:!w-full' }`}
                    onMouseUp={handleDragEnd}
                    onTouchEnd={handleDragEnd}
                >
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={`${IMAGE_URL}/uploads/${image}`}
                            className='w-full h-auto object-cover flex-shrink-0'
                            alt='refresh window'
                        />
                    ))}
                </div>

                {images.length > 1 && <>
                    <div className='absolute w-full bottom-2'>
                        <div className='flex justify-center items-center gap-0'>
                            {images.map((_, index) => (
                                <GoDotFill key={index} className={`${currentIndex === index ? "text-white" : "text-themeGrey-150"} text-[12px]`} />
                            ))}
                        </div>
                    </div>
                    <button
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleArrowClick('left') }}
                        className={`absolute left-1.5 bg-white/90 hover:bg-white hover:scale-105 top-[47%] outline-none transform border-[1px] border-themeGrey-100 rounded-full p-1.5 px-1.5 ${currentIndex === 0 ? 'opacity-50 cursor-default' : ''
                            }`}
                        disabled={currentIndex === 0}
                    >
                        <MdKeyboardArrowLeft className='text-sm' />
                    </button>
                    <button
                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleArrowClick('right') }}
                        className={`absolute right-1.5 bg-white/90 hover:bg-white hover:scale-105 top-[47%] outline-none transform border-[1px] border-themeGrey-100 rounded-full p-1.5 px-1.5 ${currentIndex === images.length - 1 ? 'opacity-50 cursor-default' : ''
                            }`}
                        disabled={currentIndex === images.length - 1}
                    >
                        <MdKeyboardArrowRight className='text-sm' />
                    </button>
                </>}
            </div>}
        </>
    )
}

export default ImageSlider

import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { PiChat, PiChatLight, PiUser, PiWalletLight } from "react-icons/pi";
import { PiBellSimpleLight } from 'react-icons/pi';
import Notification from './Notification';
import { Context } from '../Provider/NotificationProvider'
import { IoCreateOutline } from "react-icons/io5";
import { LuWallet2 } from "react-icons/lu";
import { FaList, FaPlus } from 'react-icons/fa';

function Navbar() {
    const { t } = useTranslation("global")
    const user = JSON.parse(localStorage.getItem('user'))
    const localtion = useLocation()
    const [notificationPopup, setNotificationPopuo] = React.useState(false)
    const { notifications } = useContext(Context)

    const [isNavVisible, setIsNavVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY) {
            setIsNavVisible(false);
        } else {
            // Scrolling up
            setIsNavVisible(true);
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <>
            {/* PC */}
            <div className='fixed sm:hidden w-full top-0 left-0 border-b-[1px] border-themeGrey-100 bg-white flex justify-center items-center z-50'>
                <div className='w-[70%] md:w-[90%] py-3 flex justify-between items-center'>
                    <div className='flex justify-start items-center gap-20'>
                        <img src={'/logo.png'} className='w-10 h-10 object-contain' />
                        <nav className='flex justify-between items-center gap-4'>
                            <NavLink to='/' className='text-sm cursor-pointer text-themeGrey-300 hover:text-themePink transition-all ease-in duration-150'>{t("home")}</NavLink>
                            {user?.role === 'customer' && <>
                                <NavLink to="/campaigns/create" className='text-sm text-themeGrey-300 hover:text-themePink transition-all ease-in duration-150'>{t("create")}</NavLink>
                                <NavLink to="/manage" className='text-sm text-themeGrey-300 hover:text-themePink transition-all ease-in duration-150'>{t("manage")}</NavLink>
                            </>}
                            {user?.role === 'creator' && <NavLink to="/orders" className='text-sm text-themeGrey-300 hover:text-themePink transition-all ease-in duration-150'>{t("orders")}</NavLink>}
                            <NavLink to="/inbox" className='text-sm text-themeGrey-300 hover:text-themePink transition-all ease-in duration-150'>{t("inbox")}</NavLink>
                            {user?.role === 'creator' && <NavLink to="/wallet" className='text-sm text-themeGrey-300 hover:text-themePink transition-all ease-in duration-150'>{t("wallet")}</NavLink>}
                            <NavLink to="/profile" className='text-sm text-themeGrey-300 hover:text-themePink transition-all ease-in duration-150'>{t("Profile")}</NavLink>
                        </nav>
                    </div>
                    <div className='flex justify-end items-center gap-4'>
                        <span onClick={() => setNotificationPopuo(true)} className='relative cursor-pointer'>
                            {notifications.filter(n => n.read === false).length > 0 && <p className='text-white bg-themeBlue rounded-full absolute -top-2 p-1 text-[10px] -right-2 px-2'>{notifications.filter(n => n.read === false).length}</p>}
                            <PiBellSimpleLight className='text-themeBlue text-2xl' />
                        </span>
                        <button
                            onClick={() => {
                                localStorage.removeItem('token')
                                localStorage.removeItem('user')
                                window.location.href = '/'
                            }}
                            className='border-[1px] border-themeBlue py-1 px-4 text-sm text-themeBlue font-medium rounded-lg'>
                            {t("logout")}
                        </button>
                    </div>
                </div>
            </div>

            {/* mobile */}
            {
                (localtion.pathname === '/' ||
                    localtion.pathname.startsWith('/manage') ||
                    localtion.pathname.startsWith('/orders') ||
                    localtion.pathname === '/inbox' ||
                    localtion.pathname === '/wallet' ||
                    localtion.pathname === '/profile/my-page')
                &&
                user
                &&
                <div className={`hidden sm:flex flex-col fixed bottom-0 bg-white left-0 w-full border-t-[1px] z-50 border-themeGrey-70 transition-transform ${isNavVisible ? 'translate-y-0' : 'translate-y-full'} duration-150`}>
                    <div className='flex justify-evenly gap-4 items-center px-4 py-2 w-full mobile-nav'>
                       {user?.role === 'creator' &&
                        <NavLink to='/' className={`${localtion.pathname === '/' || localtion.pathname.startsWith('/campaign-detail') ? 'active' : ''} flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs`}>
                            <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.5424 8.58441L24.5424 8.58439L24.5372 8.58025L16.7532 2.35308L16.7522 2.35222C14.9727 0.934185 12.2226 0.9153 10.4461 2.34178C10.446 2.34187 10.4459 2.34196 10.4458 2.34206L2.66362 8.57966C2.66339 8.57985 2.66316 8.58003 2.66293 8.58021C1.98427 9.12195 1.46857 9.90626 1.15358 10.7323C0.838615 11.5582 0.701656 12.4853 0.845951 13.3378C0.846001 13.3381 0.84605 13.3384 0.8461 13.3387L2.34319 22.2975L2.34372 22.3006C2.74723 24.6521 4.91055 26.4876 7.29563 26.4876H19.8926C22.2555 26.4876 24.4524 24.6151 24.8561 22.2902L24.8569 22.2857L26.3543 13.3252L26.3543 13.3252L26.3557 13.316C26.4856 12.4721 26.3423 11.5514 26.0285 10.7303C25.7148 9.90936 25.2071 9.12636 24.5424 8.58441ZM13.7657 21.0143C13.7657 21.0554 13.7489 21.0983 13.7135 21.1337C13.6782 21.1691 13.6352 21.1859 13.5941 21.1859C13.553 21.1859 13.5101 21.1691 13.4747 21.1337C13.4393 21.0983 13.4225 21.0554 13.4225 21.0143V17.4491C13.4225 17.408 13.4393 17.3651 13.4747 17.3297C13.5101 17.2943 13.553 17.2775 13.5941 17.2775C13.6352 17.2775 13.6782 17.2943 13.7135 17.3297C13.7489 17.3651 13.7657 17.408 13.7657 17.4491V21.0143Z"
                                    stroke={localtion.pathname === '/' || localtion.pathname.startsWith('/campaign-detail') ? "#2DB400" : "#757575"} stroke-opacity="0.68" stroke-width="1.43944" />
                            </svg>
                            {t('home')}
                        </NavLink>}
                        <NavLink to='/inbox' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                            <PiChat className={`${localtion.pathname.startsWith('/inbox') ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-3xl`} />
                            {t('inbox')}
                        </NavLink>
                        {user?.role === 'customer' && <>
                            <NavLink to='/campaigns/create' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                                <FaPlus className={`${localtion.pathname.startsWith('/campaigns') ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-2xl`} />
                                {t("create")}
                            </NavLink>
                            <NavLink to='/manage' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                                <FaList className={`${localtion.pathname.startsWith('/manage') ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-2xl`} />
                                {t('manage')}
                            </NavLink>
                        </>}
                        {user?.role === 'creator' &&
                            <>
                                <NavLink to='/orders' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                                    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.03862 8.63601L1.03854 8.63763L0.752224 14.6025L0.75212 14.6048C0.671645 16.4021 1.34205 18.162 2.62028 19.4402L8.02444 24.8444C10.525 27.3449 14.5821 27.343 17.0927 24.8457L17.0941 24.8444L22.3285 19.61C22.329 19.6095 22.3294 19.609 22.3299 19.6086C24.8444 17.1195 24.8422 13.0598 22.3307 10.5609C22.3304 10.5606 22.3302 10.5603 22.3299 10.5601L16.9271 5.15726C15.652 3.88216 13.8922 3.19548 12.0879 3.28928C12.0874 3.28931 12.0868 3.28933 12.0863 3.28936L6.12666 3.57542C6.12629 3.57544 6.12592 3.57545 6.12555 3.57547C3.36883 3.70008 1.17646 5.89304 1.03862 8.63601ZM9.4993 14.7409C8.01 14.7409 6.78277 13.5223 6.78277 12.0244C6.78277 10.5264 8.01 9.30782 9.4993 9.30782C10.9886 9.30782 12.2158 10.5264 12.2158 12.0244C12.2158 13.5223 10.9886 14.7409 9.4993 14.7409ZM18.5703 16.3235L13.7984 21.0953C13.7777 21.116 13.758 21.1279 13.74 21.1351C13.7214 21.1426 13.6996 21.1471 13.6747 21.1471C13.6498 21.1471 13.628 21.1426 13.6094 21.1351C13.5914 21.1279 13.5717 21.116 13.551 21.0953C13.5209 21.0652 13.5022 21.0221 13.5022 20.9716C13.5022 20.9212 13.5209 20.8781 13.551 20.8479L18.3229 16.076C18.353 16.0459 18.3961 16.0272 18.4466 16.0272C18.497 16.0272 18.5402 16.0459 18.5703 16.076C18.6004 16.1062 18.6191 16.1493 18.6191 16.1997C18.6191 16.2502 18.6004 16.2933 18.5703 16.3235Z"
                                            stroke={localtion.pathname.startsWith('/orders') ? "#2DB400" : "#757575"} stroke-opacity="0.68" stroke-width="1.43843" />
                                        <path d="M17.1807 7.86144C19.2135 7.86144 20.8614 6.21352 20.8614 4.18072C20.8614 2.14791 19.2135 0.5 17.1807 0.5C15.1479 0.5 13.5 2.14791 13.5 4.18072C13.5 6.21352 15.1479 7.86144 17.1807 7.86144Z"
                                            fill={localtion.pathname.startsWith('/orders') ? "#2DB400" : "#757575"} fill-opacity="1" />
                                    </svg>
                                    {t('orders')}
                                </NavLink>
                                <NavLink to='/wallet' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                                    <PiWalletLight className={`${localtion.pathname.startsWith('/wallet') ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-3xl`} />
                                    {t('wallet')}
                                </NavLink>
                            </>}
                        <NavLink to='/profile/my-page' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                            <PiUser className={`${localtion.pathname.startsWith('/profile') ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-2xl`} />
                            {t('Profile')}
                        </NavLink>
                    </div>
                </div>}
            {notificationPopup && <Notification setNotificationPopuo={setNotificationPopuo} />}
        </>
    )
}

export default Navbar

import React, { useState } from 'react'
import ProfileBio from './ProfileBio'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function ProfileSideBar() {
    const [t] = useTranslation('global')
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
    return (
        <div className='flex justify-start items-start w-full flex-col gap-10'>
            <ProfileBio user={user} />

            <nav className='flex flex-col w-full bg-white rounded-lg overflow-hidden border-[1px] border-themeGrey-500'>
                <NavLink to="/profile/my-page" className='py-3 px-4 text-themeBlack-500 border-b-[1px] border-themeGrey-400 text-sm hover:text-themePink hover:bg-themeGrey-150/10'>{t('MyPage')}</NavLink>
                <NavLink to="/profile/my-data" className='py-3 px-4 text-themeBlack-500 border-b-[1px] border-themeGrey-400 text-sm hover:text-themePink hover:bg-themeGrey-150/10'>{t('myData')}</NavLink>
                <NavLink to="/profile/settings" className='py-3 px-4 text-themeBlack-500 border-b-[1px] border-themeGrey-400 text-sm hover:text-themePink hover:bg-themeGrey-150/10'>{t('setting')}</NavLink>
            </nav>
        </div>

    )
}

export default ProfileSideBar

import React from 'react'
import LayoutHeader from '../components/LayoutHeader'
import ProfileSideBar from '../components/ProfileSideBar'
import ProfileBio from '../components/ProfileBio'
import { FaArrowLeft } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { IoIosLogOut } from 'react-icons/io'

function ProfileLayout({ children }) {
    const user = JSON.parse(localStorage.getItem('user')|| '{}')
    const [t] = useTranslation('global')
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <div>
            <LayoutHeader title={"My Profile"} />
            <div className='flex justify-start items-start gap-16 h-full mt-5 sm:mt-0'>
                <div className='w-[30%] sm:hidden'>
                    <ProfileSideBar />
                </div>
                <div className='w-[55%] sm:w-full'>
                    {location.pathname !== '/profile/my-page' && <div className='hidden sm:flex relative justify-center items-center gap-5 mb-4 py-0 pt-5 px-4'>
                        <FaArrowLeft onClick={() => navigate('/profile/my-page')} className='bg-themePink absolute left-6 text-[28px] px-2 py-1 text-white rounded-full'/>
                        <h1 className='text-black text-lg font-bold'>{t('settings')}</h1>
                    </div>}
                    <div className='hidden sm:flex flex-col relative'>
                        {location.pathname === '/profile/my-page' && 
                        <>
                            <h2 className='text-lg font-bold text-black text-center px-6 pt-5 mb-4'>{t('MyPage')}</h2>
                            <span
                            onClick={() => {
                                localStorage.removeItem('token')
                                localStorage.removeItem('user')
                                window.location.href = '/'
                            }}
                            className='flex bg-[#F7F7F9] rounded-lg  px-2 py-3 absolute right-6 top-3 items-center gap-2'>
                                    <p className='text-xs text-themeBlack-200'>{t('logout')}</p>
                                    <IoIosLogOut className='text-themeBlack-300 text-lg'/>
                            </span>
                        </>
                        }
                        <ProfileBio user={user} />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default ProfileLayout

import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import SignUp from './pages/SignUp'
import { ToastContainer, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Welcome from './pages/Welcome';
import Login from './pages/Login';
import MainLayout from './layouts/MainLayout';
import MyProfile from './pages/MyProfile';
import { getMyProfile } from './services/getMyProfile';
import MyCampaigns from './pages/MyCampaigns';
import CreateCampaign from './pages/CreateCampaign';
import Inbox from './pages/Inbox';
import Landing from './pages/Landing';
import Campaign from './pages/Campaign';
import Profile from './pages/Profile';
import Orders from './pages/Orders';
import CustomerOrder from './pages/CustomerOrder';
import CreatorOrder from './pages/CreatorOrder';
import Payment from './pages/Payment';
import Wallet from './pages/Wallet';
import HomeLayout from './layouts/HomeLayout';

function App() {
  const user = JSON.parse(localStorage.getItem('user'))

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  const localtion = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [localtion])


  useEffect(() => {
    const isMobileDevice = () => {
      return /iPhone|iPad|iPod|Mobi|Android/i.test(navigator.userAgent);
    };

    const handleBeforeInstallPrompt = (e) => {
      if (isMobileDevice()) {
        e.preventDefault();
        setDeferredPrompt(e);
        setShowInstallPrompt(true);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleInstallClick = () => {
    const userAgent = window.navigator.userAgent;
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      alert('To install this app, tap the share icon and then "Add to Home Screen".');
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }

      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token') && !localStorage.getItem('newUser'))
      getMyProfile()
  }, [])


  return (
    <>
      {/* {showInstallPrompt || (window.innerWidth <= 768 && !window.matchMedia('(display-mode: standalone)').matches) ? 
      (
        <div className="bg-white flex justify-center items-center flex-col gap-2 h-screen">
          <img src={'/logo.png'} className='w-10 h-10 object-contain' />
          <p className='text-themeBlack-300 font-medium text-lg text-center'>Install this app for a better experience</p>
          <button className='text-themePink border-themePink px-4 py-2 text-base border-[1px] rounded-lg uppercase font-medium tracking-wider' onClick={handleInstallClick}>Install</button>
        </div>
      ) */}
        :
        <>
          {localStorage.getItem('token') && user ?
            <MainLayout>
              <Routes>
                <Route path='/profile' element={<Outlet />} >
                  <Route index element={<MyProfile />} />
                <Route path='*' element={<MyProfile />} />
                </Route>
                <Route path='/inbox/*' element={<Inbox />} />

                {user.role === 'customer' &&
                  <>
                    <Route path='/campaigns' element={<Outlet />} >
                      <Route index element={<Navigate to='/campaigns/create' />} />
                      <Route path='create' element={<CreateCampaign />} />
                      <Route path='edit/:_id' element={<CreateCampaign />} />
                      <Route path='*' element={<Navigate to='/campaigns/create' />} />
                    </Route>

                    <Route path='/manage' element={<Outlet />} >
                      <Route index element={<MyCampaigns />} />
                      <Route path=':name/:id' element={<CustomerOrder />} />
                      <Route path='*' element={<Navigate to='/manage' />} />
                    </Route>

                    <Route path='/payment-screen' element={<Payment />} />
                  </>
                }
                {user.role === 'creator' && <>
                  <Route path='/orders' element={<Outlet />}>
                    <Route index element={<Orders />} />
                    <Route path=':name/:orderId' element={<CreatorOrder />} />
                    <Route path='*' element={<Orders />} />
                  </Route>
                  <Route path='/wallet' element={<Wallet />} />
                </>
                }
                <Route index element={<Landing />} />
                <Route path='/campaign-detail/:name/:_id' element={<Campaign />} />
                <Route path='/:name' element={<Profile />} />
                <Route path='*' element={user.approved ? user.role === 'customer' ? <Navigate to='/campaigns' /> : <Navigate to="/profile" /> : <Navigate to="/profile" />} />
              </Routes>
            </MainLayout>
            :
            <>

            <Routes>
              <Route index element={
                <Landing />
              } />
              {/* <Route path="/welcome" element={<Welcome />} /> */}
              <Route path="/signup/*" element={<SignUp />} />
              <Route path="/login/*" element={<Login />} />
              <Route path='/campaign-detail/:name/:_id' element={<Campaign />} />
              <Route path='/:name' element={<Profile />} />
              <Route path='*' element={<Navigate to='/login' />} />
            </Routes>
              </>
          }


          <ToastContainer transition={Zoom} position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
        </>
        {/* } */}
    </>
  )
}

export default App


// src/serviceWorker.js

import { BASE_URL, VAPIDPublicKey } from "./constants";
import { getToken } from "./utils/getToken";

export async function registerSW() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        try {
            const registration = await navigator.serviceWorker.register('/service-worker.js');
            console.log('Service Worker registered with scope:', registration.scope);

            // Subscribe to push notifications
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(VAPIDPublicKey),
            });

            const response = await fetch(`${BASE_URL}/user/saveSW`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    token: getToken(),
                },
                body: JSON.stringify({
                    swScope: registration.scope,
                    device: window.innerWidth < 768 ? 'mobile' : 'web',
                    subscription, 
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to send service worker info to backend');
            }
        } catch (error) {
            console.error('Service Worker registration failed:', error);
        }
    } else {
        console.warn('Push messaging is not supported in this browser.');
    }
}

// Helper function to convert the VAPID key
function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export async function askPermissionSW() {
    try {
        const permissionResult = await Notification.requestPermission();
        if (permissionResult !== 'granted') {
            throw new Error('Notification permission not granted.');
        }
        console.log('Notification permission granted.');
        return true;
    } catch (error) {
        console.error('Failed to get notification permission:', error);
    }

    return false;
}

export async function unregisterSW() {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.ready;
            
            // Unsubscribe from push notifications
            const subscription = await registration.pushManager.getSubscription();
            if (subscription) {
                await subscription.unsubscribe();
                console.log('Push subscription unsubscribed.');
            }

            // Unregister the service worker
            const unregistration = await registration.unregister();
            if (unregistration) {
                console.log('Service Worker unregistered successfully.');
                return true;
            } else {
                console.error('Service Worker unregistration failed.');
                return false;
            }
        } catch (error) {
            console.error('Error during Service Worker unregistration:', error);
            return false;
        }
    }
    console.warn('Service Worker is not supported in this browser.');
    return false;
}

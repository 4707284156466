import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/getCroppedImg'; // Helper function for cropping
import { useTranslation } from 'react-i18next';
import { SlCloudUpload } from 'react-icons/sl';
import { imageUpload } from '../services/ImageUpload';
import { updateMyProfile } from '../services/updateMyProfile';
import { RxCross1, RxCross2 } from 'react-icons/rx';
import { toast } from 'react-toastify';
import { getMyProfile } from '../services/getMyProfile';

const ProductImages = ({ productImages, removeProductImage, data, setProductImages, setData }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const productRef = React.createRef();
    const [t] = useTranslation('global')
    const [cropping, setCropping] = useState(false);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            setProductImages([...productImages, croppedImage])

            const base64Response = await fetch(croppedImage);
            const blob = await base64Response.blob();


            setCropping(false);

            const fileName = 'cropped-image.jpg';
            const croppedImageFile = new File([blob], fileName, { type: 'image/jpeg' });

            setData((prevData) => ({
                ...prevData,
                productImage: [...prevData.productImage, croppedImageFile],
            }));

        } catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => setImageSrc(reader.result);
        }
        setCropping(true);
    };

    



    return (
        <div className=''>

            {cropping && <div className="absolute z-[50] w-[50%] h-96 sm:w-full sm:left-0 bg-black">
                <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={1} // For a circular crop, aspect ratio should be 1 (square)
                    // cropShape="round" // Makes the cropper circle
                    objectFit="contain" // Ensures image stays contained within the cropper
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                />
                <RxCross1 onClick={() => setCropping(false)} className='absolute cursor-pointer top-2 right-3 text-black bg-white rounded-full p-1 text-2xl z-20' />
                <button className='absolute z-20 bg-themePink text-sm p-1.5 px-3 rounded-lg text-white bottom-2 right-2' onClick={showCroppedImage}>{t("crop")}</button>
            </div>}

            <div className='flex flex-wrap justify-start gap-4'>
                {productImages.map((img, index) => (
                    <div key={index} className='w-80 sm:h-auto sm:w-full h-80 rounded-lg overflow-hidden relative shadow-sm border-[1px] border-themeGrey-150'>
                        <img src={img} alt='Product' className='w-full h-full object-cover' />
                        <span onClick={() => removeProductImage(img)} className='absolute top-1 right-1 bg-white shadow-lg px-3 py-2 rounded-lg text-xs flex justify-center items-center gap-1 cursor-pointer text-themePink'>
                            <RxCross2 className="text-themePink text-sm" />
                            {t("remove")}
                        </span>
                    </div>
                ))}
                {!data.paymentProccessed && <div onClick={() => productRef?.current?.click()} className='relative sm:h-auto  sm:w-full overflow-hidden !cursor-pointer flex justify-center items-center gap-1 flex-col w-80 h-80 rounded-lg border-[1px] border-themeBlue border-dashed bg-themeBlue/10'>
                    <SlCloudUpload className='text-themeBlue text-3xl !cursor-pointer' />
                    <p className='text-themeBlue text-center text-xs !cursor-pointer'>{t("upload_p_img")}</p>
                    <input accept='image/*' onChange={handleImageUpload} ref={productRef} type='file' className='absolute top-16 left-5 opacity-0' onClick={(e) => e.stopPropagation()} />
                </div>}
            </div>

        </div>
    );
};

export default ProductImages;


import { toast } from "react-toastify"
import { BASE_URL } from "../constants"
import { getToken } from "../utils/getToken"

export const ResendOtp = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('newUser'))
        const response = await fetch(`${BASE_URL}/user/resendOTP`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            },
            body: JSON.stringify({
                userId: user.email,
            })
        })
    
        const res = await response.json()
        if (response.status === 200){
           toast.success('OTP has been sent successfully');
        }
        else{
            toast.error(res.message);
        }
    } catch (error) {
        console.log(error);
    }
}
import React from 'react'
import Input from './Input'

function InputGroup({
    label = null,
    name = "",
    type = null,
    placeholder = "",
    icon = null,
    required = false,
    onChange = () => { },
    value,
    options = [],
    flex = "flex-row gap-5 ",
    solidLabel = null,
    children,
    textAreaSimple = false,
    simple = false,
    readOnly = false,
    disabled = false
}) {
  
  return (
    <div className={`flex ${flex} sm:flex-col sm:gap-1 w-full items-start relative`}>
        <div className='flex justify-between items-center gap-1 w-full'>
            {label && <label className='text-sm text-themeBlack-300'>{label}</label>}
            {solidLabel && <label className='text-base text-themeBlack-300 font-bold'>{solidLabel}</label>}
            {children}
        </div>
      <Input 
        name={name}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        icon={icon}
        required={required}
        onChange={onChange}
        value={value}
        options={options}
        readOnly={readOnly}
        textAreaSimple={textAreaSimple}
        simple={simple}
      />
    </div>
  )
}

export default InputGroup

import React, { useEffect, useRef, useState } from 'react'
import { deleteCampaign } from '../services/deleteCampaign';
import { cancelCampaign } from '../services/cancelCampaign';
import { duplicateCampaign } from '../services/duplicateCampaign';
import { Link } from 'react-router-dom';
import { MdCancel } from 'react-icons/md';
import { BsTrash2Fill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { IoIosArrowDown } from 'react-icons/io';
import { RiEditBoxFill, RiFileCopyFill } from "react-icons/ri";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { portoneRefundApi } from '../utils/portone';

function CampaignMenu({ campaign , getCampaigns }) {

    const [t] = useTranslation("global");
    const [menu, setMenu] = useState(false);
    const menuRef = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const _deleteCampaign = async (_id) => {
        const confirm = window.confirm(t('delete_campaign_confirm'));
        if (confirm) {
            await deleteCampaign(_id);
            getCampaigns()
        }
    }
    const _cancelCampaign = async (_id) => {
        const confirm = window.confirm(t('cancel_confirm'));
        if (confirm) {
            const refunded = await portoneRefundApi({
                paymentID: campaign.paymentId,
                refundAmount: campaign.paidAmount,
                totalPayment: campaign.paidAmount
            })
            if(refunded){
                await cancelCampaign(_id, campaign.paidAmount, campaign.paymentId);
                getCampaigns()
            }
        }
    }
   
    return (
        <>
            <button onClick={(e) => {e.stopPropagation(); e.preventDefault(); setMenu(true)}} className='outline-none border-[1px] border-themeGrey-70 sm:p-0 sm:border-none rounded-md p-1.5'>
                <IoIosArrowDown className='text-lg text-themeBlack-500 sm:hidden' />
                <HiOutlineDotsHorizontal className='hidden sm:flex text-lg text-themeBlack-500 ' />
            </button>
            {menu &&
                <div onClick={(e) =>{ e.preventDefault(); e.stopPropagation()}} ref={menuRef} className='absolute w-36 overflow-hidden glass border-[1px] border-themeGrey-100 z-40 shadow-md right-5 sm:right-2 rounded-md top-[110%]'>
                    {campaign.status === 'Incomplete' &&
                        <Link to={`/campaigns/edit/${campaign._id}`} onClick={() => setMenu(false)} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                            <RiEditBoxFill className="text-base text-themeBlue" />
                            {t('edit')}
                        </Link>}
                    {campaign.status === 'Paid' &&
                        <p onClick={() => _cancelCampaign(campaign._id)} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                            <MdCancel className="text-base text-themePink" />
                            {t('cancel')}
                        </p>}
                    {campaign.status === 'Incomplete' && <p onClick={() => { _deleteCampaign(campaign._id); setMenu(false) }} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                        <BsTrash2Fill className="text-base text-red-500" />
                        {t('delete')}
                    </p>}
                    <div className='absolute bottom-0 w-1/2 h-8 bg-themePink blur-3xl opacity-50 z-0' />
                </div>
            }
        </>
    )
}

export default CampaignMenu

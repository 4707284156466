import React from 'react'
import { GoDotFill } from 'react-icons/go'
import { MdOutlineCheck } from 'react-icons/md'
import { validatePassword } from '../constants/validations'
import { useTranslation } from 'react-i18next'

function PasswordChecker({ password}) {
    const validation = validatePassword(password)
    const { t } = useTranslation('global')
    return (
        <div className='flex flex-col w-full gap-2'>
            <div className='h-1 bg-themeGrey-100 w-full'>
                <div className={"h-full w-1/2 bg-themeYellow"} style={{width: `${validation.completionPercentage}%`}}>
                </div>
            </div>

            <div className='flex justify-start items-center gap-1.5'>
                {!validation.minLength ?
                    <GoDotFill className='text-themeBlue text-sm' />
                    :
                    <MdOutlineCheck className='text-themeBlue text-base' />}
                <p className={`text-sm font-normal ${validation.minLength ? "text-themeBlue" : "text-themeGrey-50"}`}>{t('least_8')}</p>
            </div>
            <div className='flex justify-start items-center gap-1.5'>
                {!validation.hasNumberOrSymbol ?
                    <GoDotFill className='text-themeBlue text-sm' />
                    :
                    <MdOutlineCheck className='text-themeBlue text-base' />}
                <p className={`text-sm font-normal ${validation.hasNumberOrSymbol ? "text-themeBlue" : "text-themeGrey-50"}`}>{t('numberorsymb')}</p>
            </div>
            <div className='flex justify-start items-center gap-1.5'>
                {!validation.hasUpperAndLowerCase ?
                    <GoDotFill className='text-themeBlue text-sm' />
                    :
                    <MdOutlineCheck className='text-themeBlue text-base' />}
                <p className={`text-sm font-normal ${validation.hasUpperAndLowerCase ? "text-themeBlue" : "text-themeGrey-50"}`}>{t('lowup')}</p>
            </div>
        </div>
    )
}

export default PasswordChecker

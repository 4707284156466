import React from 'react'
import { IMAGE_URL } from '../constants'
import { defaultPfp } from '../utils/defaultPfp'
import StarRatings from 'react-star-ratings'

function ReviewCard({ review }) {
    return (
        <div className='bg-white rounded-lg border-[1px] shadow-sm border-themeGrey-70 flex flex-col gap-3 px-6 py-4 w-full sm:px-4 sm:py-3'>
            <div className='flex justify-start items-start gap-4'>
                <img src={review.from_whom?.profilePic ? `${IMAGE_URL}/uploads/${review.from_whom?.profilePic}` : defaultPfp()} className='w-14 h-14 sm:h-10 sm:w-10 rounded-full bg-themePink/15 object-cover' />
                <div className='flex flex-col gap-2 sm:gap-1'>
                    <h2 className='text-themeBlack-300 font-semibold text-sm capitalize'>{review.from_whom.nickname}</h2>
                    <StarRatings
                        rating={review.stars}
                        numberOfStars={5}
                        name='rating'
                        starRatedColor="#FFC542"
                        starEmptyColor="#cacaca"
                        starDimension="20px"
                        starHoverColor="#FFC542"
                        starSpacing="2px"
                    />
                    <p className='text-themeBlack-200 text-sm mt-3 sm:mt-1'>{review.feedback}</p>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdArrowDropright } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";

const RegionSelect = ({ setRegionSelect, regions, setQuery, query }) => {
    const [selectedRegion, setSelectedRegion] = useState(query?.region);
    const [t] = useTranslation("global");
    let _state = query.region?.length > 0 ? regions.filter(r => query.region.includes(r.name))[0] : null
    const [state, setState] = useState(_state);
    const [City, setCity] = useState(query.region?.length > 0 ? _state?.cities.filter(c => query.region?.includes(c.name))[0]?.name : null);
    const [count, setCount] = useState(0);
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setRegionSelect(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };


    }, []);

    useEffect(() => {
        const _state = state?.name || ''
        const _city = City ? `> ${City}` : ''
        let value
        if (_state.length > 1 && query.region?.includes(_state))
            value = _state + ' ' + _city;
        else
            value = _state;



        setQuery((prev) => ({ ...prev, region: value }));
        setCount(count + 1);

    }, [state, City]);



    return (
        <div ref={menuRef} className='flex fixed w-[300px] sm:w-[90%] sm:left-[5%] sm:top-44 top-28 border-[1px] border-themeGrey-70 bg-white rounded-xl shadow-xl overflow-hidden z-50'>
            <div className='flex flex-col gap-2 px-4 py-2 w-full relative'>
                <RxCross1 onClick={() => setRegionSelect(false)} className='absolute top-4 right-4 cursor-pointer text-base text-themePink' />
                <div className='flex gap-8'>
                    <h2 className='text-sm text-themeBlack-300 font-medium'>{t("States")}</h2>
                    {state?.cities?.length > 0 && <h2 className='text-sm text-themeBlack-300 font-medium'>{t("cities")}</h2>}
                </div>
                <div className='min-h-20 max-h-72 flex flex-col gap-1 overflow-y-scroll w-full'>
                    {regions.map((region, index) => (
                        <p onClick={() => {
                            if (state?.name === region.name) {
                                setState(null);
                                setCity(null);
                            }
                            else
                                setState(region);
                        }} className={`text-base flex gap-0.5 items-center ${state?.name?.includes(region.name) ? 'text-themePink' : 'text-themeBlack-300'}`}>
                            {region.name}
                            {region.cities?.length > 0 && <IoMdArrowDropright className='text-sm' />}
                        </p>
                    ))}
                </div>
                {state?.cities?.length > 0 &&
                    <div className='absolute right-4 w-[70%] shadow-md bg-red top-8 border-[1px] border-themeGrey-70 rounded-lg px-4 py-2  bg-white min-h-20 max-h-64 flex flex-col gap-1 overflow-y-scroll'>
                        {state?.cities.map((city, index) => (
                            <p onClick={() => {
                                if (City === city.name)
                                    setCity(null);
                                else
                                    setCity(city.name);
                            }} className={`text-sm flex gap-0.5 items-center ${City?.includes(city.name) ? 'text-themePink' : 'text-themeBlack-300'}`}>
                                {city.name}
                            </p>
                        ))}
                    </div>}
            </div>
        </div>
    )
}


export default RegionSelect

import React, { useContext, useEffect, useState } from 'react'
import LandingNav from '../components/LandingNav'
import CampaignCard from '../components/CampaignCard'
import { getCampaigns } from '../services/getCampaigns'
import CampaignCardSkeleton from '../components/CampaignCardSkeleton'
import Navbar from '../components/Navbar'
import { fetchRegions } from '../services/fetchRegions'
import RegionSelect from '../components/RegionSelect'
import { useTranslation } from 'react-i18next'
import Footer from '../components/Footer'
import { TbArrowsDownUp } from 'react-icons/tb'
import MobileLandingNav from '../components/MobileLandingNav'
import SortPopup from '../components/SortPopup'
import { Context } from '../Provider/NavbarProvider'
import HomeLayout from '../layouts/HomeLayout'

function Landing() {

  const [campaigns, setCampaigns] = useState(null)
  const [loading, setLoading] = useState(false)
  const [regions, setRegions] = useState([]);
  const {query, setQuery} = useContext(Context)
  const [regionSelect, setRegionSelect] = useState(false)
  const [t] = useTranslation('global')
  const [sort, setSort] = useState(false)

  useEffect(() => {
    setCampaigns(null)
    setLoading(true)
    const _getCampaigns = async () => {
      const data = await getCampaigns(query)
      setCampaigns(data)
      setLoading(false)
    }

    const getRegions = async () => {
      const regions = await fetchRegions();
      setRegions(regions);
    }
    getRegions();
    _getCampaigns()
  }, [query])

  return (
    <HomeLayout>
      <div className='flex-1 sm:pb-[20px]'>
        <div className='hidden sm:flex z-10 justify-between items-center px-6 sticky pb-2'>
            {<h2 className='text-themeBlack-200 text-sm'>{campaigns?.length} {t('campaigns')} </h2>}
          <div className='flex justify-end gap-1'>
            {query.type === 'Store' && <div onClick={() => setRegionSelect(true)} className='flex border-[1px] border-themeGrey-70 text-themeBlack-300 text-xs rounded-md px-3 py-1.5'>
              {query.region?.length > 1 ? query.region : t('anywhere')}
            </div>}
            {sort ? <div onClick={() => setSort(false)} className='flex border-[1px] relative justify-between items-center gap-3 border-themeGrey-70 text-themeBlack-300 text-xs rounded-md px-3 py-1.5'>
              {t('sort')} <TbArrowsDownUp className='text-themePink' />
            {sort &&  <SortPopup setQuery={setQuery} query={query} setSort={setSort} scrolled={false}/>}
            </div>
            :
            <div onClick={() => setSort(true)} className='flex border-[1px] relative justify-between items-center gap-3 border-themeGrey-70 text-themeBlack-300 text-xs rounded-md px-3 py-1.5'>
              {t('sort')} <TbArrowsDownUp className='text-themePink' />
            </div>}
          </div>
          {regionSelect && <RegionSelect regions={regions} setRegionSelect={setRegionSelect} setQuery={setQuery} query={query} />}
        </div>
        <span className='hidden sm:flex'>
          {/* <Navbar /> */}
          <MobileLandingNav setQuery={setQuery} query={query}/>
        </span>
        <div className='min-h-screen'>
          <div className='z-0 w-full px-16 pb-20  pt-8 flex justify-start items-stretch gap-x-5 gap-y-7 flex-wrap  sm:pb-20 sm:px-6 sm:flex-col'>
            {campaigns?.length === 0 && <p className='text-themeBlack-500 text-xs font-medium text-center w-full'>OOPs! No campaigns found</p>}
            {campaigns?.map((campaign, index) => (
              <>
                <CampaignCard key={index} campaign={campaign} />
              </>
            ))}

            {(!campaigns || loading) && [1, 1, 1, 1, 1]?.map((campaign, index) => (
              <CampaignCardSkeleton />
            ))}

          </div>
        </div>
          <Footer />
      </div>
    </HomeLayout>
  )
}

export default Landing

import React from 'react'

function LayoutHeader({ title }) {
    return (
        <div className='flex justify-start items-start w-full pt-14 sm:hidden'> 
            <h1 className='text-themeBlack-400 font-semibold text-base'>
                {title}
            </h1>
        </div>
    )
}

export default LayoutHeader

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../Button';
import { BASE_URL, CHANNEL_KEY, MERCHANT_ID, STORE_ID } from '../../constants';
import { useNavigate } from 'react-router-dom';
import * as PortOne from "@portone/browser-sdk/v2";

function Payment({ campaignData, setActive }) {
  const grand_total = campaignData?.paymentAmount ?? 0
  const total = Number((grand_total / 1.1).toFixed(2))
  let vat = Number((total * 0.1).toFixed(2));
  const [t] = useTranslation("global")
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false)
  const [activeBtn, setActiveBtn] = React.useState(false)


  const portOne = async () => {
    setLoading(true)
    let config = {
      storeId: STORE_ID,
      merchantId: MERCHANT_ID,
      paymentId: campaignData.paymentId,
      orderName: campaignData._id,
      totalAmount: Math.ceil(campaignData.paymentAmount),
      payMethod: "CARD",
      currency: "KRW",
      pgProvider: "TOSSPAYMENTS",
      channelKey: CHANNEL_KEY,
    }
    if (window.innerWidth < 768)
      config.redirectUrl = `${BASE_URL}/payment`

    try {
      const response = await PortOne.requestPayment(config);

      const responses = await fetch(`${BASE_URL}/payment`, {
        method: 'Post',
        headers: {
          'Content-Type': 'Application/json'
        },
        body: JSON.stringify(response)
      })

      const res = await responses.json()
      if (responses.status === 200) {
        navigate(res.url)
      }

    } catch (error) {
      console.error(error)
      navigate(`/payment-canceled`)
    }
    setLoading(false)
  }


  useEffect(() => {
    let total = 0
            if (["Payback", "Review"].includes(campaignData.campaign_option)) {
                total += Number(
                    (campaignData.media_fee * campaignData.creator_count)
                )
            }
            
            if (campaignData.campaign_option === "Review") {
                total += Number(
                    (campaignData.delivery_fee * campaignData.creator_count)
                )
            }
            if (campaignData.campaign_option === "Payback") {
                total += Number(
                    (campaignData.payback_amount * campaignData.creator_count)
                )
            }
            if (campaignData.campaign_option === "10%") {
                total += Number(
                    (campaignData.creator_count * campaignData.tenPercent_fee)
                )
            }
            let vat = Number((total * 0.1).toFixed(2));
            let grandTotal = Number(total + vat);
            

    if(campaignData.creator_count > 0 && grandTotal === campaignData.paymentAmount && grandTotal > 0 && !campaignData.paymentProccessed)
      setActiveBtn(true)
    else
      setActiveBtn(false)
  }, [campaignData])

  return (
    <div className='flex flex-col gap-0 py-6 px-6 w-[100%] sm:pt-0 sm:pb-20  sm:h-full'>
      <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("paycharges")}</h3>
      <p className='text-sm text-themeGrey-300'>{t('textPaid')}</p>

      <div className='flex w-full flex-col mt-5 gap-1.5'>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('media')}</span>
          <span className='font-medium capitalize'>{campaignData.platform}</span>
        </p>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('creators')}</span>
          <span className='font-medium'>{campaignData.creator_count}</span>
        </p>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('creator_level')}</span>
          <span className='font-medium'>{campaignData.creatorLevel}</span>
        </p>
        {["Payback", "Review"].includes(campaignData.campaign_option) && <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('AdmediaFee')}</span>
          <span className='font-medium'>{campaignData.media_fee}</span>
        </p>}
        {campaignData.campaign_option === 'Review' && <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('delivery_fee')}</span>
          <span className='font-medium'>{campaignData.delivery_fee}</span>
        </p>}
        {campaignData.campaign_option === 'Payback' && <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('payback')}</span>
          <span className='font-medium'>{campaignData.payback_amount}</span>
        </p>}
        {campaignData.campaign_option === '10%' && <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('tenPerc')}</span>
          <span className='font-medium'>{campaignData.tenPercent_fee}</span>
        </p>}

        <p className='flex justify-between items-center text-sm text-themeGrey-300 mt-8'>
          <span className='font-medium'>{t('total')}</span>
          <span className='font-medium'>{Number(total).toLocaleString()}</span>
        </p>
        <p className='flex justify-between items-center text-sm text-themeGrey-300'>
          <span className='font-medium'>{t('vat')}</span>
          <span className='font-medium'>{Number(vat).toLocaleString()}</span>
        </p>
        <p className='flex justify-between items-center text-sm text-themeBlack-300 border-y-[1px] py-2 border-themeGrey-70'>
          <span className='font-bold'>{t('GrandTotal')}</span>
          <span className='font-bold'>{Number(grand_total).toLocaleString()}</span>
        </p>
      </div>

      <span className='flex justify-end mt-4 sm:mt-0 sm:p-3 sm:w-[130px] sm:absolute sm:top-0 sm:right-0 x-[60] w-full'>
        <span className='w-1/3 sm:w-full sm:px-0'>
          <Button disabled={loading} active={activeBtn} onClick={portOne} text={t('proceed')} />
        </span>
      </span>
    </div>
  )
}

export default Payment

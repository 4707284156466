import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Button from "../Button"
import Input from "../Input"
import { motion } from 'framer-motion'
import { FaEnvelope } from "react-icons/fa"
import { validateEmail } from "../../constants/validations"
import { RegisterUser } from "../../services/RegisterUser"
import { useTranslation } from "react-i18next"

const Section3 = ({ }) => {
    const [activeBtn, setActiveBtn] = useState(false)
    const [data, setData] = useState(JSON.parse(localStorage.getItem('newUser') ?? "{}"))
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [checkbox, setCheckbox] = useState(false)
    const [popup, setPopup] = useState(false)
    const [t] = useTranslation('global')

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const navigate = useNavigate()

    const onCLick = async () => {
        setLoading(true)
        localStorage.setItem('newUser', JSON.stringify(data))
        setError(null)
        const response = await RegisterUser(data)
        if (response && response.status) {
            navigate('/signup/verify-email')
        }
        else {
            if (response.message === 'Email already exists') {
                setPopup(true)
            }
            else {
                setError(response.message)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        if (validateEmail(data.email ?? '') && checkbox) {
            setActiveBtn(true)
        }
        else
            setActiveBtn(false)
    }, [data, checkbox])


    return (
        <div
            className='w-full bg-white rounded-[50px] shadow-sm h-auto sm:h-screen flex justify-center items-center sm:rounded-none sm:shadow-none'>
            <div className='flex justify-center items-start sm:items-center sm:justify-start sm:py-24 flex-col gap-2 pt-14 pb-5 px-20 sm:px-0 relative h-full w-full sm:w-[80%]'>
                <h1 className='text-[32px] leading-[42px] font-Pretendard font-bold text-themePink text-center w-full sm:text-3xl'>Verify Your Email</h1>
                <p className='text-themeGrey-300 text-base font-medium text-center w-full sm:text-sm'>Please enter a email address for verification</p>
                <div className='flex justify-center items-center flex-col w-full gap-2 mt-8 pb-10 sm:pb-7'>
                    <Input
                        type={"email"}
                        placeholder={t('your_email')}
                        required={true}
                        name={"email"}
                        value={data.email}
                        onChange={onChange}
                        icon={<FaEnvelope className="text-base text-themeGrey-150 mt-1" />}
                    />
                    <div className='flex justify-start items-center w-full gap-3'>
                        <input onChange={() => setCheckbox(!checkbox)} type="checkbox" className='checkbox sm:mt-0.5' checked={checkbox} />
                        <p className='sm:text-sm text-themeBlack-400'>{t('i_agree')} <a target="_blank" href='https://code365.notion.site/c69a4e3bbbda4ece906d4d1407e366dd?pvs=4' className='text-themePink underline'>{t('termsOfUse')}</a> {t('and')} <a target="_blank" href="/https://code365.notion.site/2ece263385e348f2b2c095f646a799e5?pvs=4" className='text-themePink underline'>{t('privacyPolicy')}</a>.</p>
                    </div>
                    {error && <p className='text-sm text-center text-red-600 sm:text-xs'>{error}</p>}
                </div>
                <div className=' w-[100%] sm:relative sm:px-0'>
                    <Button text={t("save")} onClick={onCLick} active={activeBtn} disabled={loading} />
                </div>

                <div className='w-[100%] px-0 sm:px-0 mt-32 sm:mt-0'>
                    <p
                        onClick={() => {
                            navigate('/login')
                        }}
                        className='sm:text-center cursor-pointer border-[1px] border-themeGrey-70 py-3 px-2.5 rounded-lg  flex justify-center items-center gap-1 text-sm text-themeBlack-400'>Already have an account? <Link to="/login" className='text-themePink'>Sign In</Link>.</p>
                </div>
            </div>
            {popup && <Popup setPopup={setPopup} />}
        </div>
    )
}

export default Section3


const Popup = ({ setPopup }) => {
    const navigate = useNavigate()
    const [t] = useTranslation("global")
    return <div className="h-full w-full fixed top-0 left-0 flex justify-center items-center bg-black/80">
        <div onClick={() => setPopup(false)} className="w-full h-full absolute top-0 left-0 z-10"></div>

        <div className="w-1/3 sm:w-[90%] sm:px-8 sm:py-6 p-8 px-16 bg-white rounded-3xl flex justify-center items-center relative z-30 flex-col">
            <h2 className="text-2xl text-center text-themePink font-bold">{t('IDexist')}</h2>
            <p className="text-themeBlack-300 mt-2 mb-12 text-base font-medium text-center">{t('IDnotexist')}</p>
            <Button text={t('pleaseLogin')} active={true} onClick={() => {
                setPopup(false)
            }}
            />
        </div>
    </div>
}
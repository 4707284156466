import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import { useLocation } from 'react-router-dom'
import { getMyProfile } from '../services/getMyProfile'

function MainLayout({ children }) {

  const location = useLocation()

  useEffect(() => {
    getMyProfile()
  }, [location])

  if (location.pathname === '/' ||
    location.pathname.startsWith('/campaign-detail') ||
    !(
      location.pathname.startsWith('/profile') ||
      location.pathname.startsWith('/inbox') ||
      location.pathname.startsWith('/campaigns') ||
      location.pathname.startsWith('/manage') ||
      location.pathname.startsWith('/orders') ||
      location.pathname.startsWith('/wallet') 
    )
  ) {
    return <>{children}</>
  }

  return (
    <div className='bg-themeGrey-400/30 flex justify-center items-start min-h-screen sm:bg-white'>
      <Navbar />
      <div className={`w-[70%] md:w-[90%] mt-14 ${!location.pathname.startsWith('/inbox') && 'pb-10'} sm:w-full sm:mt-0 sm:pb-0`}>
        {children}
      </div>
    </div>
  )
}

export default MainLayout

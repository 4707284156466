import React, { useState, useRef, useEffect } from 'react';

const OTPInput = ({ setOtpValue }) => {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputsRef = useRef([]);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        let newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to next input field if value is entered
        if (element.value && index < 5) {
            inputsRef.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                inputsRef.current[index - 1].focus();
            }
        }
    };

    useEffect(() => {
        setOtpValue(otp.join(''));
    }, [otp])

    return (
        <div className='flex justify-center items-center w-full gap-2'> 
            {otp.map((data, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={otp[index]}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputsRef.current[index] = el)}
                    className='w-[45px] h-[45px] text-center border border-themeGrey-150 outline-none text-black rounded-lg'
                    
                />
            ))}
        </div>
    );
};

export default OTPInput;

import React, { useEffect } from 'react'
import SettingsTabs from '../components/SettingsTabs'
import Input from '../components/Input'
import { useTranslation } from 'react-i18next'
import { updateMyNotification } from './updateMyNotification'
import { RiArrowRightSFill } from "react-icons/ri";
import { askPermissionSW, isSWRegistered, registerSW, unregisterSW } from '../serviceWorker'
import { Link } from 'react-router-dom'
import { IoIosLogOut } from 'react-icons/io'

function ProfileSettings() {
    const [active, setActive] = React.useState(0)
    const [callAPI, setCallAPI] = React.useState(0)
    const user = JSON.parse(localStorage.getItem("user"))
    const [notification, setNotification] = React.useState(user.notification)

    const [t] = useTranslation("global")
    const onChange = async (e) => {
        setNotification({
            ...notification,
            [e.target.name]: e.target.value
        })
        setCallAPI(true)
    }



    useEffect(() => {
        if (callAPI) {
            // updateMyNotification(notification)
        }
    }, [callAPI, notification])

    return (
        <div className='px-5 pt-6 pb-6 flex-col gap-6 flex bg-white rounded-lg border-[1px] border-themeGrey-500 overflow-hidden min-h-80 sm:pt-0 sm:border-none'>
            <SettingsTabs active={active} setActive={setActive} />
            <div className='flex flex-col gap-4'>
                {active === 0 && <Auto notification={notification} onChange={onChange} />}
                {active === 1 && <Push notification={notification} onChange={onChange} />}
                {active === 2 && <Board />}
            </div>
        </div>
    )
}

export default ProfileSettings


const Auto = ({ notification, onChange }) => {
    const [t] = useTranslation("global")
    return (
        <>
            <h2 className='text-themeBlack-300 text-base font-bold -mb-2'>{t("Recommendations1")}</h2>
            <Input
                type="switch"
                placeholder={t("general")}
                value={notification.online_general}
                name="online_general"
                onChange={onChange}
            />
            <Input
                type="switch"
                placeholder={t("impact")}
                value={notification.online_impact}
                name="online_impact"
                onChange={onChange}
            />
            <h2 className='text-themeBlack-300 text-base font-bold -mb-2'>{t("Recommendations2")}</h2>
            <Input
                type="switch"
                placeholder={t("general")}
                value={notification.offline_general}
                name="offline_general"
                onChange={onChange}
            />
            <Input
                type="switch"
                placeholder={t("impact")}
                value={notification.offline_impact}
                name="offline_impact"
                onChange={onChange}
            />
        </>
    )
}


const Push = ({ onChange }) => {
    const [t] = useTranslation("global") 
    const [push, setPush] = React.useState(localStorage.getItem("push") === "true" ? true : false)

    const handlePush = async (e) => {
        setPush(e.target.value)
        if (e.target.value === true) {
            localStorage.setItem("push", "true")
            const registered = await registerSW();
            const permissionGranted = await askPermissionSW();
            if (!permissionGranted) {
                return;
            }
        }
        if (e.target.value === false) {
            localStorage.setItem("push", "false")
            const permissionGranted = await unregisterSW()
            if (!permissionGranted) {
                return;
            }
        }
    }

    

    return (
        <>
            <h2 className='text-themeBlack-300 text-base font-bold -mb-2'>{t("campaign_noti")}</h2>
            <Input
                type="switch"
                placeholder={t("campaign_noti_me")}
                value={push}
                name={"push"}
                onChange={handlePush}
            />

        </>
    )
}


const Board = () => {
    const [t] = useTranslation("global")
    return (
        <div className='flex flex-col gap-4'>
             <Link to={`/profile/settings/board/notice`} className={`items-start gap-2 pt-4 pb-3 text-sm px-4 border-[1px] rounded-xl text-themeBlack-300 border-themeGrey-100 shadow-sm flex justify-between items-center"`}>
                    {t('notice')}
                    <RiArrowRightSFill className='text-themePink text-2xl' />
             </Link>
             <Link to={`/profile/settings/board/notice`} className={`items-start gap-2 pt-4 pb-3 text-sm px-4 border-[1px] rounded-xl text-themeBlack-300 border-themeGrey-100 shadow-sm flex justify-between items-center"`}>
                    {t('faq')}
                    <RiArrowRightSFill className='text-themePink text-2xl' />
             </Link>
             <Link to={`/profile/settings/board/notice`} className={`items-start gap-2 pt-4 pb-3 text-sm px-4 border-[1px] rounded-xl text-themeBlack-300 border-themeGrey-100 shadow-sm flex justify-between items-center"`}>
                    {t('Privacy')}
                    <RiArrowRightSFill className='text-themePink text-2xl' />
             </Link>
             <Link to={`/profile/settings/board/notice`} className={`items-start gap-2 pt-4 pb-3 text-sm px-4 border-[1px] rounded-xl text-themeBlack-300 border-themeGrey-100 shadow-sm flex justify-between items-center"`}>
                    {t('term')}
                    <RiArrowRightSFill className='text-themePink text-2xl' />
             </Link>
             <Link to={`/profile/settings/board/notice`} className={`items-start gap-2 pt-4 pb-3 text-sm px-4 border-[1px] rounded-xl text-themeBlack-300 border-themeGrey-100 shadow-sm flex justify-between items-center"`}>
                    {t('compIngo')}
                    <RiArrowRightSFill className='text-themePink text-2xl' />
             </Link>
             <div
             onClick={()=>{
                localStorage.removeItem('user')
                localStorage.removeItem('token')
                window.location.href = '/'
             }}
              className={`hidden sm:flex bg-themePink/40 items-start gap-2 pt-4 pb-3 text-sm px-4 border-[1px] rounded-xl text-themeBlack-300 border-themeGrey-100 shadow-sm justify-between items-center"`}>
                    {t('logout')}
                    <IoIosLogOut className='text-themePink text-2xl' />
             </div>
        </div>
    )
}
import React from 'react'
import Button from '../components/Button'
import { motion, AnimatePresence } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

function Welcome() {
    const [state, setState] = React.useState(0)
    const navigate = useNavigate()
    return (
        <AnimatePresence>
            {state === 0 && (
                <motion.div
                    key="section0"
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    transition={{ duration: 0.3 }}
                    className='flex flex-col h-screen overflow-hidden'
                >
                    <div className="h-[60%] w-full">
                        <img src={require('../assets/Mask group (3).png')} className='w-full h-full object-contain' />
                    </div>
                    <div className='h-full w-full px-6 py-5 flex flex-col gap-4 relative'>
                        <h1 className='text-themePink text-[30px] leading-[40px] font-bold'>Top Local Influencer at one place</h1>
                        <p className='text-base text-[#3B3B3B]'>Welcome to joining the leading marketplace
                            for discovering brand campaigns and
                            sponsored collaborations.</p>
                        <div className='absolute bottom-5 left-0 w-full px-6'>
                            <Button text="Next" onClick={() => {
                                setState(1)
                            }} />
                        </div>
                    </div>
                </motion.div>
            )}
            {state === 1 && (
                <motion.div
                    key="section1"
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    transition={{ duration: 0.3 }}
                    className='flex flex-col h-screen'
                >
                    <div className="h-[70%] w-full flex justify-center items-center pt-14">
                        <img src={require('../assets/Group (1).png')} className='w-[90%] h-full object-contain' />
                    </div>
                    <div className='h-full w-full px-6 py-10 flex flex-col gap-4 relative'>
                        <h1 className='text-themePink text-[30px] leading-[40px] font-bold'>Earn Points and Engage with brands</h1>
                        <p className='text-base text-[#3B3B3B]'>Get rewarded for your participation. Earn points, and exclusive offers as you complete campaigns and engage with brands.</p>
                        <div className='absolute bottom-5 left-0 w-full px-6'>
                            <Button text="Next" onClick={() => {
                                setState(2)
                            }} />
                        </div>
                    </div>
                </motion.div>
            )}
            {state === 2 && (
                <motion.div
                    key="section2"
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    transition={{ duration: 0.3 }}
                    className='flex flex-col h-screen'
                >
                    <div className="h-[70%] w-full flex justify-center items-center pt-14">
                        <img src={require('../assets/Group (2).png')} className='w-[90%] h-full object-contain' />
                    </div>
                    <div className='h-full w-full px-6 py-12 flex flex-col gap-4 relative'>
                        <h1 className='text-themePink text-[28px] leading-[40px] font-bold'>Discover Campaigns Base on Your Interests</h1>
                        <p className='text-base text-[#3B3B3B]'>Explore a wide range of campaigns tailored to your interests. Filter by category, location, and more to find the perfect match.</p>
                        <div className='absolute bottom-5 left-0 w-full px-6'>
                            <Button text="Get Started" onClick={() => {
                                localStorage.setItem('welcome', true)
                                navigate('/signup')
                            }} />
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default Welcome

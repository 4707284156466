import React, { useEffect, useRef, useState } from 'react'
import LayoutHeader from '../components/LayoutHeader'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import Input from '../components/Input'
import { defaultCP } from '../utils/defaultCP'
import { getMyCampaigns } from '../services/getMyCampaigns'
import Loader from '../components/Loader'
import { IMAGE_URL, PLATFORMS } from '../constants'
import { convertToSeoulTime } from '../utils/convertToSeoulTime'
import CampaignMenu from '../components/CampaignMenu'
import { IoIosArrowDown } from 'react-icons/io'
import Select from '../components/Select'
import { RiFileCopyFill } from 'react-icons/ri'
import { duplicateCampaign } from '../services/duplicateCampaign'

function MyCampaigns() {
  const [t] = useTranslation("global")
  const debounceTimeout = useRef(null);
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState('all')
  const [sort, setSort] = useState('Asc')

  const [campaigns, setCampaigns] = useState(null)

  const getCampaigns = async () => {
    const data = await getMyCampaigns(status, searchText, sort)
    setCampaigns(data)
  }

  useEffect(() => {
    setCampaigns(null)
    getCampaigns()
  }, [searchText, status, sort])

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      setSearchText(value);
    }, 500);
  }


  return (
    <div className='sm:px-6 sm:pb-24'>

      <div className='hidden sm:flex justify-center items-center gap-5 mb-4 py-0 pt-5'>
        <h1 className='text-black text-lg font-bold'>{t('my_campaign')}</h1>
      </div>

      <div className='flex justify-between items-end sm:flex-col'>
        <LayoutHeader title={t("my_campaign")} />
        <span className='w-1/2 scale-y-[0.8] sm:w-full sm:scale-y-[0.9] sm:flex hidden bg-themeGrey-70/50 rounded-xl overflow-hidden'>
            <Input
              type={'text'}
              placeholder={t('search_by_title')}
              value={searchText}
              onChange={handleSearchChange}
            />

          </span>
        <div className='flex justify-end sm:justify-between sm:mt-2 items-center gap-3 w-[70%] sm:w-full'>
          <div className='flex justify-center items-center gap-3 -mb-3 mr-3'>
            <span className='flex justify-center items-center gap-1.5'>
              <input type='checkbox' className='checkbox' name='status' checked={status === 'all'} onChange={() => setStatus('all')} />
              <p className='text-xs text-themeGrey-300 whitespace-nowrap'>{t('all')}</p>
            </span>
            <span className='flex justify-center items-center gap-1.5'>
              <input type='checkbox' className='checkbox' name='status' checked={status === 'Active'} onChange={() => setStatus('Active')} />
              <p className='text-xs text-themeGrey-300 whitespace-nowrap'>{t('Active')}</p>
            </span>
          </div>

          <span className='w-1/2 scale-y-[0.8] sm:w-full sm:scale-100 sm:hidden'>
            <Input
              type={'text'}
              placeholder={t('search_by_title')}
              value={searchText}
              onChange={handleSearchChange}
            />

          </span>
          <span className='scale-y-[0.8] w-1/4 sm:scale-90 sm:w-1/2'>
            <Select
              options={[{ label: 'Asc', value: 'Asc' }, { label: 'Desc', value: 'Desc' }]}
              onChange={(e) => setSort(e.target.value)}
              value={sort}
              simple={true}
            />

          </span>
        </div>

      </div>
      <hr className='w-full h-1 border-t-0 my-2 border-b-[2px] border-themeGrey-70 shadow-sm sm:hidden' />

      <div className='flex flex-col gap-4 sm:mt-2'>
        <div className='flex flex-col gap-4 w-[60%] sm:w-full'>
          {!campaigns && <Loader />}
          {campaigns?.length > 0 &&
            <>
              {campaigns?.map(campaign => (
                <Campaign campaign={campaign} key={campaign._id} getCampaigns={getCampaigns} path='incomplete' />
              ))}
            </>
          }
          {campaigns?.length === 0 && <p className='text-themeGrey-300 text-sm'>{t("no_activity")}</p>}
        </div>

      </div>
    </div>
  )
}

export default MyCampaigns



const Campaign = ({ campaign, getCampaigns, path }) => {
  const navigate = useNavigate()
  const [t] = useTranslation("global")

  const redirect = () => {

  }

  const _duplicateCampaign = async () => {
    await duplicateCampaign(campaign._id);
    getCampaigns()
  }

  return (
    <div onClick={redirect} className='cursor-pointer bg-white rounded-lg flex justify-between flex-col px-4 sm:p-2 py-4 border-[1px] sm:shadow-sm border-themeGrey-70 shadow-sm'>
      <div className='flex justify-start items-stretch gap-4 w-full sm:w-full sm:gap-3 sm:h-28 overflow-hidden'>
        <img src={campaign.product_images?.length > 0 ? `${IMAGE_URL}/uploads/${campaign.product_images[0]}` : defaultCP()} className='w-24 min-h-24 sm:w-28 object-cover rounded-md' />
        <div className='py-1 flex justify-start items-start flex-col w-full'>
          <div className='flex justify-between items-center w-full'>
            <h2 className='text-[#3B3B3B] font-semibold text-base'>{campaign.campaign_title}</h2>
            <p className='text-xs bg-themePink/10 mt-1 rounded-md text-themePink px-2 py-1 sm:hidden'>{campaign.status}</p>
          </div>
          <p className='text-sm text-[#555555]'>{campaign.description?.length > 145 ? `${window.innerWidth <= 768 ? campaign.description?.substr(0, 90) : campaign.description?.substr(0, 145)}...` : campaign.description}</p>
          <div className='flex justify-between w-full items-center  mt-2 sm:hidden'>
            <span>
              {campaign?.platform && <img src={PLATFORMS.filter(p => p.title === campaign?.platform)[0]?.logo} alt='refresh' className='w-5 h-5' />}
            </span>
            <p className='text-themeBlack-500 font-normal text-xs'>{convertToSeoulTime(campaign.updatedAt)}</p>
          </div>
        </div>
      </div>
      <hr className='flex w-full h-0.5 border-b-[1px] border-themeGrey-70 border-t-0 my-2' />
      <div className='w-full flex justify-between items-center relative sm:w-full sm:p-0'>
        <p onClick={_duplicateCampaign} className='text-xs border-[1px] border-themeGrey-70 mt-1 rounded-md text-themeGrey-300 pl-3 pr-4 py-1.5 flex gap-2 items-center'>  <RiFileCopyFill className="text-base text-themeYellow" /> {t('duplicate')}</p>
        {campaign.status === 'Incomplete' || campaign.status === 'Paid' ?
          <CampaignMenu getCampaigns={getCampaigns} campaign={campaign} />
          :
          <Link to={`/manage/${campaign.campaign_title}/${campaign._id}`} className='text-xs border-[1px] border-themeGrey-70 mt-1 rounded-md text-themeGrey-300 px-4 py-1.5 flex gap-2 items-center'>  {t('manage')}</Link>
        }
      </div>
    </div>
  )
}
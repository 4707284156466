import React, { useEffect } from 'react'
import LayoutHeader from '../components/LayoutHeader'
import { useTranslation } from 'react-i18next'
import { getMyBalanceHistory } from '../services/getMyBalanceHistory'
import { convertToSeoulTime } from '../utils/convertToSeoulTime'

function Wallet() {
    const [t] = useTranslation("global")
    const [data, setData] = React.useState([{}, {}])
    const [totals, setTotals] = React.useState({
        totalEarned: 0,
        totalWithdrawn: 0,
        AvailableBalance: 0
    })


    useEffect(() => {
        let totalWithdrawn = 0
        let AvailableBalance = 0

        data.forEach((item) => {
            if (item.status === 'Pending')
                AvailableBalance += item.AvailableBalance
            else
                totalWithdrawn += item.totalWithdrawn
        })
        setTotals({
            totalEarned: totalWithdrawn + AvailableBalance,
            totalWithdrawn,
            AvailableBalance
        })
    }, [data])


    useEffect(() => {
        const getData = async () => {
            const _data = await getMyBalanceHistory()
            setData(_data)
        }
        getData()
    }, [])

    return (
        <div className='sm:px-6 sm:pb-20'>
            <LayoutHeader title={t('mywallet')} />
            <h2 className='hidden sm:flex text-themeBlack-300 text-lg font-bold pt-5'>{t('mywallet')}</h2>
            <hr className='w-full h-1 border-t-0 my-2 mb-4 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <div className='w-full flex gap-5 sm:flex-col'>
                <div className='bg-white flex-1 border-[1px] shadow-sm border-themeGrey-70 rounded-xl p-6 w-full h-20 flex gap-1 justify-between items-center'>
                    <h2 className='text-sm font-medium text-themeBlack-200'>{t('totalEarned')}</h2>
                    <h2 className='text-lg font-bold text-themePink'>{totals.totalEarned.toLocaleString()}</h2>
                </div>
                <div className='bg-white flex-1 border-[1px] shadow-sm border-themeGrey-70 rounded-xl p-6 w-full h-20 flex gap-1 justify-between items-center'>
                    <h2 className='text-sm font-medium text-themeBlack-200'>{t('totalWithdrawn')}</h2>
                    <h2 className='text-lg font-bold text-themePink'>{totals.totalWithdrawn.toLocaleString()}</h2>
                </div>
                <div className='bg-white flex-1 border-[1px] shadow-sm border-themeGrey-70 rounded-xl p-6 w-full h-20 flex gap-1 justify-between items-center'>
                    <h2 className='text-sm font-medium text-themeBlack-200'>{t('AvailableBalance')}</h2>
                    <h2 className='text-lg font-bold text-themePink'>{totals.AvailableBalance.toLocaleString()}</h2>
                </div>
            </div>


            <div className="overflow-x-auto mt-5">
                <table className="min-w-full">
                    <thead className="bg-white border-[1px] border-themeGrey-70/50 rounded-lg overflow-hidden ">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-themeBlack-300 uppercase tracking-wider">
                                Order
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-themeBlack-300 uppercase tracking-wider whitespace-nowrap">
                                Earned on
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-themeBlack-300 uppercase tracking-wider whitespace-nowrap">
                                Type
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-themeBlack-300 uppercase tracking-wider">
                                Amount
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-themeBlack-300 uppercase tracking-wider">
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {data.map((d) => (
                            <tr className='mt-1.5 hover:bg-white border-b-[1px] border-themeGrey-70/50 border-t-0'>
                                <td className="px-6 py-2 whitespace-nowrap">
                                    <div className="text-sm font-medium  text-themeBlack-200">{d.order?.OrderID}</div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                    <div className="text-sm text-themeBlack-200">{convertToSeoulTime(d.createdAt)}</div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                    <div className="text-sm text-themeBlack-200 ">{d.type}</div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                    <div className="text-sm text-themeBlack-200 ">{d.balance}</div>
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap">
                                    <span
                                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${d.status === 'Pending' ? 'bg-themeBlue/40 text-themeBlue' : 'bg-themePink/40 text-themePink'}`}
                                    >
                                        {d.status}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
                {data.length === 0 && 
                        <p className='text-xs text-themeGrey-300 mt-3'>{t('no_activity')}</p>
                        }
        </div>
    )
}

export default Wallet

import { toast } from "react-toastify";
import { BASE_URL } from "../constants"
import { getToken } from "../utils/getToken";
import { getMyProfile } from "./getMyProfile";

export const updateMyNotification = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/user/updateMyNotification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            },
            body: JSON.stringify(data)
        })

        const res = await response.json()
        if (response.status === 200) {
            getMyProfile()
            toast.success(res.message)
        }
        else
            toast.error(res.message)

    } catch (error) {
        console.log(error);
    }
}
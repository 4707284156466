import React from 'react'

function Tab({
    onChange,
    text,
    value,
    name,
    toValue,
    readOnly = false
}) {
    
  return (
    <button
    onClick={() => (
        onChange({
            target: {
              name : name,
              value: toValue
            }
          })
    )}
    disabled={readOnly} className={`text-sm font-medium px-6 outline-none py-2 border-[1px] rounded-lg ${value === toValue? 'text-themePink border-themePink' : 'border-[#ECECEC] text-[#424242]'}`}>
      {text}
    </button>
  )
}

export default Tab

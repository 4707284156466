import React, { useState } from 'react'
import { IMAGE_URL } from '../constants'
import { useTranslation } from 'react-i18next';

function ImageGrid({ images }) {
    let imageCount = images?.slice(1).length
    const firstFourImages = images.slice(1, 5);

    const [t] = useTranslation("global")

    const [showImageViewer, setShowImageViewer] = useState(false)


    return (
        <div className='h-[450px] w-full rounded-xl overflow-hidden flex gap-2 relative'>
            {images.length === 1 && <img src={`${IMAGE_URL}/uploads/${images[0]}`} alt='refresh window' className='w-full h-full object-center object-cover overflow-hidden rounded-xl' />}
            {images.length >= 2 && (
                <>
                    <div className='w-1/2 h-full'>
                        <img onClick={() => setShowImageViewer(true)} src={`${IMAGE_URL}/uploads/${images[0]}`} alt='refresh window' className='w-full h-full object-center object-cover overflow-hidden cursor-pointer' />
                    </div>

                    <div className={`w-1/2 grid gap-2 ${imageCount === 4 ? 'grid-cols-2' : imageCount === 3 ? 'grid-cols-2' : 'grid-cols-2'} h-full`} >
                        {imageCount >= 4 && (
                            <>
                                {firstFourImages.map((image, index) => (
                                    <img onClick={() => setShowImageViewer(true)} src={`${IMAGE_URL}/uploads/${image}`}
                                        className={`w-full h-[100%] object-cover cursor-pointer`}
                                    />
                                ))}
                            </>
                        )}
                        {imageCount === 3 && (
                            <>
                                {firstFourImages.map((image, index) => (
                                    <img onClick={() => setShowImageViewer(true)} src={`${IMAGE_URL}/uploads/${image}`}
                                        className={`w-full object-cover ${index === 0 ? 'h-[225px] col-span-2' : 'h-[225px]'}  cursor-pointer`}
                                    />
                                ))}
                            </>
                        )}
                        {imageCount === 2 && (
                            <>
                                {firstFourImages.map((image, index) => (
                                    <img onClick={() => setShowImageViewer(true)} src={`${IMAGE_URL}/uploads/${image}`}
                                        className={`w-full object-cover  h-[225px] col-span-2  cursor-pointer`}
                                    />
                                ))}
                            </>
                        )}
                        {imageCount === 1 && (
                            <>
                                {firstFourImages.map((image, index) => (
                                    <img onClick={() => setShowImageViewer(true)} src={`${IMAGE_URL}/uploads/${image}`}
                                        className={`w-full object-cover  h-full col-span-2 row-span-2 cursor-pointer`}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </>
            )}

           {images.length > 5 &&  <button onClick={() => setShowImageViewer(true)} className='border-[1px] cursor-pointer bg-white rounded-lg border-themeGrey-70 shadow-sm absolute bottom-5 right-5 px-4 py-1.5 text-xs outline-none text-themeBlack-500 font-medium'>
                {t("viewAll")}
            </button>}
           {showImageViewer && <ImageViewer images={images} setShowImageViewer={setShowImageViewer}/>}
        </div>
    )
}

export default ImageGrid


const ImageViewer = ({ images, setShowImageViewer }) => {
    return (
        <div className='fixed top-0 w-full h-full bg-black/90 left-0 z-[60] px-60 overflow-auto flex flex-col gap-5 py-10'>
            <div className='fixed top-0 left-0 w-full h-full z-0' onClick={() => setShowImageViewer(false)}>
                 
            </div>
            {images.map((image, index) => (
                <img src={`${IMAGE_URL}/uploads/${image}`} className='w-full h-auto z-20'/>
            ))}
        </div>
    )
}
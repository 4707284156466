import React from 'react'
import { useTranslation } from 'react-i18next'

function ProfileTabs({ setActive, active, user}) {
    const [t] = useTranslation("global")

    const click = (val) => {
        setActive(val)
    }
  return (
    <div className='flex justify-start items-start gap-0 sm:gap-4 sm:rounded-none w-full shadow-sm sm:shadow-none shadow-themeGrey-500 rounded-lg overflow-hidden'>
        {<button onClick={()=>click(0)} className={`px-5 sm:rounded-lg pt-2 pb-1 text-base border-b-[0px] ${active === 0 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600 '} flex-1 outline-none transition-all ease-in duration-0`}>
            {t("basic")}
        </button>}
        <button onClick={()=>click(1)} className={`px-5 pt-2 pb-1 sm:rounded-lg sm:border-none text-base border-b-[0px] ${active === 1 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600  border-x-[1px]'} flex-1 outline-none transition-all ease-in duration-0`}>
            {t("info")}
        </button>
        {<button onClick={()=>click(2)} className={`px-5 sm:rounded-lg pt-2 pb-1 text-base border-b-[0px] ${active === 2 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600 '} flex-1 outline-none transition-all ease-in duration-0`}>
            {t("social")}
        </button>}
    </div>
  )
}

export default ProfileTabs

import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchUserProfile } from '../services/fetchUserProfile'
import LandingNav from '../components/LandingNav'
import { IMAGE_URL, PLATFORMS } from '../constants'
import { defaultPfp } from '../utils/defaultPfp'
import { useTranslation } from 'react-i18next'
import { IoChatbubbleOutline } from "react-icons/io5";
import { RiShareForward2Fill } from 'react-icons/ri'
import CampaignCard from '../components/CampaignCard'
import { toast } from 'react-toastify'
import { formateDate } from '../utils/formateDate'
import ReviewCard from '../components/ReviewCard'
import ProfileSkeleton from '../components/ProfileSkeleton'
import Footer from '../components/Footer'
import HomeLayout from '../layouts/HomeLayout'

function Profile() {
    const { name } = useParams()
    const [profile, setProfile] = useState(null)
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user') || "{}")

    useEffect(() => {
        const getUserProfile = async () => {
            const data = await fetchUserProfile(name)
            setProfile(data)
        }
        getUserProfile()
    }, [name])

    const copyToClipboard = () => {
        const currentLocation = window.location.href;
        const textarea = document.createElement('textarea');
        textarea.value = currentLocation;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            toast.success("Link copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy URL: ", err);
        }
        document.body.removeChild(textarea);
    };


    return (
        <HomeLayout>
            {profile ?
                <div className='pb-20 px-60 md:px-16 pt-10 flex justify-between gap-16 md:gap-8 items-start sm:px-6 sm:flex-col sm:pt-4 bg-themeGrey-400/30 min-h-screen'>
                    <div className='flex flex-col w-[25%] gap-5 sm:w-full'>
                        <div className='flex flex-col gap-1 border-[1px] border-themeGrey-70 bg-white shadow-sm rounded-lg px-4 py-6 justify-start items-center shadow-sm'>
                            <img src={profile?.profilePic ? `${IMAGE_URL}/uploads/${profile?.profilePic}` : defaultPfp()} className='w-24 h-24 rounded-full bg-themePink/10 object-cover' />
                            <h2 className='text-themeBlack-200 font-bold text-sm mt-1'>{profile?.name}</h2>
                            <p className='lowercase text-themeGrey-300 text-xs'>@{profile?.nickname}</p>

                            <div className='flex justify-between items-center w-full mt-5'>
                                <p className='text-themeBlack-300 text-xs'>{t('type')}</p>
                                <p className='text-themeBlack-300 text-xs capitalize'>{profile?.role}</p>
                            </div>
                            <div className='flex justify-between items-center  w-full'>
                                <p className='text-themeBlack-300 text-xs'>{t('level')}</p>
                                <p className='text-themeBlack-300 text-xs'>{profile?.level}</p>
                            </div>
                            <div className='flex justify-between items-center  w-full'>
                                <p className='text-themeBlack-300 text-xs'>{t('CTI')}</p>
                                <p className='text-themeBlack-300 text-xs'>{profile?.ctiScore}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <p className='text-xs text-themeBlack-300'>{profile.role === 'creator' ? t('TotalCompltedOrders') : t('totalCompletedCampaigns')}</p>
                                <p className='text-xs text-themeBlack-300'>{profile.role === 'creator' ? (profile.totalCompletedOrders || 0) : (profile.totalCompletedCampaigns || 0)}</p>
                            </div>
                            <div className='flex justify-between items-center w-full'>
                                <p className='text-xs text-themeBlack-300'>{t('CreatedOn')}</p>
                                <p className='text-xs text-themeBlack-300'>{formateDate(profile.createdAt || new Date())}</p>
                            </div>
                            <div className='flex gap-2 w-full mt-4'>
                                {(user?._id !== profile?._id) &&
                                    <button onClick={() => {
                                        sessionStorage.setItem('chatUser', JSON.stringify({
                                            nickname: profile?.nickname,
                                            profilePic: profile?.profilePic,
                                            _id: profile?._id
                                        }))
                                        navigate(`/inbox/${profile?.nickname}`)
                                    }} className='text-xs text-themeGrey-70 py-1.5 outline-none border-[1px] border-themeGrey-300 rounded-lg flex-1 flex justify-center items-center'>
                                        <IoChatbubbleOutline className="text-themeGrey-300 text-lg" />
                                    </button>}
                                <button onClick={copyToClipboard} className='text-xs text-themeGrey-70 py-1.5 outline-none border-[1px] border-themeGrey-300 rounded-lg flex-1 flex justify-center items-center'>
                                    <RiShareForward2Fill className="text-themeGrey-300 text-lg" />
                                </button>
                            </div>
                        </div>

                        {profile?.socials && <div className='flex flex-colgap-1 border-[1px] border-themeGrey-70 gap-3 rounded-lg px-4 py-3 justify-center bg-white shadow-sm items-center shadow-sm'>
                            {PLATFORMS.filter(platform => profile?.socials[platform.key]).map(platform => (
                                <a href={profile?.socials[platform.key].account_address} target='_blank' className='cursor-pointer'>
                                    <img key={platform.key} src={platform.logo} alt={`${platform.title} logo`} className='w-8 h-8 object-contain' />
                                </a>
                            ))}
                        </div>}

                    </div>

                    <div className={`w-[75%] flex ${profile.role === 'customer' ? 'flex-col-reverse' : 'flex-col'} gap-20 sm:w-full`}>
                        <div className='flex flex-col gap-3'>
                            <p className='text-themeBlack-300 font-bold text-sm'>{t('reviews')}</p>
                            <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-themeGrey-70' />
                            <div className='flex justify-start items-start gap-4 flex-wrap sm:flex-col'>
                                {profile?.reviews?.length === 0 && <p className='text-xs text-themeGrey-300'>{t('no_activity')}</p>}
                                {profile?.reviews?.map((review, index) => (
                                    <ReviewCard review={review} />
                                ))}
                            </div>
                        </div>
                        {profile.role === 'customer' && <div className='flex flex-col gap-3'>
                            <p className='text-themeBlack-300 font-bold text-sm'>{t('active_campaign')}</p>
                            <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-themeGrey-70' />
                            {profile?.campaigns?.length === 0 && <p className='text-xs text-themeGrey-300'>{t('no_activity')}</p>}
                            <div className='grid grid-cols-3 sm:grid-cols-1 lg:grid-cols-3 gap-4'>
                                {profile?.campaigns?.map((campaign, index) => (
                                    <div className=' rounded-xl overflow-hidden'>
                                        <CampaignCard key={index} campaign={campaign} />
                                    </div>
                                ))}
                            </div>

                        </div>}
                    </div>
                </div>
                :
                <ProfileSkeleton />
            }
            <Footer />
        </HomeLayout>
    )
}

export default Profile

import React, { useEffect, useState } from 'react'
import { defaultPfp } from '../utils/defaultPfp'
import { GoGear, GoPencil } from "react-icons/go";
import { useLocation, useNavigate } from 'react-router-dom';
import { IMAGE_URL, PLATFORMS } from '../constants';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import ProfileImage from './ProfileImage';
import { getMyProfile } from '../services/getMyProfile';

function ProfileBio({ user:_user }) {
    const [user, setuser] = useState(_user)
    const localtion = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const getUserProfile = async () => {
            const data = await getMyProfile()
            setuser(data)
        }
        getUserProfile()
    }, []) 
    return (
        <div className={`bg-white rounded-lg w-full h-full px-5 py-6 ${localtion.pathname === '/profile/my-page'?  'sm:pt-2' : 'sm:pt-2'} sm:pb-4 border-[1px] border-themeGrey-500 sm:border-none`}>
            <div className='flex justify-start items-start gap-3'>
                {/* <img src={user.profilePic? `${IMAGE_URL}/uploads/${user.profilePic}` : defaultPfp()} alt='refresh-window' className='w-16 h-16 rounded-full bg-themePink/10' /> */}
                <ProfileImage uploadedImage={user.profilePic? `${IMAGE_URL}/uploads/${user.profilePic}` : null} />
                <div className='flex flex-col gap-1 py-2 sm:w-[60%] overflow-hidden justify-start items-start'>
                    <h1 className='text-sm text-black capitalize font-semibold whitespace-nowrap'>{user.nickname} | {user.role}</h1>
                    <p className='text-xs text-black capitalize'>{user.level} | {user.role === 'creator'? user.totalCompletedOrders : user.totalCompletedCampaigns || 0} {t('done')} </p>
                    {user?.socials && <div className='flex gap-1 justify-start bg-white items-center my-1'>
                        {PLATFORMS.filter(platform => user?.socials[platform.key]).map(platform => (
                            <a href={user?.socials[platform.key].account_address} target='_blank' className='cursor-pointer'>
                                <img key={platform.key} src={platform.logo} alt={`${platform.title} logo`} className='w-4 h-4 object-contain' />
                            </a>
                        ))}
                    </div>}
                   {user.nickname && <p 
                    onClick={() => {
                        const link = `${window.location.origin}/${user.nickname}`;
                        navigator.clipboard.writeText(link);
                        toast.success('Copied to clipboard!');
                      }}
                   className='border-[1px] rounded-md border-themeGrey-70 p-1 px-2 text-[10px] w-auto text-themeBlack-400 lowercase'>{`${window.location.origin}/${user.nickname}`}</p>}
                </div>
                <div className='hidden sm:flex flex-col gap-3 w-[15%] h-full'>
                    <span onClick={() => navigate('/profile/my-data')} className={`px-2 py-3 ${localtion.pathname.includes('my-data') ? 'bg-themePink/20' : 'bg-[#F7F7F9]'} rounded-lg flex justify-center items-center`}>
                        <GoPencil className="text-themePink text-xl"/>
                    </span>
                    <span onClick={() => navigate('/profile/settings')} className={`px-2 py-3 ${localtion.pathname.includes('settings') ? 'bg-themePink/20' : 'bg-[#F7F7F9]'} rounded-lg flex justify-center items-center`}>
                        <GoGear className="text-themePink text-xl"/>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ProfileBio

import React, { useEffect, useState } from 'react';
import InputGroup from './InputGroup';
import { useTranslation } from 'react-i18next';

const Address = ({ setUser, user }) => {
  const [zonecode, setZonecode] = useState(null);
  const [roadAddress, setRoadAddress] = useState(null);
  const [t] = useTranslation('global');
  const [address, setAddress] = useState(user.address);

  const onClickSearch = () => {
    new window.daum.Postcode({
      oncomplete: function (data) {
        setZonecode(data.zonecode);
        setRoadAddress(data.roadAddress);
      },
    }).open();
  };

  useEffect(() => {
    if (roadAddress && zonecode) {
      const fullAddress = `${roadAddress} ${zonecode}`;
      setUser((prev) => ({ ...prev, address: fullAddress }));
      setAddress(fullAddress);
    }
  }, [zonecode, roadAddress, setUser]);

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress); // Update the local address state
    setUser((prev) => ({ ...prev, address: newAddress })); // Update the user object with the new address
  };

  console.log(address);
  

  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between items-end gap-4">
        <InputGroup
          label={t('address') + ' *'}
          type="text"
          required={false}
          disabled={true}
          value={zonecode || ''} // Ensure non-null value
          name="zonecode"
          readOnly={true}
          flex="flex-col gap-1.5"
          placeholder={t('zoneCode')}
        />
        <span className="w-1/4">
          <button
            className="w-full bg-themeGrey-150 py-3 px-2.5 rounded-lg outline-none text-white font-bold tracking-wide text-sm"
            onClick={onClickSearch}
          >
            Find
          </button>
        </span>
      </div>
      <InputGroup
        type="text"
        required={false}
        disabled={true}
        value={roadAddress || ''} // Ensure non-null value
        name="roadAddress"
        readOnly={true}
        flex="flex-col gap-1.5"
        placeholder={t('addresS')}
      />
      <InputGroup
        type="text"
        required={true}
        value={address || ''} // Ensure non-null value
        name="address"
        readOnly={false}
        flex="flex-col gap-1.5"
        onChange={handleAddressChange} // Use the handler function
        placeholder={t('address_placeholder')}
      />
    </div>
  );
};

export default Address;

import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SendMessage from './SendMessage';
import { getMessages } from '../services/getMessages';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { getChatTime } from '../utils/getChatTime';
import { IMAGE_URL } from '../constants';
import { defaultPfp } from '../utils/defaultPfp';
import ScrollableFeed from 'react-scrollable-feed';
import { groupMessagesByDate } from '../utils/groupMessagesByDate';
import { FaArrowLeft } from 'react-icons/fa'
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';




const Chat = ({ refresh, setRefresh }) => {
    const { nickname } = useParams();
    const [pageNumber, setPageNumber] = useState(1);
    const [messages, setMessages] = useState([]);
    const [messageItems, setMessageItems] = useState([]);
    const [lastRecordLength, setLastRecordLength] = useState(10);
    const [loading, setLoading] = useState(true);
    const [APICalled, setApiCalled] = useState(false);
    const scrollableRef = useRef(null);
    const navigate = useNavigate()
    const [t] = useTranslation('global');

    const fetchMessages = async (_pageNumber) => {
        let data = await getMessages(nickname, _pageNumber);
     
        setMessages(prevMessages => {
            const updatedMessages = _pageNumber === 1 ? data : [...data, ...prevMessages];
            const groupedMessages = groupMessagesByDate(updatedMessages);
            const newMessageItems = [];
            Object.keys(groupedMessages).forEach(date => {
                newMessageItems.push({ type: 'date', date });
                groupedMessages[date].forEach(message => newMessageItems.push({ type: 'message', message }));
            });
            setMessageItems(newMessageItems);
            return updatedMessages;
        });
        setLastRecordLength(data.length || 0);
        setLoading(false);
        const element = document.getElementById('chat-container');
        if (_pageNumber !== 1 && element) {
            console.log('scrolling');
            
            element.scrollTop = 600
        }
        setTimeout(() => setApiCalled(false), 5000);

    };
    

    useEffect(() => {
        fetchMessages(1);
    }, []);

    useEffect(() => {
        if (pageNumber >= 1) {
            setLoading(true);
            fetchMessages(pageNumber);
        }
    }, [pageNumber]);

    const redDivRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    if (lastRecordLength === 10 && !APICalled) {
                        setApiCalled(true);
                        setLoading(true);
                        setPageNumber(prev => prev + 1);
                    }
                } else {
                    console.log('Red div is out of view');
                }

            },
            {
                root: scrollableRef.current,
                threshold: 0
            }
        );

        if (redDivRef.current) {
            observer.observe(redDivRef.current);

        }

        return () => {
            if (redDivRef.current) {
                observer.unobserve(redDivRef.current);
            }
        };
    }, [redDivRef, scrollableRef, messageItems, APICalled]);


    const ItemContent = ({ item, index }) => {
        if (item.type === 'date') {
            return (
                <div className="flex justify-center w-full mt-6 px-6">
                    <div className="bg-white rounded-2xl border-[1px] border-themeGrey-70 px-4 py-2 inline-block text-themeBlack-500 font-medium text-xs">
                        {item.date}
                    </div>
                </div>
            );
        }
        if (item.type === 'message') {
            const { sender, content, createdAt } = item.message;
            return (
                <div className="flex justify-start items-start mt-8 gap-4 px-6">
                    <img
                        src={sender.profilePic ? `${IMAGE_URL}/uploads/${sender.profilePic}` : defaultPfp()}
                        className='w-10 h-10 rounded-full bg-themePink/10'
                        alt={sender.nickname}
                    />
                    <div className="flex flex-col gap-2">
                        <span className='flex justify-start items-center gap-5'>
                            <h2 className='text-[#313131] font-semibold text-sm capitalize'>{sender.nickname}</h2>
                            <p className='text-[10px] font-medium -mb-1 text-themeBlack-500'>{getChatTime(createdAt)}</p>
                        </span>
                        {content?.split('\n').map((line, index) => (
                            <p key={index} className='text-themeBlack-500 font-medium text-sm'>{line}</p>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div className='flex flex-col justify-start items-center relative overflow-hidden h-full'>
            <div className='bg-white w-full py-4  sm:py-5 px-6 flex justify-between sm:justify-center items-center'>
            <FaArrowLeft onClick={() => navigate(-1)} className='hidden sm:flex sm:absolute sm:left-4 bg-themePink text-[28px] px-2 py-1 text-white rounded-full'/>
                <Link to={`/${nickname}`} className='flex flex-col gap-1'>
                    <h2 className='text-base font-bold capitalize sm:text-center'>{nickname}</h2>
                    <p className='text-xs text-themeBlack-500 text-center hidden sm:flex'>{t('tap')}</p>
                </Link>
            </div>

            {loading && (
                <div className='flex justify-center py-4 items-center'>
                    <AiOutlineLoading3Quarters className='rotating-element text-themePink text-2xl z-10' />
                </div>
            )}
            <div className={`h-[73vh] w-full mb-0`} ref={scrollableRef} id='chat-container'>
                <ScrollableFeed>
                    {messageItems.length > 0 && !APICalled && !loading && <div className='w-full h-0.5' ref={redDivRef}>
                    </div>}
                    {messageItems.map((item, index) => (
                        <ItemContent key={index} item={item} index={index} />
                    ))}
                </ScrollableFeed>
            </div>

            <SendMessage to={nickname} setRefresh={setRefresh} setMessageItems={setMessageItems}/>
        </div>
    );
};

export default Chat;

import React from 'react'
import { useTranslation } from 'react-i18next'
import OTPInput from './OTPInput'

function StoreOTP({ setOtp }) {
    const [t] = useTranslation('global')

  return (
    <div className='px-6 py-5 flex flex-col gap-4 justify-center items-center'>
        <p className='text-sm text-themeBlack-500 font-medium'>{t("need_otp")}</p>
        <OTPInput setOtpValue={setOtp}/>
    </div>
  )
}

export default StoreOTP

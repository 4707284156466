import React, { useEffect, useState } from 'react'
import TextField from './TextField'
import Textarea from './Textarea'
import Select from './Select'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { constraints, validateAddress, validateBio, validateBRegNo, validateComapnyName, validateDoB, validateEmail, validateName, validateNickname, validateNumber, validatePassword, validateRegistrationDate, validateTags, validateURL } from '../constants/validations';
import TagInput from './TagInput';
import Switch from './Switch';

function Input({
  label = null,
  name = "",
  type = null,
  placeholder = "",
  icon = null,
  required = false,
  readOnly = false,
  onChange = () => { },
  value = "",
  options = [],
  textAreaSimple = false,
  disabled = false
}) {
  const [active, setActive] = useState(false)
  const [iconColor, setIconColor] = useState(false)


  const validate = (value) => {
    let validation = false

    if (name === "name")
      validation = validateName(value)
    else if (name === "email")
      validation = validateEmail(value)
    else if (name === "phone" || type === "phone")
      validation = validateNumber(value)
    else if (name === "dob")
      validation = validateDoB(value)
    else if (type === "password" && name !== "password") {
      const { completionPercentage } = validatePassword(value)
      validation = completionPercentage === 100
    }
    else if (name === "password" && type === "password")
      validation = value.length >= 8
    else if (name === "company_name")
      validation = validateComapnyName(value)
    else if (name === "business_reg_no")
      validation = validateBRegNo(value)
    else if (name === "company_address" || name === "address")
      validation = validateAddress(value)
    else if (name === "registration_date")
      validation = validateRegistrationDate(value)
    else if (type === "select")
      validation = value !== ""
    else if (name === "nickname")
      validation = validateNickname(value)
    else if (type === "tag")
      validation = validateTags(value)
    else if (name === "bio")
      validation = validateBio(value)
    else if (type === "url")
      validation = validateURL(value)
    else
      validation = false;


    setActive(validation)
  }

  const checkValueLength = (value) => {
    if (value.length > constraints[name]?.max) {
      document.getElementById(name).value = value.slice(0, constraints[name]?.max)
      return false
    }

    const wordCount = value.trim().split(/\s+/).length;
    if (wordCount > constraints[name]?.max_word) {
      document.getElementById(name).value = value.trim().split(/\s+/).slice(0, 6).join(' ');
      return false;
    }


    return true
  }

  const handleValue = (e) => {
    if (checkValueLength(e.target.value)) {
      validate(e.target.value)
      onChange(e)
    }
  }

  useEffect(() => {
    const input = document.querySelector(`.react-international-phone-input`)    
    if (active) {
      setIconColor('#000000');
      if (input){
        input.classList.add('active-color');
        input.classList.remove('inactive-color');
      }

    } else {
      setIconColor('#cacaca');
      if (input){
        input.classList.add('inactive-color');
        input.classList.remove('active-color');
        }
    }
    if (value !== "") {
      validate(value)
    }
  }, [active, value]);
  return (
    <div className='flex flex-col gap-1 w-full'>
      {label && <label className='text-themePink text-base'>{label}</label>}

      <div className={`flex justify-start items-start gap-2 ${type === "phone" ? 'py-1 px-3' : 'py-2.5 px-4'} border-[1px] rounded-lg ${active && type !== 'url' && !disabled ? "border-black " : "border-themeGrey-100 shadow-sm"} ${type === "textarea" && !textAreaSimple && 'bg-themeGrey-400'} ${disabled && 'bg-themeGrey-400'}`}>
        {icon && (
          <div className="mr-2">
            {React.cloneElement(icon, { fill: iconColor })}
          </div>
        )}
        {type === "text" || type === "number" || type === "password" || type === "date" || type === "email" || type === "url" ?
          <TextField
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={handleValue}
            value={value}
            active={active}
            required={required}
            readOnly={readOnly}
          />
          :
          type === "textarea" ?
            <Textarea
              type={type}
              name={name}
              placeholder={placeholder}
              onChange={handleValue}
              value={value}
              active={active}
              required={required}
              readOnly={readOnly}
            />
            :
            type === "select" ?
              <Select
                options={options}
                name={name}
                onChange={handleValue}
                placeholder={placeholder}
                value={value}
                activeVal={active}
                simple={false}
                readOnly={readOnly}
              />
              :
              type === "phone" ?
                <PhoneInput
                  defaultCountry="kr"
                  value={value}
                  style={{
                    width: '100%',
                    border: '0px solid transparent',
                    fontSize: '16px',
                    color: '#cacaca'
                  }}
                  onChange={(phone) => handleValue({
                    target: {
                      name: name,
                      value: phone
                    }
                  })}
                />
                :
                type === "tag" ?
                  <TagInput
                    value={value}
                    name={name}
                    onChange={onChange}
                    readOnly={readOnly}
                  />
                  :
                  type === "switch" ?
                    <Switch
                      value={value}
                      onChange={onChange}
                      placeholder={placeholder}
                      name={name}
                      readOnly={readOnly}
                    />
                    :
                    <></>
        }
      </div>
    </div>
  )
}

export default Input

import React, { act, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../Input'
import { constraints } from '../../constants/validations'
import InputGroup from '../InputGroup'
import Guide from '../Guide'
import Keyword from '../Keyword'
import Button from '../Button'
import { createUpdateCampaign } from '../../services/createUpdateCampaign'
import moment from 'moment-timezone'
import DescriptionImage from '../DescriptionImage'
import { imageUpload } from '../../services/ImageUpload'

function Detail({ campaignData, setActive }) {
    const [t] = useTranslation("global")
    const [data, setData] = React.useState({
        ...campaignData,
        reservation_date: campaignData?.campaign_option === '10%' ? t("anytime") : campaignData.reservation_date
    })
    const [activeBtn, setActiveBtn] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    let readOnly = campaignData?.paymentProccessed ? true : false


    const onChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = async () => {
        // setLoading(true)
        setError(null)

        let now = moment().tz('Asia/Seoul').startOf('day').format()
        const weeks2 = moment().tz('Asia/Seoul').add(2, 'weeks').startOf('day').format()

        if (moment(data.start_date).tz('Asia/Seoul').format() < now || moment(data.start_date).tz('Asia/Seoul').format() > weeks2) {
            setError("Start date must fall between today and 2 weeks from now")
            setLoading(false)
            return
        }

        const startDate = moment(data.start_date).tz('Asia/Seoul').startOf('day');
        const recruitEndDate = data.campaign_method === 'Quick'
            ? startDate.clone().add(1, 'days')
            : startDate.clone().add(3, 'days')
        const approveEndDate = recruitEndDate.clone().add(1, 'days');

        const activeDays = (data.campaign_method === 'Quick')
            ? 3
            : (data.campaign_type === 'Product' && data.campaign_option === 'Review') ? 14 : 10;

        const activeStartDate = approveEndDate.clone().add(1, 'days').format();
        const activeEndDate = approveEndDate.clone().add(activeDays, 'days').format();


        
        if (data.reservation_date < activeStartDate || data.reservation_date > activeEndDate) {
            setError(`Reservation date must fall in active period which is ${activeStartDate.substr(0, 10)} to ${activeEndDate.substr(0, 10)}`)
            setLoading(false)
            return

        }

        let newImagePath = null
        if(data.descriptionImageToUpload){
            newImagePath = await imageUpload(data.descriptionImageToUpload)
        }

        if(Array.isArray(newImagePath)){
            data.descriptionImage = newImagePath[0]
        }

        const response = await createUpdateCampaign(data, 'Detail')
        const res = await response.json()
        if (response?.status === 200) {
            setActive(4)
        }
        else if (response?.status !== 200) {
            setError(res.message)
        }
        else {
            setError("Some error occured, please try again")
        }
        setLoading(false)
    }


    const validate = () => {
        const isValidBasicDetails = (
            data.campaign_title?.length >= constraints.campaign_title?.min &&
            data.description?.length >= constraints.description?.min &&
            data.product_offer?.length >= constraints.product_offer?.min &&
            data.types_of_submitted_results?.length >= constraints.types_of_submitted_results?.min &&
            data.body_keyword?.length >= constraints.body_keyword?.min &&
            data.title_keyword?.length >= constraints.title_keyword?.min &&
            data.tags?.length >= constraints.tags?.min &&
            data.what_to_do?.length >= constraints.what_to_do?.min &&
            data.what_should_be_emphasized?.length >= constraints.what_should_be_emphasized?.min &&
            data.what_not_to_do?.length >= constraints.what_not_to_do?.min &&
            moment(data.start_date).tz('Asia/Seoul').format() >= moment().tz('Asia/Seoul').startOf('day').format() &&
           (data.descriptionImage || data.descriptionImageToUpload)
        );

        

        console.log(data.campaign_title?.length >= constraints.campaign_title?.min,
            data.description?.length >= constraints.description?.min,
            data.product_offer?.length >= constraints.product_offer?.min,
            data.types_of_submitted_results?.length >= constraints.types_of_submitted_results?.min,
            data.body_keyword?.length >= constraints.body_keyword?.min,
            data.title_keyword?.length >= constraints.title_keyword?.min,
            data.tags?.length >= constraints.tags?.min,
            data.what_to_do?.length >= constraints.what_to_do?.min,
            data.what_should_be_emphasized?.length >= constraints.what_should_be_emphasized?.min,
            data.what_not_to_do?.length >= constraints.what_not_to_do?.min,
            moment(data.start_date).tz('Asia/Seoul').format()>= moment().tz('Asia/Seoul').startOf('day').format());
        console.log(moment(data.start_date).tz('Asia/Seoul').format(), moment().tz('Asia/Seoul').startOf('day').format());

        if (!isValidBasicDetails) return false;


        if (
            campaignData?.campaign_type !== "Store" &&
            campaignData?.campaign_option !== 'Payback'
        ) {
            return true;
        }
        else if (
            campaignData?.campaign_type === "Store" &&
            campaignData?.campaign_option === 'Payback' &&
            data.visit_address?.length >= constraints.visit_address?.min &&
            (campaignData?.campaign_option === "10%" || new Date(data.reservation_date).toISOString() > moment().tz('Asia/Seoul').startOf('day').format()) &&
            data.how_to_purchase?.length >= constraints.how_to_purchase?.min
        ) {
            return true;
        } else if (
            campaignData?.campaign_type === "Store" &&
            campaignData?.campaign_option !== 'Payback' &&
            data.visit_address?.length >= constraints.visit_address?.min &&
            (campaignData?.campaign_option === "10%" || new Date(data.reservation_date).toISOString() > moment().tz('Asia/Seoul').startOf('day').format())
        ) {
            return true;
        } else if (
            campaignData?.campaign_type !== "Store" &&
            campaignData?.campaign_option === 'Payback' &&
            data.how_to_purchase?.length >= constraints.how_to_purchase?.min
        ) {
            return true;
        }

        return false;
    };




    useEffect(() => {
        if (validate()) {
            setActiveBtn(true)
        }
        else {
            setActiveBtn(false)
        }

    }, [data])

    return (
        <div className='flex flex-col gap-6 py-6 sm:pt-0 sm:pb-10 relative'>
            <DescriptionImage data={data} setData={setData}  />
            <div className='flex flex-col gap-1 px-6'>
                <span className='flex justify-between items-center'>
                    <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("Title")}</h3>
                    <p className='text-sm text-themePink'>{data.campaign_title?.trim().split(/\s+/).length ?? 0}/{constraints.campaign_title.max_word}</p>
                </span>
                <p className='text-xs text text-themeBlack-300'>{t('Title_text')}</p>
                <Input
                    type={'text'}
                    placeholder={t('Title_placeholder')}
                    name="campaign_title"
                    onChange={onChange}
                    value={data.campaign_title || ""}
                    readOnly={readOnly}
                />
            </div>

            <div className='flex flex-col gap-2 px-6'>
                <div className='flex justify-between items-end'>
                    <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("description")}</h3>
                    <p className="text-themePink text-xs">{data.description?.length ?? 0}/{constraints.description?.max}</p>
                </div>
                <Input
                    name='description'
                    type={"textarea"}
                    flex='flex-col gap-2'
                    placeholder={t("description_place")}
                    required={true}
                    textAreaSimple={true}
                    onChange={onChange}
                    readOnly={readOnly}
                    value={data.description}
                />
            </div>

            <div className='px-6 w-[55%] sm:w-full'>
                <Guide heading={t("Guide")} text={t("detail_guide")} />
            </div>
            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <div className='flex flex-col gap-1 px-6 '>
                <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("Product_offer")}</h3>
                <Input
                    type={'text'}
                    name="product_offer"
                    onChange={onChange}
                    placeholder={t("Product_offer_placeholder")}
                    value={data.product_offer || ""}
                    readOnly={readOnly}
                />
            </div>

            <div className='flex flex-col gap-2 px-6'>
                <div className='flex justify-between items-end'>
                    <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("Types_of_Submitted_Results")}</h3>
                    <p className="text-themePink text-xs">{data.types_of_submitted_results?.length ?? 0}/{constraints.types_of_submitted_results.max}</p>
                </div>
                <Input
                    name='types_of_submitted_results'
                    type={"textarea"}
                    flex='flex-col gap-2'
                    placeholder={t("types_of_submitted_results_placeholder")}
                    required={true}
                    textAreaSimple={true}
                    onChange={onChange}
                    readOnly={readOnly}
                    value={data.types_of_submitted_results}
                />
            </div>
            <hr className='w-full h-1 border-t-0 border-b-[2px] border-themeGrey-70 shadow-sm' />

            <h3 className='text-themeBlack-300 font-semibold text-xl px-6'>{t("ContentKeyword")}</h3>
            <div className='px-6 w-[55%] sm:w-full'>
                <Guide heading={t("Guide")} text={t("tag_guide")} />
            </div>
            <div className='flex flex-col gap-1 px-6'>
                <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("TitleKeyword")}</h3>
                <Keyword
                    name='title_keyword'
                    onChange={onChange}
                    value={data.title_keyword || ""}
                    readOnly={readOnly}
                    placeholder={t("tilte_keyword_placeholder")}
                />
            </div>
            <div className='flex flex-col gap-1 px-6'>
                <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("BodyKeyword")}</h3>
                <Keyword
                    name='body_keyword'
                    onChange={onChange}
                    value={data.title_keyword || ""}
                    readOnly={readOnly}
                    placeholder={t("body_keyword_placeholder")}
                />
            </div>

            <div className='flex flex-col gap-1 px-6 '>
                <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("Addtags")}</h3>
                <InputGroup
                    type={'tag'}
                    name="tags"
                    onChange={onChange}
                    readOnly={readOnly}
                    value={data.tags || ""}
                    flex="flex-col gap-1.5"
                >
                    <p className='text-xs absolute -bottom-5 left-0 text-themePink'>{t("c_tags")}</p>
                </InputGroup>
            </div>
            <div className='flex flex-col gap-1 px-6 mt-6'>
                <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("account_tag")}</h3>
                <InputGroup
                    type={'tag'}
                    name="account_tag"
                    onChange={onChange}
                    readOnly={readOnly}
                    value={data.account_tag || ""}
                    flex="flex-col gap-1.5"
                >
                    <p className='text-xs absolute -bottom-5 left-0 text-themePink'>{t("c_tags")}</p>
                </InputGroup>
            </div>

            <hr className='w-full h-1 border-t-0  mt-4 border-b-[2px] border-themeGrey-70 shadow-sm' />
            <div className='px-6 flex flex-col gap-3'>
                <h3 className='text-themeBlack-300 font-semibold text-xl'>{t("CampaignMission")}</h3>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between items-end'>
                        <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("what_to_do")}</h3>
                        <p className="text-themePink text-xs">{data.what_to_do?.length ?? 0}/{constraints.what_to_do.max}</p>
                    </div>
                    <Input
                        name='what_to_do'
                        placeholder={t("what_to_do_place")}
                        type={"textarea"}
                        flex='flex-col gap-2'
                        required={true}
                        readOnly={readOnly}
                        textAreaSimple={true}
                        onChange={onChange}
                        value={data.what_to_do}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between items-end'>
                        <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("what_emphasized")}</h3>
                        <p className="text-themePink text-xs">{data.what_should_be_emphasized?.length ?? 0}/{constraints.what_should_be_emphasized.max}</p>
                    </div>
                    <Input
                        name='what_should_be_emphasized'
                        type={"textarea"}
                        flex='flex-col gap-2'
                        placeholder={t("what_emphasized_place")}
                        required={true}
                        readOnly={readOnly}
                        textAreaSimple={true}
                        onChange={onChange}
                        value={data.what_should_be_emphasized}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between items-end'>
                        <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("Whatnottodo")}</h3>
                        <p className="text-themePink text-xs">{data.what_not_to_do?.length ?? 0}/{constraints.what_not_to_do.max}</p>
                    </div>
                    <Input
                        name='what_not_to_do'
                        type={"textarea"}
                        flex='flex-col gap-2'
                        placeholder={t("what_not_to_do_place")}
                        required={true}
                        readOnly={readOnly}
                        textAreaSimple={true}
                        onChange={onChange}
                        value={data.what_not_to_do}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='flex justify-between items-end'>
                        <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("landing_link")}</h3>
                        <p className="text-themePink text-xs">{t("optional")}</p>
                    </div>
                    <Input
                        name='landing_link'
                        type={"url"}
                        readOnly={readOnly}
                        flex='flex-col gap-2'
                        placeholder={t("landing_link")}
                        required={true}
                        textAreaSimple={true}
                        onChange={onChange}
                        value={data.landing_link}
                    />
                </div>


            </div>

            <hr className='w-full h-1 border-t-0  border-b-[2px] border-themeGrey-70 shadow-sm' />

            <h3 className='text-themeBlack-300 font-semibold text-xl px-6'>{t("Period")}</h3>
            <div className='flex gap-6 px-6 w-full sm:flex-wrap sm:gap-4'>
                <div className='flex flex-col gap-2 w-1/2 sm:w-full'>
                    <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("startDate")}</h3>
                    <Input
                        name='start_date'
                        type={"date"}
                        flex='flex-col gap-2'
                        required={true}
                        readOnly={readOnly}
                        textAreaSimple={true}
                        onChange={onChange}
                        value={moment(data.start_date).tz('Asia/Seoul').format()?.substr(0, 10)}
                    />
                </div>
            </div>


            {campaignData?.campaign_type === "Store" && <>
                <hr className='w-full h-1 border-t-0  border-b-[2px] border-themeGrey-70 shadow-sm' />
                <h3 className='text-themeBlack-300 font-semibold text-xl px-6'>{t("visit_info")}</h3>
                <div className='flex gap-6 px-6 w-full sm:flex-wrap sm:gap-4'>
                    <div className='flex flex-col gap-2 w-1/2 sm:w-full'>
                        <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("Visit")}</h3>
                        <Input
                            name='visit_address'
                            placeholder={t("visit_placeholder")}
                            type={"text"}
                            flex='flex-col gap-2'
                            required={true}
                            textAreaSimple={true}
                            onChange={onChange}
                            readOnly={readOnly}
                            value={data.visit_address}
                        />
                    </div>
                    <div className='flex flex-col gap-2 w-1/2 sm:w-full'>
                        <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("reservation_date")}</h3>
                        {campaignData?.campaign_option === "10%" ?
                            <Input
                                name='reservation_date'
                                type={"text"}
                                flex='flex-col gap-2'
                                required={true}
                                textAreaSimple={true}
                                readOnly={true}
                                value={data.reservation_date}
                            />
                            :
                            <Input
                                name='reservation_date'
                                type={"date"}
                                flex='flex-col gap-2'
                                required={true}
                                textAreaSimple={true}
                                readOnly={readOnly}
                                onChange={onChange}
                                value={data.reservation_date?.substr(0, 10)}
                            />
                        }
                    </div>
                </div>
            </>}


            {campaignData?.campaign_option === 'Payback' && <>
                <hr className='w-full h-1 border-t-0  border-b-[2px] border-themeGrey-70 shadow-sm' />
                <div className='flex flex-col gap-2 px-6'>
                    <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("how_to_purchase")}</h3>
                    <Input
                        name='how_to_purchase'
                        type={"textarea"}
                        flex='flex-col gap-2'
                        placeholder={t("how_to_purchase_placeholder")}
                        required={true}
                        textAreaSimple={true}
                        readOnly={readOnly}
                        onChange={onChange}
                        value={data.how_to_purchase}
                    />
                </div>
            </>}

            {error && <p className='text-red-600 text-sm mb-0 sm:mb-3 px-6'>{error}</p>}
            <div className='w-1/3 px-6 sm:absolute sm:-top-[117px] sm:p-3 sm:w-[130px] sm:right-0'>
                <Button text={t("save")} active={activeBtn} disabled={loading} onClick={onSubmit} />
            </div>

        </div>
    )
}

export default Detail

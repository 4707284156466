import React, { useEffect, useRef, useState } from 'react'
import LayoutHeader from '../components/LayoutHeader'
import { Navigate, NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Input from '../components/Input'
import { IMAGE_URL, PLATFORMS } from '../constants'
import Loader from '../components/Loader'
import { getMyOrdersCreator } from '../services/getMyOrdersCreator'
import { defaultCP } from '../utils/defaultCP'
import { convertToSeoulTime } from '../utils/convertToSeoulTime'
import { IoIosArrowDown } from 'react-icons/io'
import { BsTrash2Fill } from 'react-icons/bs'
import { deleteOrder } from '../services/deleteOrder'
import { HiOutlineDotsHorizontal } from 'react-icons/hi'

function Orders() {
    const [t] = useTranslation("global")
    const user = JSON.parse(localStorage.getItem('user'))

    const debounceTimeout = useRef(null);
    const [searchText, setSearchText] = useState('')

    const handleSearchChange = (e) => {
        const value = e.target.value;
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            setSearchText(value);
        }, 500);
    }
    return (
        <div className='sm:px-6'>
            <div className='hidden sm:flex justify-center items-center gap-5  py-5 '>
                <h1 className='text-black text-lg font-bold'>{t('manageOrders')}</h1>
            </div>
            <div className='flex justify-between items-end'>
                <LayoutHeader title={t("manageOrders")} />
                <div className='order flex justify-end items-end w-[40%] gap-0 sm:w-full sm:justify-start sm:overflow-x-scroll sm:border-b-[2px] border-themeGrey-70 '>
                    <NavLink to='/orders/pending' className='font-medium outline-none px-2 py-0 text-sm text-themeGrey-300 sm:pb-3 sm:pl-0 whitespace-nowrap'>
                        {t('pending')}
                    </NavLink>
                    <NavLink to='/orders/active' className='font-medium outline-none px-2 py-0 text-sm text-themeGrey-300 sm:pb-3 whitespace-nowrap'>
                        {t('Active')}
                    </NavLink>
                    <NavLink to='/orders/submitted' className='font-medium outline-none px-2 py-0 text-sm text-themeGrey-300 sm:pb-3 whitespace-nowrap'>
                        {t('submitted')}
                    </NavLink>
                    <NavLink to='/orders/disputed' className='font-medium outline-none px-2 py-0 text-sm text-themeGrey-300 sm:pb-3 whitespace-nowrap'>
                        {t('dispute')}
                    </NavLink>
                    <NavLink to='/orders/completed' className='font-medium outline-none px-2 py-0 text-sm text-themeGrey-300 sm:pb-3 whitespace-nowrap'>
                        {t('completed')}
                    </NavLink>
                    <NavLink to='/orders/cancelled' className='font-medium outline-none px-2 py-0 text-sm text-themeGrey-300 sm:pb-3 whitespace-nowrap'>
                        {t('cancelled')}
                    </NavLink>
                    <NavLink to='/orders/rejected' className='font-medium outline-none px-2 py-0 text-sm text-themeGrey-300 sm:pb-3 sm:pr-0'>
                        {t('rejected')}
                    </NavLink>
                </div>
            </div>
            <hr className='w-full h-1 border-t-0 my-3 border-b-[2px] border-themeGrey-70 sm:hidden shadow-sm ' />
            <div className='flex justify-end mb-3 sm:hidden'>
                <span className='w-1/5 scale-y-[0.8]'>
                    <Input
                        type={'text'}
                        placeholder={t('search_by_title')}
                        value={searchText}
                        onChange={handleSearchChange}
                    />
                </span>
            </div>
            <div className='flex flex-col gap-4 sm:mt-4'>

                <Routes>
                    <Route index element={<Navigate to='/orders/pending' />} />
                    <Route path=':status' element={<OrdersList searchText={searchText} user={user} />} />
                    <Route path='*' element={<Navigate to='/orders/pending' />} />
                </Routes>

            </div>
        </div>
    )
}

export default Orders



const OrdersList = ({ searchText, user }) => {
    const [t] = useTranslation("global");
    const loocation = useLocation();
    const [orders, setOrders] = useState(null)

    const { status } = useParams()
    const navigate = useNavigate()


    const getOrders = async () => {
        const data = await getMyOrdersCreator(status, searchText)
        setOrders(data)
    }

    useEffect(() => {
        if (['pending', 'active', 'submitted', 'disputed', 'completed', 'cancelled', 'rejected'].indexOf(status) === -1 && user.role === 'creator') {
            return navigate('/orders/pending')
        }
        else {
            setOrders(null)
            getOrders()
        }
    }, [searchText, loocation.pathname])


    return (

        <>
            {!orders && <Loader />}
            {orders?.length === 0 && <p className='text-themeGrey-300 text-sm'>{t("no_activity")}</p>}
            {orders?.map((order, index) => (
                <Order order={order} getOrders={getOrders} key={index} user={user} />
            ))}
        </>
    );
}


const Order = ({ order, getOrders, user }) => {
    const [menu, setMenu] = useState(false);
    const [t] = useTranslation("global");
    const menuRef = useRef();
    const navigate = useNavigate()

    const campaign = order.campaign

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenu(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const _deleteOrder = async (_id) => {
        const confirm = window.confirm(t('withdraw_confirm'));
        if (confirm) {
            const deleted = await deleteOrder(_id);
            if (deleted)
                getOrders()
        }
    }


    const refirect = () => {
        if (order.status === 'Pending' || order.status === 'Rejected')
            navigate(`/campaign-detail/${campaign.campaign_title}/${campaign._id}`)
        if (order.status !== 'Pending')
            navigate(`/orders/${campaign.campaign_title}/${order._id}`)
    }

    return (
        <div className='bg-white rounded-lg flex justify-between items-center px-4 py-4 border-[1px] border-themeGrey-70 shadow-sm sm:flex-col'>
            <div className='flex justify-start items-stretch gap-4 w-[50%] sm:w-full'>
                <img onClick={refirect} src={campaign?.product_images?.length > 0 ? `${IMAGE_URL}/uploads/${campaign.product_images[0]}` : defaultCP()} className='w-24 min-h-24 sm:w-28 object-cover cursor-pointer rounded-md' />
                <div className='py-1 flex justify-start items-start flex-col w-full'>
                    <h2 onClick={refirect} className='text-[#3B3B3B] font-semibold text-base cursor-pointer'>{campaign.campaign_title}</h2>
                    <p className='flex justify-between items-center text-xs font-medium text-[#555555] w-full'>
                        <span>{t('ApplicationID')}:</span>
                        <span>{order.OrderID}</span>
                    </p>
                    <p className='flex justify-between items-center text-xs text-[#555555] w-full'>
                        <span>{t('customerID')}:</span>
                        <span>{order.customerID}</span>
                    </p>
                    <p className='flex justify-between items-center text-xs text-[#555555] w-full'>
                        <span>{t('CreatorID')}:</span>
                        <span>{order.creatorID}</span>
                    </p>
                    <p className='flex justify-between items-center text-xs text-[#555555] w-full'>
                        <span>{t('Applied_on')}:</span>
                        <span>{convertToSeoulTime(order.updatedAt)}</span>
                    </p>

                </div>
            </div>
            <hr className='hidden sm:flex w-full h-0.5 border-b-[1px] border-themeGrey-70 border-t-0 my-3'/>
            <div className='w-[50%] flex justify-between items-center pl-20 pr-5 relative sm:w-full sm:p-0'>
                <p className='text-xs bg-themePink/10 mt-1 rounded-md text-themePink px-2 py-1'>{order.status}</p>
                {order.status === 'Pending' && <>
                    <button onClick={() => setMenu(true)} className='outline-none border-[1px] border-themeGrey-70 rounded-md p-1.5 sm:p-0 sm:border-none'>
                    <IoIosArrowDown className='text-lg text-themeBlack-500 sm:hidden' />
                    <HiOutlineDotsHorizontal className='text-lg text-themeBlack-500 ' />
                    </button>
                    {menu &&
                        <div ref={menuRef} className='absolute w-36 overflow-hidden glass border-[1px] border-themeGrey-100 z-40 shadow-md right-5 rounded-md top-[110%]'>
                            {order.status === 'Pending' && user.role === 'creator' &&
                                <p onClick={() => { _deleteOrder(order._id); setMenu(false) }} className='z-10 relative py-2 px-4 flex justify-start items-center gap-2 hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300'>
                                    <BsTrash2Fill className="text-base text-themePink" />
                                    {t('WithDraw_Appli')}
                                </p>}
                            <div className='absolute bottom-0 w-1/2 h-8 whitespace-nowrap bg-themePink blur-3xl opacity-50 z-0' />
                        </div>
                    }
                </>}
            </div>
        </div>
    )
}
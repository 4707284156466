
import { toast } from "react-toastify";
import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const createDispute = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/order/createDispute`,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            },
            body: JSON.stringify(data)
        })
        const res = await response.json()
        if (response.status === 200){
            toast.success(res.message)
        }
        else{
            toast.error(res.message)
        }
    } catch (error) {
        console.log(error);
    }
}
import React from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

function Section1() {
    const navigate = useNavigate()
    const onClick = ( value ) => {
        if(localStorage.getItem("newUser")){
            const data = JSON.parse(localStorage.getItem("newUser"))
            data.role = value
            localStorage.setItem("newUser", JSON.stringify(data))
        }
        else
            localStorage.setItem("newUser", JSON.stringify({role: value}))

        navigate('/signup/ID')
    }
    return (
        <motion.div key="section2"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 0.3 }}
            className='w-full relative overflow-hidden bg-white rounded-l-[50px] shadow-2xl h-full flex justify-center items-center sm:rounded-none sm:shadow-none'>
            <div className='flex justify-center flex-col gap-3 py-10 px-0 relative h-full w-[60%] sm:w-[80%]'>
                <h1 className='text-[28px] leading-[38px] font-Pretendard font-bold text-themePink text-center mb-12 sm:text-2xl'>Please select one of the following user type options</h1>

                <div className='flex flex-col gap-10 w-[90%] sm:w-full'>
                    <div onClick={()=>onClick("customer")} className='cursor-pointer flex flex-col gap-4'>
                        <p className='text-sm text-themeBlack-300'>Do you want to promote your products, services through influencers?</p>
                        <div className='relative rounded-lg w-full bg-themeBlue px-2 py-2'>
                            <p className='text-white text-sm'> Customer</p>
                            <svg className='absolute -right-2 -top-4' width="64" height="70" viewBox="0 0 64 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.4856 36.7897L4.42961 34.1221C4.31807 28.8079 4.2623 26.1508 4.90314 23.7381C5.65254 20.9166 7.08906 18.3247 9.0844 16.1938C10.7907 14.3716 13.0735 13.0106 17.639 10.2885L17.639 10.2885C22.1715 7.58618 24.4377 6.235 26.8355 5.60019C29.6396 4.85784 32.5833 4.81696 35.4069 5.48118C37.8215 6.04917 40.1243 7.33691 44.7301 9.91239L45.283 10.2216C50.4793 13.1273 53.0774 14.5801 54.9786 16.6335C56.7004 18.4933 58.0022 20.7016 58.7956 23.1087C59.6717 25.7664 59.6849 28.7894 59.7115 34.8354L59.7115 34.8355C59.7389 41.0855 59.7526 44.2105 58.8515 46.944C58.0218 49.4607 56.637 51.7586 54.7993 53.6678C52.8033 55.7415 50.0765 57.1669 44.623 60.0177L44.6229 60.0177L44.1658 60.2566L44.1658 60.2566C39.9154 62.4784 37.7902 63.5893 35.5692 64.0983C32.9921 64.6888 30.3154 64.6937 27.7363 64.1127C25.5133 63.6119 23.3841 62.5088 19.1256 60.3027L19.1255 60.3026L19.1255 60.3026C14.2964 57.8008 11.8818 56.5499 10.0423 54.7862C7.90864 52.7405 6.32209 50.1924 5.4278 47.375C4.65678 44.946 4.59972 42.2272 4.4856 36.7897Z" fill="url(#paint0_linear_7_145)" />
                                <path d="M28.0737 27.9702L34.7561 34.5518L28.0737 41.1333" stroke="white" stroke-width="2.64515" />
                                <defs>
                                    <linearGradient id="paint0_linear_7_145" x1="-25.1069" y1="-15.2259" x2="92.2158" y2="82.3935" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.335" stop-color="#01CBC4" />
                                        <stop offset="1" stop-color="#01CBA7" />
                                    </linearGradient>
                                </defs>
                            </svg>

                        </div>
                    </div>
                    <div onClick={()=>onClick("creator")} className='cursor-pointer flex flex-col gap-4'>
                        <p className='text-sm text-themeBlack-300'>Do you want to make economic profits by introducing advertisers' products?</p>
                        <div className='relative rounded-lg w-full bg-themeBlue px-2 py-2'>
                            <p className='text-white text-sm'> Creator</p>
                            <svg className='absolute -right-2 -top-4' width="64" height="70" viewBox="0 0 64 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.4856 36.7897L4.42961 34.1221C4.31807 28.8079 4.2623 26.1508 4.90314 23.7381C5.65254 20.9166 7.08906 18.3247 9.0844 16.1938C10.7907 14.3716 13.0735 13.0106 17.639 10.2885L17.639 10.2885C22.1715 7.58618 24.4377 6.235 26.8355 5.60019C29.6396 4.85784 32.5833 4.81696 35.4069 5.48118C37.8215 6.04917 40.1243 7.33691 44.7301 9.91239L45.283 10.2216C50.4793 13.1273 53.0774 14.5801 54.9786 16.6335C56.7004 18.4933 58.0022 20.7016 58.7956 23.1087C59.6717 25.7664 59.6849 28.7894 59.7115 34.8354L59.7115 34.8355C59.7389 41.0855 59.7526 44.2105 58.8515 46.944C58.0218 49.4607 56.637 51.7586 54.7993 53.6678C52.8033 55.7415 50.0765 57.1669 44.623 60.0177L44.6229 60.0177L44.1658 60.2566L44.1658 60.2566C39.9154 62.4784 37.7902 63.5893 35.5692 64.0983C32.9921 64.6888 30.3154 64.6937 27.7363 64.1127C25.5133 63.6119 23.3841 62.5088 19.1256 60.3027L19.1255 60.3026L19.1255 60.3026C14.2964 57.8008 11.8818 56.5499 10.0423 54.7862C7.90864 52.7405 6.32209 50.1924 5.4278 47.375C4.65678 44.946 4.59972 42.2272 4.4856 36.7897Z" fill="url(#paint0_linear_7_145)" />
                                <path d="M28.0737 27.9702L34.7561 34.5518L28.0737 41.1333" stroke="white" stroke-width="2.64515" />
                                <defs>
                                    <linearGradient id="paint0_linear_7_145" x1="-25.1069" y1="-15.2259" x2="92.2158" y2="82.3935" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.335" stop-color="#01CBC4" />
                                        <stop offset="1" stop-color="#01CBA7" />
                                    </linearGradient>
                                </defs>
                            </svg>

                        </div>
                    </div>
                </div>



                <svg  className="absolute -top-0 -right-[320px] sm:-right-60 rotate-[0deg]" width="257" height="208" viewBox="0 0 257 208" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_i_12_232)">
                        <path d="M250.294 84.377C256.944 87.4309 258.814 96.0064 254.039 101.552L166.434 203.274C163.235 206.989 157.964 208.138 153.509 206.093L6.99632 138.814C1.47545 136.279 -0.944933 129.748 1.59025 124.227L55.4226 6.99643C57.9578 1.47555 64.4885 -0.944827 70.0094 1.59036L250.294 84.377Z" fill="#F54363" />
                    </g>
                    <defs>
                        <filter id="filter0_i_12_232" x="-18.216" y="-18.2159" width="274.922" height="225.313" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dx="-39" dy="-24" />
                            <feGaussianBlur stdDeviation="9.4" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12_232" />
                        </filter>
                    </defs>
                </svg>

                <svg  className="absolute -bottom-28 rotate-[120deg] sm:-left-44 -left-[250px]" width="257" height="208" viewBox="0 0 257 208" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_i_12_232)">
                        <path d="M250.294 84.377C256.944 87.4309 258.814 96.0064 254.039 101.552L166.434 203.274C163.235 206.989 157.964 208.138 153.509 206.093L6.99632 138.814C1.47545 136.279 -0.944933 129.748 1.59025 124.227L55.4226 6.99643C57.9578 1.47555 64.4885 -0.944827 70.0094 1.59036L250.294 84.377Z" fill="#F54363" />
                    </g>
                    <defs>
                        <filter id="filter0_i_12_232" x="-18.216" y="-18.2159" width="274.922" height="225.313" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dx="-39" dy="-24" />
                            <feGaussianBlur stdDeviation="9.4" />
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0" />
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12_232" />
                        </filter>
                    </defs>
                </svg>

            </div>
        </motion.div>
    )
}

export default Section1

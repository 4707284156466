import { BASE_URL } from "../constants"

export const RegisterUser = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/user/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    
        const res = await response.json()
        if (response.status === 201){
            const user = JSON.parse(localStorage.getItem('newUser'))
            localStorage.setItem('token', res.token)
            user._id = res.userId
            localStorage.setItem('newUser', JSON.stringify(user))
            return {
                status: true,
                message: 'User Registered Successfully'
            }
        }
        return {
            status: false,
            message: res.message
        }
    } catch (error) {
        console.log(error);
    }
}
// export const BASE_URL = 'http://localhost:5000/api'
export const BASE_URL = 'https://api.imfact.io/api'
export const IMAGE_URL = 'https://images.imfact.io'
export const VAPIDPublicKey = "BM8_fdpwngUct4Mjp1bm5qtwG_8sulSwWA3Ep14qwE5IXiueuC1Lv4gDAnnvAiQG0x4o8ntRk8RVHjpXt5_N7vc"
export const VAPIDPrivatKey = "6yO5xbdPktr61Is-7Z-RDVgYHYs6n90I9QB5no5gpsY"
export const STORE_ID = "store-eb0f3207-c3e1-4ad1-9e74-f83cb1dfe9a2"
export const MERCHANT_ID = ""
export const CHANNEL_KEY = "channel-key-7f9157f2-480f-4ce9-9956-5210f0b4bd7c"
export const PORTONE_API_SECRET = "Qs1Bhvwl3Wo2qRYLOBZoA8G3YZnluEalGaVEfrR3r1AkC8l4wpeKlK0YuxHb0pfGHBzSZgCzbDNpkeAQ"

export const PLATFORMS = [
    {
        title: "YouTube",
        kor_title: "유튜브",
        key: "youtube",
        logo: require('../assets/Group (3).png'),
    },
    {
        title: "YouTube Shorts",
        kor_title: "유튜브쇼츠",
        key: "youtube_shorts",
        logo: require('../assets/Group (3).png'),
    },
    {
        title: "YouTube Shopping",
        kor_title: "유튜브쇼핑",
        key: "youtube_shopping",
        logo: require('../assets/Group (3).png'),
    },
    {
        title: "Instagram",
        kor_title : "인스타그램",
        key: "instagram",
        logo: require('../assets/Insta Button.png'),
    },
    {
        title: "Instagram Reels ",
        kor_title : "인스타릴스",
        key: "instagram_reels",
        logo: require('../assets/Insta Button.png'),
    },
    {
        title: "TikTok",
        kor_title: "틱톡",
        key: "tiktok",
        logo: require('../assets/Group (4).png'),
    },
    {
        title: "TikTok Shopping ",
        kor_title: "틱톡쇼핑",
        key: "tiktok_shopping",
        logo: require('../assets/Group (4).png'),
    },
    {
        title: "Naver Blog ",
        kor_title: "네이버블로그",
        key: "naver_blog",
        logo: require('../assets/naver.png'),
    },
    {
        title: "Naver Clip ",
        kor_title: "네이버클립",
        key: "naver_clip",
        logo: require('../assets/naver.png'),
    },
  
];
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formateDate } from '../utils/formateDate'
import { useNavigate } from 'react-router-dom'
import DeliveryBtn from './DeliveryBtn'
import FeedbackPopup from './FeedbackPopup'

function OrderSideBar({ campaign, orders, setRefresh = () => { } }) {
    const [t] = useTranslation('global')
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user') || "{}")
    const isCustomer = user.role === 'customer' ? true : false

    return (
        <div className='w-full flex flex-col gap-1 bg-white rounded-xl border-[1px] border-themeGrey-70 px-6 py-8 sm:p-4'>

            {!isCustomer && <DeliveryBtn setRefresh={setRefresh} campaign={campaign} deadline={campaign.content_deadline} is10Percent={campaign.campaign_option === '10%' ? true : false} order={orders[0]} />}
            {!isCustomer && orders[0]?.status === 'Completed' && !orders[0]?.review_given &&
            <span className='mb-4'>
                <FeedbackPopup campaign={campaign} orders={orders} setRefresh={setRefresh} isCreator={true}/>
            </span>
            }
            {isCustomer &&
                <p className={`flex w-full mb-4 justify-between items-center text-sm font-medium text-[#555555]`}>
                    <span className='font-semibold'>{t('campaign_status')}:</span>
                    <span className='capitalize bg-themeBlue/15 text-themeBlue px-4 text-sm py-0.5 rounded-lg'>{campaign.status}</span>
                </p>}
            <p className={`flex w-full justify-between items-center text-sm font-medium text-[#555555]`}>
                <span className='font-semibold'>{t('campaign_type')}:</span>
                <span className='capitalize'>{campaign.campaign_type}</span>
            </p>
            <p className={`flex w-full  justify-between items-center text-sm font-medium text-[#555555]`}>
                <span className='font-semibold'>{t('category')}:</span>
                <span className='capitalize'>{campaign.category?.name}</span>
            </p>
            <p className={`flex w-full justify-between items-center text-sm font-medium text-[#555555]`}>
                <span className='font-semibold'>{t('expMeth')}:</span>
                <span className='capitalize'>{campaign.campaign_option}</span>
            </p>

            <p className={`flex w-full mt-8 justify-between items-center text-sm font-medium text-[#555555]`}>
                <span className='font-semibold'>{t('customer')}:</span>
                <span className='capitalize'>{campaign.creator.nickname}</span>
            </p>
            <p className='flex w-full justify-between items-center text-sm font-medium text-[#555555]'>
                <span className='font-semibold'>{t('customerID')}:</span>
                <span>{orders[0]?.customerID}</span>
            </p>
            <p className='flex w-full justify-between items-center text-sm font-medium text-[#555555]'>
                <span className='font-semibold'>{t('level')}:</span>
                <span>{campaign.creator.level}</span>
            </p>

            {campaign.creator?._id !== user._id &&
                <button
                    onClick={() => {
                        sessionStorage.setItem('chatUser', JSON.stringify({
                            nickname: campaign.creator?.nickname,
                            profilePic: campaign.creator?.profilePic,
                            _id: campaign.creator?._id
                        }))
                        navigate(`/inbox/${campaign.creator?.nickname}`)
                    }}
                    className='mt-1 border-[1px] border-themePink rounded-lg py-2 text-sm w-full text-themePink hover:bg-themePink hover:text-white '>
                    {t('chat_withcust')}
                </button>
            }


            {isCustomer &&
                <>
                    {/* dates */}

                    {/* status */}
                    <p className='text-base mt-8 font-semibold text-themeBlack-300'>{t('current_status')}</p>
                    <p className='flex w-full justify-between items-center text-sm font-medium text-[#555555]'>
                        <span>{t('TotalAsked')}:</span>
                        <span>{campaign.creator_count}</span>
                    </p>
                    <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span>{t('Total_Applied')}:</span>
                        <span>{orders.length}</span>
                    </p>
                    <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span>{t('Approve')}:</span>
                        <span>{orders.filter((o) => !['Pending', 'Rejected'].includes(o.status)).length}</span>
                    </p>
                    <hr className='w-full h-0.5 border-[1px] border-themeGrey-70 border-t-0' />
                    {campaign.campaign_type === 'Store' && <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span> {t('tVisit')}:</span>
                        <span>{orders.filter((o) => o.otp_verified === true).length}</span>
                    </p>}
                    <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span> {t('Delivered')}:</span>
                        <span>{orders.filter((o) => ["Delivered", "Completed", 'Disputed', 'Rejected'].includes(o.status)).length}</span>
                    </p>
                    <hr className='w-full h-0.5 border-[1px] border-themeGrey-70 border-t-0' />
                    <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span>{t('total_delivered')}:</span>
                        <span>{orders.filter((o) => ["Delivered", "Completed", 'Disputed', 'Rejected'].includes(o.status)).length}</span>
                    </p>
                    <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span>ㄴ {t('TotalDisputed')}:</span>
                        <span>{orders.filter((o) => o.status === 'Disputed').length}</span>
                    </p>
                    <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span>ㄴ {t('Total_Completed')}:</span>
                        <span>{orders.filter((o) => o.status === 'Completed').length}</span>
                    </p>
                    <p className='flex w-full justify-between items-start text-sm font-medium text-[#555555]'>
                        <span>ㄴ {t('total_cancelled')}:</span>
                        <span>{orders.filter((o) => o.status === 'Cancelled').length}</span>
                    </p>

                </>
            }

            {campaign.campaign_type === 'Store' &&
                <>
                    <p className='flex w-full mt-8 justify-between items-center text-sm font-medium text-[#555555]'>
                        <span className='font-semibold'>{t('reservation_date')}:</span>
                        <span>{campaign.campaign_option === '10%' ? t("anytime") : formateDate(campaign.reservation_date)}</span>
                    </p>
                    <p className='flex w-full justify-between flex-col items-start text-sm font-medium text-[#555555]'>
                        <span className='font-semibold'>{t('address')}:</span>
                        <span>{campaign.visit_address}</span>
                    </p>
                </>
            }


            <p className='text-[13px] text-themeBlack-200 leading-[18px] mt-16 text-center sm:hidden'>Not Agreed? Ask for help here <br /> <span className='text-themeBlue'>Help center</span></p>

        </div>
    )
}

export default OrderSideBar

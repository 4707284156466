import React from 'react'
import { useTranslation } from 'react-i18next'

function SettingsTabs({ setActive, active }) {
    const [t] = useTranslation("global")
    
    const click = (val) => {
        setActive(val)
    }
  return (
    <div className='flex justify-start items-start gap-0 w-full shadow-sm sm:shadow-none sm:border-none sm:rounded-none sm:gap-4 shadow-themeGrey-500 rounded-lg overflow-hidden'>
        <button onClick={()=>click(0)} className={`px-5 pt-2 pb-1 sm:rounded-lg text-base border-b-[0px] ${active === 0 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600  '} w-1/3 outline-none transition-all ease-in duration-0`}>
            {t("auto")}
        </button>
        <button onClick={()=>click(1)} className={`px-5 pt-2 pb-1  sm:rounded-lg sm:border-none text-base border-b-[0px] ${active === 1 ? 'border-themePink text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600  border-x-[1px]'} w-1/3 outline-none transition-all ease-in duration-0`}>
            {t("push")}
        </button>
        <button onClick={()=>click(2)} className={`px-5 pt-2 pb-1  sm:rounded-lg text-base border-b-[0px] ${active === 2 ? 'border-themePink  text-white bg-themePink' : 'border-themeGrey-150 text-themeBlack-500 bg-themeGrey-600 '} w-1/3 outline-none transition-all ease-in duration-0`}>
            {t("board")}
        </button>
    </div>
  )
}

export default SettingsTabs

import React from 'react'

function Button({
  onClick = () => {},
  text = "Button",
  disabled = false,
  active = true,
  theme = 'Pink'
}) {
  return (
    <button
      disabled={disabled}
      onClick={()=> {
        if(active && !disabled)
          onClick()
      }}
      className={`w-full ${!active ? "bg-themeGrey-150 cursor-not-allowed" : theme === 'Pink' ?"bg-themePink" : "bg-themeYellow"} py-3 px-2.5 rounded-lg outline-none text-white font-bold tracking-wide text-sm disabled:opacity-75 disabled:cursor-wait ${active && !disabled && "hover:scale-[0.98]"} transition-all ease-in duration-100`}>
      {text}
    </button>
  )
}

export default Button

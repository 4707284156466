import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsBox, BsBoxFill } from 'react-icons/bs'
import { FaList, FaListUl } from 'react-icons/fa'
import { PiStorefrontDuotone, PiUser, PiWalletLight } from 'react-icons/pi'
import { TbTimeDuration10 } from 'react-icons/tb'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { IoSettingsOutline } from 'react-icons/io5'

function MobileLandingNav({ query, setQuery }) {
    const [more, setMore] = React.useState(false)
    const [t] = useTranslation('global');
    const location = useLocation();
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('token') && localStorage.getItem('user') ? true : false;
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    const [isNavVisible, setIsNavVisible] = useState(true);
    const [scrollTimeout, setScrollTimeout] = useState(null);

    const handleScroll = () => {
        setIsNavVisible(false);
        setMore(false);
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }
        const newTimeout = setTimeout(() => {
            setIsNavVisible(true);
        }, 150); // Show nav after scrolling stops
        setScrollTimeout(newTimeout);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (scrollTimeout) clearTimeout(scrollTimeout);
        };
    }, [scrollTimeout]);



    return (
        <div className={`hidden sm:flex flex-col fixed bottom-0 bg-white left-0 w-full border-t-[1px] z-50 border-themeGrey-70 transition-transform ${isNavVisible ? 'translate-y-0' : 'translate-y-full'} duration-150`}>
            <div className='flex justify-evenly gap-4 z-10 bg-white items-center px-4 py-2 w-full mobile-nav'>
                <div onClick={() => setQuery({ ...query, type: "Product" })} className={` ${query.type == 'Product' ? 'active' : ''} flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs`}>
                    <BsBox className={`${query.type == 'Product' ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-2xl mb-1`} />
                    {t('Product')}
                </div>
                <div onClick={() => setQuery({ ...query, type: "Store" })} className={` ${query.type == 'Store' ? 'active' : ''} flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs`}>
                    <PiStorefrontDuotone className={`${query.type == 'Store' ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-3xl mb-[2px]`} />
                    {t('Store')}
                </div>
                <div onClick={() => setQuery({ ...query, type: "10%" })} className={` ${query.type == '10%' ? 'active' : ''} flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs`}>
                    <TbTimeDuration10 className={`${query.type == '10%' ? 'text-themePink' : 'text-[#757575]'} opacity-65 text-3xl`} />
                    {t('10%')}
                </div>
                <Link to={loggedIn ? user.role === 'customer' ? '/manage' : '/orders' : '/login'} className={` flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs`}>
                    <FaList className={`text-[#757575] opacity-65 text-2xl mb-1.5`} />
                    {t('manage')}
                </Link>
                <div onClick={() => loggedIn? setMore(!more) : navigate('/login')} className={` flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs`}>
                    <HiOutlineDotsHorizontal className={`text-[#757575] opacity-65 text-2xl mb-1`} />
                    {more ? t('close') : t('more')}
                </div>
            </div>

            <div className={`w-full shadow-lg h-40 absolute z-0 ${more ? '-bottom-5' : '-bottom-[100px]'} transition-all ease-in duration-100`}>
                <div className='flex justify-start gap-6 z-10 bg-white items-center px-6 py-3 pt-4 w-full mobile-nav'>
                    <NavLink to='/wallet' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                        <PiWalletLight className={`text-[#757575] opacity-65 text-2xl`} />
                        {t('wallet')}
                    </NavLink>
                    <NavLink to='/profile/my-page' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                        <PiUser className={`text-[#757575] opacity-65 text-2xl`} />
                        {t('MyPage')}
                    </NavLink>

                    <NavLink to='/profile/settings' className='flex flex-col justify-center items-center gap-1 text-[#757575AD] text-xs'>
                        <IoSettingsOutline className={`text-[#757575] opacity-65 text-2xl`} />
                        {t('setting')}
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default MobileLandingNav



import React, { useState } from 'react';
import ApplicantHeader from './ApplicantHeader';
import { useTranslation } from 'react-i18next';
import { sendOrderOTP } from '../services/sendOrderOTP';
import OrderSubmission from './OrderSubmission';
import CompleteOrDispute from './CompleteOrDispute';

const OrderAccordian = ({ campaign, order, setRefresh, index }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [t] = useTranslation('global');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const [loading, setLoading] = useState(false);
    const [dispute, setDispute] = useState(false);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const sendOTP = async () => {
        if (order.otp_verified) return;
        setLoading(true);
        const data = {
            orderId: order._id,
            userId: order.creator._id,
        };

        await sendOrderOTP(data);
        setLoading(false);
    }

    return (
        <div id='order' className="border-[1px] border-themeGrey-70 py-4 sm:py-2 bg-white rounded-md sm:shadow-sm">
            {user.role === 'customer' &&
                <div
                    className="px-6 sm:px-3 w-full text-left cursor-pointer flex justify-between items-center focus:outline-none"
                    onClick={toggleAccordion}
                >
                    <span className="text-base text-themeBlack-200 font-medium">{order.OrderID}</span>
                    <div className='flex justify-end gap-4 items-center'>
                        {campaign?.campaign_type === 'Store' && !order.otp_verified &&
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation()
                                    sendOTP()
                                }}
                                disabled={loading}
                                className='px-4 py-1 border-[1px] text-xs border-themePink rounded-md text-themePink'>
                                {t('Send_OTP')}
                            </button>
                        }
                        <span className='sm:flex hidden text-themeBlue min-w-20 max-w-44 disabled:cursor-wait disabled:opacity-75 text-xs px-4 justify-center items-center py-1 bg-themeBlue/10  rounded-md cursor-pointer outline-none'>
                            {order.status}
                        </span>
                        <svg
                            className={`w-5 h-5 sm:h-5 sm:w-5 transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''
                                }`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                    </div>
                </div>}
            <div
                className={`overflow-hidden transition-max-height duration-500 ease-in-out flex flex-col gap-3  ${isOpen ? 'max-h-max' : 'max-h-0'}`} >
                <div className={`px-6 sm:px-3 ${order.creator._id !== user._id && "mt-4"}`}>
                    <ApplicantHeader setDispute={setDispute} creator={order.creator} order={order} setRefresh={setRefresh} user={user} index={index}/>
                </div>
                {order.submissions.length > 0 && <>
                    <hr className='w-full h-0.5 border-t-0 border-b-[1px] border-themeGrey-70' />
                    <div className='flex flex-col justify-end items-start pl-[100px] sm:pl-3'>
                        <h2 className='text-sm font-semibold text-themeBlack-300'>{t('Submissions')}</h2>
                        {order.submissions.map((submission, index) => (
                            <div className='flex flex-col gap-2 sm:pl-2'>
                                <OrderSubmission key={index} submission={submission} />
                                {index < order.submissions.length - 1 && <hr className='h-0.5 w-full border-t-0 border-b-[2px] border-themeGrey-70' />}
                            </div>
                        ))}

                    </div>
                </>}

                {dispute &&
                    <div className='flex justify-end flex-col items-end gap-2 w-full'>
                        <hr className='h-0.5 w-full border-t-0 border-b-[1px] border-themeGrey-70' />
                        <div className='pr-6 pl-[100px] w-full sm:px-3'>
                            <CompleteOrDispute order={order} setRefresh={setRefresh} submission={order.submissions[order.submissions.length - 1]} />
                        </div>
                    </div>}
            </div>
        </div>
    );
};

export default OrderAccordian;


import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useTimer } from 'react-timer-hook';
import Button from './Button';
import DeliverPopup from './DeliverPopup';

function DeliveryBtn({ deadline, setRefresh, campaign, order, is10Percent }) {
    let expiryTimestamp = new Date(deadline)
    const [active, setActive] = useState(true)
    const [t] = useTranslation('global')
    const [timeLeft, setTimeLeft] = useState()
    const [popup, setPopup] = useState(false)
    const { totalSeconds, seconds, minutes, hours, days } = useTimer({ expiryTimestamp, onExpire: () => setActive(false) });

    useEffect(() => {
        const fixValue = (x) => { return x <= 9 ? `0${x}` : x }
        let daysLeft = days % 30
        setTimeLeft({
            months: fixValue((days - daysLeft) / 30),
            days: fixValue(daysLeft),
            hours: fixValue(hours),
            minutes: fixValue(minutes),
            seconds: fixValue(seconds),
        })
    }, [totalSeconds])

    return (
        <div className='flex flex-col gap-3 mb-2'>
            {is10Percent ?
                <p className='text-sm font-medium text-themeBlack-500 text-center'>{t('day_of_visit')}</p>
                :
                <p className='text-sm font-medium text-themeBlack-500 text-center'>
                    {timeLeft?.months > 0 && (
                        <span>{timeLeft?.months}mo - </span>
                    )}
                    <span>{timeLeft?.days}d - </span>
                    <span>{timeLeft?.hours}h - </span>
                    <span>{timeLeft?.minutes}m - </span>
                    <span>{timeLeft?.seconds}s</span>
                </p>
            }

            <span className='scale-y-[0.88]'>

                <Button active={
                   campaign.status === 'Active Period' && (order.status === 'Active' || order.status === 'Disputed') ? true : false
                } onClick={() => setPopup(true)} text={t('deliver')} />
            </span>

            {popup && <DeliverPopup setRefresh={setRefresh} campaign={campaign} order={order} setPopup={setPopup} />}
        </div>
    )
}

export default DeliveryBtn



import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { SlCloudUpload } from 'react-icons/sl';
import { RxCross1, RxCross2 } from 'react-icons/rx';
import { IMAGE_URL } from '../constants';
import { convertBase64 } from '../utils/convertBase64';

const DescriptionImage = ({ data, setData }) => {
    const [descriptionImage, setDescriptionImage] = useState(data.descriptionImage ? `${IMAGE_URL}/uploads/${data.descriptionImage}` : null);
    const productRef = React.createRef();
    const [t] = useTranslation('global')


    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const base64 = await convertBase64(file);
        setDescriptionImage(base64);

        setData((prevData) => ({
            ...prevData,
            descriptionImageToUpload: file,
        }));
    };





    return (
        <div className='px-6 flex flex-col gap-2'>

            <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("description_image")}</h3>
            <div className='flex flex-wrap justify-start gap-4'>
                {descriptionImage ?

                    <div onClick={() => productRef?.current?.click()} className='w-80 sm:min-h-48 sm:w-full h-56 rounded-lg overflow-hidden relative shadow-sm border-[1px] border-themeGrey-150'>
                        <img src={descriptionImage} alt='Product' className='w-full h-full object-cover' />
                    </div>

                    :
                    <>
                        {!data.paymentProccessed && <div onClick={() => productRef?.current?.click()} className='relative sm:min-h-48  sm:w-full overflow-hidden !cursor-pointer flex justify-center items-center gap-1 flex-col w-80 h-56 rounded-lg border-[1px] border-themeBlue border-dashed bg-themeBlue/10'>
                            <SlCloudUpload className='text-themeBlue text-3xl !cursor-pointer' />
                            <p className='text-themeBlue text-center text-xs !cursor-pointer'>{t("upload_p_img")}</p>
                        </div>}
                    </>
                }
                <input accept='image/*' onChange={handleImageUpload} ref={productRef} type='file' className='absolute top-16 left-5 opacity-0' onClick={(e) => e.stopPropagation()} />

            </div>

        </div>
    );
};

export default DescriptionImage;


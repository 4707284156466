import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import OrderCampaignHeader from '../components/OrderCampaignHeader'
import { getOrderData } from '../services/getOrderData'
import OrderAccordian from '../components/OrderAccordian'
import OrderSideBar from '../components/OrderSideBar'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft } from 'react-icons/fa'

function CreatorOrder() {
    const { orderId } = useParams()
    const [data, setData] = useState(null)
    const [t] = useTranslation("global")
    const [refresh, setRefresh] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            const data = await getOrderData(orderId)
            setData(data)
        }
        fetchData()
    }, [refresh])
    return (
        <>
            {data && <div className='py-10 flex justify-between items-start gap-16 sm:py-5 sm:pb-32 sm:px-6'>
                <div className='w-[70%] flex flex-col gap-5 sm:w-full'>
                <div className='hidden sm:flex justify-start items-center gap-5'>
                    <FaArrowLeft onClick={() => navigate(-1)} className='bg-themePink text-[28px] px-2 py-1 text-white rounded-full' />
                    <h1 className='text-black text-lg font-bold'>{t('order')}</h1>
                </div>
                    <OrderCampaignHeader campaign={data?.campaign} />
                    <div className='sm:flex hidden'>
                        <OrderSideBar campaign={data.campaign} orders={[data.order]} setRefresh={setRefresh} />
                    </div>
                    <OrderAccordian order={data.order} setRefresh={setRefresh} />

                    <div className='flex flex-col gap-0 mt-3'>
                        <p className='text-sm text-[#222] font-semibold'>{t('product')}</p>
                        <h3 className='text-themeBlack-200 text-sm'>{data?.campaign?.product_offer}</h3>

                    </div>
                    <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                        {data?.campaign?.product_description?.split('\n').map((line, index) => (
                            <p key={index}>{line}</p>
                        ))}
                    </p>
                    {data?.campaign.campaign_option === 'Payback' &&
                        <div className='flex flex-col gap-0 mt-3'>
                            <p className='text-sm text-[#222] font-semibold'>{t('how_to_purchase')}</p>
                            <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                                {data?.campaign?.how_to_purchase?.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}
                            </p>
                        </div>}
                    <div className='flex flex-col gap-0 mt-3'>
                        <p className='text-sm text-[#222] font-semibold'>{t('what_to_do')}</p>
                        <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                            {data?.campaign?.what_to_do?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </p>
                    </div>
                    <div className='flex flex-col gap-0 mt-3'>
                        <p className='text-sm text-[#222] font-semibold'>{t('what_emphasized')}</p>
                        <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                            {data?.campaign?.what_should_be_emphasized?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </p>
                    </div>
                    <div className='flex flex-col gap-0 mt-3'>
                        <p className='text-sm text-[#222] font-semibold'>{t('Whatnottodo')}</p>
                        <p className='text-[13px] text-themeBlack-200 leading-[18px]'>
                            {data?.campaign?.what_not_to_do?.split('\n').map((line, index) => (
                                <p key={index}>{line}</p>
                            ))}
                        </p>
                    </div>
                </div>

                <div className='w-[30%] sm:hidden'>
                    <OrderSideBar campaign={data.campaign} orders={[data.order]} setRefresh={setRefresh} />
                </div>
            </div>
            }
        </>
    )
}

export default CreatorOrder

import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function ProfileSkeleton() {
    return (
        <SkeletonTheme baseColor="#F3F3F3" highlightColor="#E5E5E5">
            <div className='pb-20 px-60 md:px-16 pt-10 flex justify-between gap-16 md:gap-8 items-start sm:px-6 sm:flex-col sm:pt-4 bg-themeGrey-400/30 min-h-screen'>
                <div className='flex flex-col w-[25%] gap-5 sm:w-full'>
                    <Skeleton count={1} width={"100%"} height={"300px"} style={{ borderRadius: "12px" }} />
                    <Skeleton count={1} width={"100%"} height={"50px"} style={{ borderRadius: "12px" }} />


                </div>

                <div className={`w-[75%] flex flex-col gap-20 sm:w-full`}>
                    <div className='flex flex-col gap-3'>
                        <Skeleton count={1} width={"150px"} height={"12px"} style={{ borderRadius: "12px" }} />
                        <hr className='w-full h-1 border-t-[0px] border-b-[1px] border-themeGrey-70' />
                            <Skeleton count={1} width={"100%"} height={"200px"} style={{ borderRadius: "12px" }} />
                            <Skeleton count={1} width={"100%"} height={"200px"} style={{ borderRadius: "12px" }} />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default ProfileSkeleton

import { toast } from "react-toastify";
import { PORTONE_API_SECRET } from "../constants";
import axios from 'axios';

const getAuthorzation = async () => {
    const config = {
        url: 'https://api.portone.io/login/api-secret',
        method: 'post',
        data: {
            "apiSecret": PORTONE_API_SECRET
        },
        headers: {
            'Content-Type': 'application/json', 
        },
        timeout: 5000,
    };
  
    try {
        const response = await axios(config);
        return response.data.accessToken
    } catch (error) {
        console.error(error);
    }
    return null
  }
  
  export const portoneRefundApi = async (refund) => {
    const token = await getAuthorzation()
    if(!token)
        throw new Error("Access token could not be fetched from portone")
  
    const config = {
        url:   `https://api.portone.io/payments/${refund.paymentID}/cancel`,
        method: 'post',
        data: {
            "paymentId": refund.paymentID,
            "reason": "Customer Request",
            "amount": Number(refund.refundAmount),
            "currentCancellableAmount": Number(refund.totalPayment),
        },
        headers: {
            'Content-Type': 'application/json', 
            'Authorization': `Bearer ${token}`
        },
        timeout: 5000,
    };
  
    try {
        const response = await axios(config);
  
        console.log(response.status);
        if(response.status === 200)
            return true;
        else
            toast.error("PortOne: ", response.data.message)
    } catch (error) {
        console.error(error);
    }
    
    return false;
  
  }


import { BASE_URL } from "../constants"
import { getToken } from "../utils/getToken"

export const sendMessage = async (data) => {
    try {
        const response = await fetch(`${BASE_URL}/chat/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: getToken()
            },
            body: JSON.stringify(data)
        })
    
        const res = await response.json()
        if (response.status === 201){
          return true
        }
       
    } catch (error) {
        console.log(error);
    }

    return false;
}
import { motion, AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import Input from '../components/Input'
import Button from '../components/Button'
import { Link, useNavigate } from 'react-router-dom'
import { validateEmail } from '../constants/validations'
import { BASE_URL } from '../constants'
import { getMyProfile } from '../services/getMyProfile'
import { ResendOtp } from '../services/ResendOtp'
import { useTranslation } from 'react-i18next'

function Login() {
    const [activeBtn, setActiveBtn] = React.useState(false)
    const [data, setData] = React.useState({})
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [checkbox, setCheckbox] = React.useState(false)
    const navigate = useNavigate()
    const [t] = useTranslation('global')
    const [role, setRole] = React.useState(localStorage.getItem('loginRole') || 'creator')

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const onCLick = async (e) => {
        e.preventDefault()
        if(loading || !activeBtn) return
        setLoading(true)
        try {

            setError(null)
            const response = await fetch(`${BASE_URL}/user/login?role=${role}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            const res = await response.json()
            if (response.status === 200) {
                localStorage.setItem('token', res.token)
                const user = await getMyProfile()
                if (!user) {
                    localStorage.removeItem('token')
                    setError('Please try again')
                    setLoading(false)
                    return;
                }
                if (user.approved) {
                    window.location.href = '/profile/my-page'
                }
                else {
                    window.location.href = '/profile/my-page'
                }

                if(checkbox){
                    localStorage.setItem('loggedInUser', JSON.stringify(data))
                }
            }
            else if (response.status === 422) {
                setError(res.message)
            }
            else
                setError(res.message)
        } catch (error) {
            console.error(error)
        }

        setLoading(false)
    }

    useEffect(() => {
        setData(JSON.parse(localStorage.getItem('loggedInUser') || '{}'))
        setError(null)
        localStorage.setItem('loginRole', role)
    }, [role])

    useEffect(() => {
        if (validateEmail(data.email ?? '') && data.password?.length >= 8) {
            setActiveBtn(true)
        }
        else
            setActiveBtn(false)
    }, [data])

    return (
        <div className='w-full h-screen flex flex-1 bg-themeGrey-400/30 items-center justify-center overflow-hidden relative sm:bg-white'>
            <Link to='/' className='w-10 h-10 absolute left-16 top-3 sm:top-8 sm:left-8'>
                <img src={'/logo.png'} className='w-10 h-10 object-contain' />
            </Link>

            <div
                onClick={() => {
                    setRole(role === 'customer' ? 'creator' : 'customer')
                }}
                className='hidden z-50 sm:flex absolute top-8 right-10 px-2 py-1.5 border-[1px] rounded-md border-themeGrey-70 text-xs text-themeBlack-300'>

                {t('are_you')} {role === 'customer' ? t('creator') : t('customer')} ?
            </div>

            {/* <div className='px-14 py-10 w-[60%] sm:hidden'>
                <h1 className='text-white font-extrabold text-[25px] select-none'>im;fact</h1>
                <div className='w-[70%] mt-20'>
                    <h2 className='text-white text-[45px] leading-[55px] mt-5 font-bold'>Top Local Influencer at one place</h2>
                    <p className='text-white font-semibold leading-6 text-lg w-[60%] mt-12'>Welcome to the premier marketplace where your brand’s vision comes to life! Create impactful campaigns and connect with top influencers to promote your products through authentic collaborations. Join us to elevate your brand and reach new audiences with powerful sponsored partnerships.</p>
                </div>
                <div className='absolute -bottom-14 left-[28%]'>
                    <img src={require('../assets/Group 2.png')} alt='refresh window' className='h-[70%] object-contain drop-shadow-2xl' />
                </div>
            </div> */}
            <div className='w-[35%] sm:w-full border-[1px] border-themeGrey-70 bg-white  rounded-[50px]'>
                <AnimatePresence>
                    <div
                        className='w-full bg-white rounded-[50px] shadow-sm h-auto sm:h-screen flex justify-center items-center sm:rounded-none sm:shadow-none'>
                        <div className='flex justify-center items-start sm:items-center sm:justify-start sm:py-24 flex-col gap-2 pt-14 pb-5 px-20 sm:px-0 relative h-full w-full sm:w-[80%]'>
                            <h1 className='text-[32px] leading-[42px] font-Pretendard font-bold text-themePink sm:text-center sm:text-[28px] capitalize'>{role} {t('loginn')}</h1>
                            <p className='text-themeGrey-300 text-base font-normal text-left w-full sm:text-sm sm:text-center'>{t('please_enter')}</p>
                            <form onSubmit={onCLick} className='flex justify-center items-center flex-col w-full gap-5 mt-8 sm:pb-7'>
                                <Input
                                    type={"email"}
                                    placeholder={t('your_email')}
                                    required={true}
                                    name={"email"}
                                    value={data.email}
                                    onChange={onChange}
                                    icon={<FaEnvelope className="text-base text-themeGrey-150 mt-1" />}
                                />
                                <Input
                                    type={"password"}
                                    placeholder={t('your_password')}
                                    required={true}
                                    name={"password"}
                                    value={data.password}
                                    onChange={onChange}
                                    icon={<FaLock className="text-base text-themeGrey-150 mt-1" />}
                                />
                                <div className='flex justify-start items-center w-full gap-3 -mt-2'>
                                    <input onChange={() => setCheckbox(!checkbox)} type="checkbox" className='checkbox sm:mt-0.5' checked={checkbox} />
                                    <p className='sm:text-sm text-themeBlack-400'>{t('remember_me')}</p>
                                </div>
                                {error && <p className='text-sm text-center text-red-600 sm:text-xs -my-2'>{error}</p>}
                                <Button text="Log In" active={activeBtn} disabled={loading} />
                            <div className=' w-full sm:mt-0 mt-32 left-0'>
                                <p
                                    onClick={() => {
                                        let data = {}
                                        const role = localStorage.getItem('loginRole') || 'creator'
                                        data.role = role
                                        localStorage.setItem("newUser", JSON.stringify(data))
                                        navigate('/signup')
                                    }}
                                className='sm:text-center cursor-pointer border-[1px] border-themeGrey-70 py-3 px-2.5 rounded-lg  flex justify-center items-center gap-1 text-sm text-themeBlack-400'>{t('dont_have_account')} 
                                    <span className='text-themePink'>{t('sign_up')}</span>.</p>
                            </div>
                            </form>
                        </div>
                    </div>
                </AnimatePresence>
            </div>
        </div>
    )
}

export default Login



import { BASE_URL } from "../constants";
import { getToken } from "../utils/getToken";

export const getMyBalanceHistory = async () => {
    try {
        const response = await fetch(`${BASE_URL}/user/getMyBalanceHistory`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'token': getToken()
            }
        })
        const res = await response.json()
        if (response.status === 200){
            return res.balance
        }
    } catch (error) {
        console.log(error);
    }
    return []
}
import React from 'react'
import { useTranslation } from 'react-i18next'

function PaymentStats({user}) {
    const [t] = useTranslation("global")
    return (
        <div className='flex flex-col gap-3  px-5 '>
            <div className='bg-themeGrey-400/50 rounded-lg  px-4 py-3 flex sm:p-2 justify-between items-center'>
                <div className='flex justify-start items-center gap-2'>
                    <p className='text-themeBlack-200 text-xs font-medium'>{t('payment')}</p>
                    <span className='px-2 py-1 text-xs rounded-md bg-themeBlue/70 text-white'>{t('available')}</span>
                </div>
                <span className='rounded-md bg-themeGrey-500 px-2 py-1 text-xs font-bold text-themeBlack-200'>
                    {user.AvailableBalance?.toLocaleString() || 0}
                </span>
            </div>
            <div className='bg-themeGrey-400/50 rounded-lg  px-4 py-3 flex sm:p-2 justify-between items-center'>
                <div className='flex justify-start items-center gap-2'>
                    <p className='text-themeBlack-200 text-xs font-medium'>{t('payment')}</p>
                    <span className='px-2 py-1 text-xs rounded-md bg-themeYellow/70 text-white'>{t('withdrawn')}</span>
                </div>
                <span className='rounded-md bg-themeGrey-500 px-2 py-1 text-xs font-bold text-themeBlack-200'>
                    {user.totalWithdrawn?.toLocaleString() || 0}
                </span>
            </div>
            <div className='bg-themeGrey-400/50 rounded-lg  px-4 py-3 flex sm:p-2 justify-between items-center'>
                <div className='flex justify-start items-center gap-2'>
                    <p className='text-themeBlack-200 text-xs font-medium'>{t('payment')}</p>
                    <span className='px-2 py-1 text-xs rounded-md bg-themePink/70 text-white'>{t('total')}</span>
                </div>
                <span className='rounded-md bg-themeGrey-500 px-2 py-1 text-xs font-bold text-themeBlack-200'>
                    {user.totalEarned?.toLocaleString() || 0}
                </span>
            </div>
        </div>
    )
}

export default PaymentStats

import React, { useContext, useEffect, useState } from 'react'
import LandingNav from '../components/LandingNav'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { fetchCampaign } from '../services/fetchCampaign'
import { IMAGE_URL, PLATFORMS } from '../constants'
import Button from '../components/Button'
import { defaultPfp } from '../utils/defaultPfp'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { convertToSeoulTime } from '../utils/convertToSeoulTime'
import { formateDate } from '../utils/formateDate'
import ImageGrid from '../components/ImageGrid'
import { RiShareForward2Fill } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { joinCmapaign } from '../services/joinCmapaign'
import CampDetailSekelton from '../components/CampDetailSekelton'
import ImageSlider from '../components/ImageSlider'
import { FaArrowLeft } from 'react-icons/fa'
import Footer from '../components/Footer'
import { IoHeartSharp } from 'react-icons/io5'
import { MdOutlineContentCopy } from 'react-icons/md'
import HomeLayout from '../layouts/HomeLayout'
import { getChatTime } from '../utils/getChatTime'

function Campaign() {
    const { _id } = useParams()
    const [campaign, setCampaign] = useState(null)
    const [t] = useTranslation("global")
    const [loading, setLoading] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [isfavorite, setIsfavorite] = useState(JSON.parse(localStorage.getItem('favoriteCampaigns') || '[]').includes(_id))
    const [active, setActive] = useState('campaign')

    useEffect(() => {
        const getCampaigns = async () => {
            const data = await fetchCampaign(_id)
            setCampaign(data)
        }

        getCampaigns()
    }, [_id])



    const _joinCmapaign = async () => {
        setLoading(true)
        const joined = await joinCmapaign(_id)
        if (joined) {
            navigate('/orders/pending')
        }
        setLoading(false)
    }

    const copyToClipboard = (value) => {
        const currentLocation = value
        const textarea = document.createElement('textarea');
        textarea.value = currentLocation;
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
            toast.success("Copied to clipboard!");
        } catch (err) {
            console.error("Failed to copy URL: ", err);
        }
        document.body.removeChild(textarea);
    };


    const favorite = () => {
        const campaigns = JSON.parse(localStorage.getItem('favoriteCampaigns')) || []

        if (campaigns.includes(_id)) {
            let newCampaigns = campaigns.filter(c => c !== _id)
            localStorage.setItem('favoriteCampaigns', JSON.stringify(newCampaigns))
            setIsfavorite(false)
        }
        else {
            campaigns.push(_id)
            localStorage.setItem('favoriteCampaigns', JSON.stringify(campaigns))
            setIsfavorite(true)
        }
    }



    return (
        <HomeLayout>
            <div className='pb-20 sm:pb-10'>

                <div className='hidden sm:flex z-50 absolute top-2 left-0 w-full justify-between items-center gap-3 mb-4 py-0 pt-4 px-4'>
                    <div className='rounded-full p-1 glasssss flex justify-center items-center'>
                        <FaArrowLeft onClick={() => navigate(-1)} className='text-themeBlack-150/70 drop-shadow-[0_0_4px_rgba(255,255,255)] text-2xl' />
                    </div>

                    <div className='flex justify-end items-center gap-3'>
                        <span className='rounded-full p-1 glasssss flex justify-center items-center'>
                            <RiShareForward2Fill onClick={() => copyToClipboard(window.location.href)} className='text-themeBlack-150/70 drop-shadow-[0_0_4px_rgba(255,255,255)] text-2xl' />
                        </span>
                        <span onClick={favorite} className='rounded-full p-1 glasssss flex justify-center items-center'>
                            <IoHeartSharp className={`${isfavorite ? 'text-[#F54363]' : 'text-themeGrey-150/70'} drop-shadow-[0_0_4px_rgba(255,255,255)] text-2xl`} />
                        </span>
                    </div>

                </div>
                {campaign ? <>

                    <div className='px-60 md:px-20 pt-5 flex flex-col gap-4 sm:px-0 sm:pb-0 sm:pt-0'>
                        <div className='flex justify-between items-start gap-10 sm:flex-col sm:gap-4' >
                            <div className='flex gap-4 w-[70%] sm:w-full flex-col-reverse pr-10 border-r-[1px] sm:pr-0 sm:border-none border-themeGrey-70'>
                                <div className='flex justify-between items-center sm:px-5'>
                                    <img src={PLATFORMS.filter(p => p.title === campaign?.platform)[0]?.logo} alt='refresh' className='w-7 h-7 sm:w-6 sm:h-6' />
                                    <span className='flex justify-end items-center gap-2 sm:justify-between sm:flex-row-reverse sm:w-full'>
                                        <div className='flex gap-1'>
                                            {(campaign.campaign_type === 'Store' || campaign.campaign_option === '10%') && <span className='text-sm text-themeBlack-300 border-[1px] sm:text-xs sm:px-2 sm:py-1 border-themeGrey-70 px-4 py-2 rounded-md'>
                                                {campaign?.region}
                                            </span>}
                                            <span className='text-sm text-themeBlack-300 border-[1px] sm:text-xs sm:px-2 sm:py-1 border-themeGrey-70 px-4 py-2 rounded-md'>
                                                {campaign?.category?.name}
                                            </span>
                                            <span className='text-sm text-themeBlack-300 border-[1px] sm:text-xs sm:px-2 sm:py-1 border-themeGrey-70 px-4 py-2 rounded-md'>
                                                {campaign?.media_fee}P
                                            </span>

                                        </div>
                                    </span>
                                </div>
                                <div className='border-[1px] border-themeGrey-70 rounded-lg shadow-md overflow-hidden sm:rounded-none sm:shadow-none sm:border-none relative'>
                                    <ImageSlider images={campaign.product_images} classes={`w-full h-[550px] sm:h-[350px] sm:rounded-none`} />
                                    <span className='rounded-full p-1 glasssss flex justify-center items-center absolute top-2 sm:hidden right-2'>
                                        <RiShareForward2Fill onClick={() => copyToClipboard(window.location.href)} className='text-themeBlack-150/70 drop-shadow-[0_0_4px_rgba(255,255,255)] text-2xl' />
                                    </span>
                                    <div className='transition-all ease-in duration-100 hh flex justify-between items-center absolute bottom-4 sm:top-[250px] px-3 w-full h-4'>
                <span className='text-xs glasssss  font-medium text-black rounded-full px-2 py-1'>
                    {campaign.joined || 0}/{campaign.creator_count}
                </span>
                <span className='glasssss  text-xs font-medium text-black rounded-full px-2 py-1'>
                    {getChatTime(new Date(campaign.start_date))}
                </span>
            </div>
                                </div>


                            </div>

                            <div className='justify-between items-center hidden sm:flex w-full px-5'>
                                <button onClick={() => setActive('campaign')} className={`${active === 'campaign' ? 'bg-themePink' : 'bg-themeGrey-150'} rounded-l-md flex-1 py-2.5 text-white font-bold text-xs`}>
                                    {t('campaign')}
                                </button>
                                <button onClick={() => setActive('product')} className={`${active === 'product' ? 'bg-themePink' : 'bg-themeGrey-150'} rounded-r-md flex-1 py-2.5 text-white font-bold text-xs`}>
                                    {t('pproduct')}
                                </button>
                            </div>
                            <h1 className={`text-lg sm:flex hidden px-5 sm:text-sm font-bold text-themeBlack-300 ${active === 'product' && 'sm:hidden'} `}>{campaign?.campaign_title}</h1>
                            <p className={`text-[13px] -mt-2 sm:flex px-5 hidden text-themeBlack-200 leading-[18px]  ${active === 'product' && 'hidden'}`}>
                                {campaign?.product_description?.split('\n').map((line, index) => (
                                    <p key={index}>{line}</p>
                                ))}
                            </p>
                            <div className='flex flex-col gap-5 w-[30%] sm:w-full'>
                                <div className='flex flex-col gap-5  sm:py-0 sm:border-none sm:shadow-none border-[1px] shadow-md sm:rounded-none rounded-xl px-4 sm:px-6 py-6'>
                                    <hr className='w-full h-0.5 hidden sm:flex border-t-[0px] border-b-[1px] border-themeGrey-70' />
                                    <div className='flex flex-col gap-1 sm:hidden'>
                                        <h1 className='text-lg sm:hidden  sm:text-sm font-bold text-themeBlack-300'>{campaign?.campaign_title}</h1>
                                        <p className={`text-[13px] text-themeBlack-200 leading-[18px]`}>
                                            {campaign?.product_description?.split('\n').map((line, index) => (
                                                <p key={index}>{line}</p>
                                            ))}
                                        </p>
                                    </div>

                                    <div className={`flex justify-between ${active !== 'product' ? 'sm:flex' : 'hidden'}`}>
                                        <h1 className='text-themeBlack-300 text-xl sm:text-sm font-bold'>{t("joinee")}</h1>
                                        {/* <RiShareForward2Fill onClick={() => copyToClipboard(window.location.href)} className='text-themeBlack-200 sm:hidden cursor-pointer text-lg' /> */}
                                    </div>
                                    <div className={`flex flex-col gap-2 py-2 px-3 rounded-md bg-themeGrey-400 mb-2 ${active !== 'product' ? 'sm:flex' : 'hidden'}`}>
                                        <p className='text-sm text-themeBlack-200 flex justify-between items-center gap-2'>
                                            <span className='text-[#222]'>{t('startDate')}</span>
                                            <span className='text-[#222]'>{campaign?.campaign_option === '10%' ? t('day_of_visit') : formateDate(campaign?.start_date)}</span>
                                        </p>
                                        <p className='text-sm text-themeBlack-200 flex justify-between items-center gap-2'>
                                            <span className='text-[#222]'>{t('applicationPeriod')}</span>
                                            <span className='text-[#222]'>{campaign?.campaign_option === '10%' ? t('day_of_visit') : formateDate(campaign?.application_deadline)}</span>
                                        </p>
                                        <p className='text-sm text-themeBlack-200 flex justify-between items-center gap-2'>
                                            <span className='text-[#222]'>{t('content_period')}</span>
                                            <span className='text-[#222]'>{formateDate(campaign?.content_deadline)}</span>
                                        </p>
                                        {campaign?.campaign_type === 'Store' &&
                                            <p className='text-sm text-themeBlack-200- flex justify-between items-center gap-2'>
                                                <span className='text-[#222]'>{t('Visit')}</span>
                                                <span className='w-[50%] text-right text-[#222]'>{campaign?.visit_address}</span>
                                            </p>}
                                        {campaign?.campaign_type === 'Store' &&
                                            <p className='text-sm text-themeBlack-200 flex justify-between items-center gap-2'>
                                                <span className='text-[#222]'>{t('reservation_date')}</span>
                                                <span className='text-[#222]'>{campaign.campaign_option === '10%' ? t('anytime') : formateDate(campaign?.reservation_date)}</span>
                                            </p>}
                                        <p className='text-sm text-themeBlack-200 flex justify-between items-center gap-2'>
                                            <span className='text-[#222]'>{t('end_date')}</span>
                                            <span className='text-[#222]'>{formateDate(campaign?.endDate)}</span>
                                        </p>
                                    </div>

                                    <span className='flex sm:hidden justify-start items-center gap-4 pb-3 sm:pt-0 border-b-[1px] border-themeGrey-70 '>
                                        <div>
                                            <img src={campaign?.brand_image ? `${IMAGE_URL}/uploads/${campaign?.brand_image}` : defaultPfp()} className='w-14 h-14 sm:h-12 sm:w-12 rounded-full bg-themePink/10' alt='refreh' />
                                        </div>
                                        <span className='flex flex-col gap-1'>
                                            <h2 className='text-themeBlack-300 text-base sm:text-sm font-bold capitalize'>{campaign?.brand_title}</h2>
                                        </span>
                                    </span>
                                    <div className='sm:fixed bottom-0 left-0 w-full sm:px-5 sm:py-4 sm:bg-white sm:z-[100]'>
                                        <Button onClick={_joinCmapaign} disabled={loading} text='Join Now' active={user ?
                                            user.role === 'customer' ? false
                                                : campaign?.joinBtn
                                            : false} />
                                    </div>

                                </div>
                                <div className='justify-between items-center flex sm:hidden w-full'>
                                    <button onClick={() => setActive('campaign')} className={`${active === 'campaign' ? 'bg-themePink' : 'bg-themeGrey-150'} rounded-l-md flex-1 py-2.5 text-white font-bold text-xs`}>
                                        {t('campaign')}
                                    </button>
                                    <button onClick={() => setActive('product')} className={`${active === 'product' ? 'bg-themePink' : 'bg-themeGrey-150'} rounded-r-md flex-1 py-2.5 text-white font-bold text-xs`}>
                                        {t('pproduct')}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='px-60 md:px-20 flex sm:flex-col sm:gap-5 justify-between items-start gap-10 sm:px-6'>
                        <div className='flex flex-col gap-4 w-[70%] sm:w-full pr-10 border-r-[1px] sm:pr-0 sm:border-none border-themeGrey-70'>


                            <span className={`hidden justify-start items-center gap-4 pb-3 pt-5 sm:pt-4 border-b-[1px] border-themeGrey-70 ${active !== 'product' ? 'sm:flex' : 'hidden'}`}>
                                <div>
                                    <img src={campaign?.brand_image ? `${IMAGE_URL}/uploads/${campaign?.brand_image}` : defaultPfp()} className='w-14 h-14 sm:h-12 sm:w-12 rounded-full bg-themePink/10' alt='refreh' />
                                </div>
                                <span className='flex flex-col gap-1'>
                                    <h2 className='text-themeBlack-300 text-base sm:text-sm font-bold capitalize'>{campaign?.brand_title}</h2>
                                </span>
                            </span>

                            <div className={`flex flex-col gap-1 mt-3 ${active !== 'product' ? 'flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold'>{t('product')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.product_offer)} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <h3 className='text-themeBlack-200 text-sm bg-themeGrey-500/30 rounded-md px-4 py-2'>{campaign?.product_offer}</h3>
                            </div>
                            <div className={`flex-col gap-1 mt-3 ${active === 'product' ? 'flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold'>{t('description')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.product_description)} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <p className='text-[13px] flex text-themeBlack-200 leading-[18px] bg-themeGrey-500/30 rounded-md px-4 py-2'>
                                    {campaign?.product_description?.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </p>
                            </div>

                            <div className={`flex flex-col gap-1 mt-3 ${active !== 'product' ? 'flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold'>{t('Types_of_Submitted_Results')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.types_of_submitted_results)} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <p className='text-[13px] text-themeBlack-200 leading-[18px] bg-themeGrey-500/30 rounded-md px-4 py-2'>
                                    {campaign?.types_of_submitted_results?.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </p>
                            </div>
                            {campaign?.how_to_purchase?.length > 1 &&
                                <div className={`flex flex-col gap-1 mt-3 ${active !== 'product' ? 'flex' : 'hidden'}`}>
                                    <span className='flex justify-start items-center gap-2'>
                                        <p className='text-sm text-[#222] font-semibold'>{t('how_to_purchase')}</p>
                                        <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.how_to_purchase)} className='text-themeGrey-300 cursor-pointer' />
                                    </span>
                                    <p className='text-[13px] text-themeBlack-200 leading-[18px] bg-themeGrey-500/30 rounded-md px-4 py-2'>
                                        {campaign?.how_to_purchase?.split('\n').map((line, index) => (
                                            <p key={index}>{line}</p>
                                        ))}
                                    </p>
                                </div>
                            }


                            <div className={`flex flex-col gap-1 mt-3 ${active !== 'product' ? 'flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold'>{t('what_to_do')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.what_to_do)} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <p className='text-[13px] text-themeBlack-200 leading-[18px] bg-themeGrey-500/30 rounded-md px-4 py-2'>
                                    {campaign?.what_to_do?.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </p>
                            </div>
                            <div className={`flex flex-col gap-1 mt-3 ${active !== 'product' ? 'flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold'>{t('what_emphasized')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.what_should_be_emphasized)} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <p className='text-[13px] text-themeBlack-200 leading-[18px] bg-themeGrey-500/30 rounded-md px-4 py-2'>
                                    {campaign?.what_should_be_emphasized?.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </p>
                            </div>
                            <div className={`flex flex-col gap-1 mt-3 ${active !== 'product' ? 'flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold'>{t('Whatnottodo')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.what_not_to_do)} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <p className='text-[13px] text-themeBlack-200 leading-[18px] bg-themeGrey-500/30 rounded-md px-4 py-2'>
                                    {campaign?.what_not_to_do?.split('\n').map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </p>
                            </div>

                        </div>

                        {/* {active === 'product' && <div className={`w-[30%]`}></div>} */}
                        <div className={`w-[30%] h-full mt-3 sm:mt-0 flex flex-col gap-6 sm:w-full `}>
                    
                            <div className={`flex flex-col gap-1 ${active !== 'product' ? ' flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold'>{t('TitleKeyword')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.title_keyword.join(','))} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <div className='flex flex-col gap-1'>
                                    {campaign?.title_keyword?.map((keyword, index) => (
                                        <p className='text-themeBlack-200 font-medium text-xs bg-themeGrey-500/30 rounded-md  px-4 py-2'>
                                            {keyword}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <hr className={`w-full h-0.5 sm:hidden flex border-t-[0px] border-b-[1px] border-themeGrey-70 -my-3 ${active !== 'product' ? ' flex' : 'hidden'}`} />
                            <div className={`flex flex-col gap-1 ${active !== 'product' ? ' flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold mt-'>{t('BodyKeyword')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.body_keyword.join(','))} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <div className='flex flex-col gap-1'>
                                    {campaign?.body_keyword?.map((keyword, index) => (
                                        <p className='text-themeBlack-200 font-medium text-xs bg-themeGrey-500/30 rounded-md  px-4 py-2'>
                                            {keyword}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <hr className={`w-full h-0.5 sm:hidden flex border-t-[0px] border-b-[1px] border-themeGrey-70 -my-3 ${active !== 'product' ? ' flex' : 'hidden'}`} />
                            <div className={`flex flex-col gap-1 ${active !== 'product' ? ' flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold mt-'>{t('_tags')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.tags.map(tag => `#${tag}`).join(' '))} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <div className='flex gap-1'>
                                    {campaign?.tags?.map((keyword, index) => (
                                        <p className='text-themeBlack-200 font-medium text-xs bg-themeBlue/30 rounded-md px-4 py-2'>
                                            #{keyword}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <hr className={`w-full h-0.5 sm:hidden flex border-t-[0px] border-b-[1px] border-themeGrey-70 -my-3 ${active !== 'product' ? ' flex' : 'hidden'}`} />
                            <div className={`flex flex-col gap-1 ${active !== 'product' ? ' flex' : 'hidden'}`}>
                                <span className='flex justify-start items-center gap-2'>
                                    <p className='text-sm text-[#222] font-semibold mt-'>{t('account_tag')}</p>
                                    <MdOutlineContentCopy onClick={() => copyToClipboard(campaign?.account_tag.map(tag => `@${tag}`).join(' '))} className='text-themeGrey-300 cursor-pointer' />
                                </span>
                                <div className='flex gap-1'>
                                    {campaign?.account_tag?.map((keyword, index) => (
                                        <p className='text-themeBlack-200 font-medium text-xs bg-themeBlue/30 rounded-md px-4 py-2'>
                                            @{keyword}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div className='px-60 md:px-20 flex justify-between items-start gap-10 mt-8 sm:px-6 sm:flex-col'>


                        {active === 'product' && <div className='flex flex-col gap-2 w-[63%] sm:w-full'>
                            {campaign?.product_images?.map((image, index) => (
                                <img src={`${IMAGE_URL}/uploads/${image}`} alt='refresh' className='w-full sm:h-64 object-cover rounded-md' />
                            ))}
                        </div>}

                    </div>
                </> :
                    <CampDetailSekelton />
                }


            </div>
            <Footer />
        </HomeLayout>
    )
}

export default Campaign

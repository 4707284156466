import React, { useState, useRef, useEffect } from 'react';
import { IMAGE_URL, PLATFORMS } from '../constants';
import { GoDotFill } from 'react-icons/go';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { defaultCP } from '../utils/defaultCP';
import { Link } from 'react-router-dom';
import ImageSlider from './ImageSlider';
import { RiShareForward2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { defaultPfp } from '../utils/defaultPfp';
import { getChatTime } from '../utils/getChatTime';
import { IoHeartSharp } from 'react-icons/io5';

function CampaignCard({ campaign }) {

    const [isfavorite, setIsfavorite] = useState(JSON.parse(localStorage.getItem('favoriteCampaigns') || '[]').includes(campaign._id))
    const [t] = useTranslation("global")

    const favorite = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const campaigns = JSON.parse(localStorage.getItem('favoriteCampaigns')) || []

        if (campaigns.includes(campaign._id)) {
            let newCampaigns = campaigns.filter(c => c !== campaign._id)
            localStorage.setItem('favoriteCampaigns', JSON.stringify(newCampaigns))
            setIsfavorite(false)
        }
        else {
            campaigns.push(campaign._id)
            localStorage.setItem('favoriteCampaigns', JSON.stringify(campaigns))
            setIsfavorite(true)
        }
    }


    return (
        <Link to={`/campaign-detail/${campaign.campaign_title}/${campaign._id}`} className='flex flex-col sm:w-full relative'>

            <ImageSlider images={[campaign.descriptionImage || []]} classes={'w-64 h-64'} />
            <div className='flex justify-between items-center mt-2'>
                <img src={PLATFORMS.filter(p => p.title === campaign?.platform)[0]?.logo} alt='refresh' className='w-5 h-5 sm:w-5 sm:h-5' />
                <div className='flex justify-end items-center gap-1'>
                    {(campaign.campaign_type === 'Store' || campaign.campaign_option === '10%') && <span className='text-xs text-themeBlack-200 border-[1px] border-themeGrey-70 font-medium sm:text-xs sm:px-2 sm:py-1 px-2 py-1 rounded-md'>
                        {campaign?.region}
                    </span>}
                    <span className='text-xs text-themeBlack-200 border-[1px] border-themeGrey-70 font-medium sm:text-xs sm:px-2 sm:py-1 px-2 py-1 rounded-md'>
                        {campaign?.category?.name}
                    </span>
                    <span className='text-xs text-themeBlack-200 border-[1px] border-themeGrey-70 font-medium sm:text-xs sm:px-2 sm:py-1 px-2 py-1 rounded-md'>
                        {campaign?.media_fee}P
                    </span>
                </div>
            </div>
            <h2 className='text-sm font-medium sm:font-bold sm:text-base text-themeBlack-300 mt-1'>{campaign.campaign_title}</h2>
            <p className='text-[13px] text-themeGrey-300 capitalize '>{campaign.description?.length > 25 ? campaign.description.substr(0, 25) : campaign.description}</p>
            <div className='transition-all ease-in duration-100 hh flex justify-between items-center absolute top-5 px-3 w-full h-4'>
                <span className='glasssss  text-xs font-medium text-black rounded-full px-2 py-1'>
                 {campaign.brand_title}
                </span>

                <span onClick={favorite} className='rounded-full p-0.5 glasssss'>
                    <IoHeartSharp className={`${isfavorite ? 'text-[#F54363]' : 'text-themeGrey-150/70'} drop-shadow-[0_0_4px_rgba(255,255,255)] text-2xl`} />
                </span>

            </div>
            <div className='transition-all ease-in duration-100 hh flex justify-between items-center absolute top-[220px] sm:top-[250px] px-3 w-full h-4'>
                <span className='text-xs glasssss  font-medium text-black rounded-full px-2 py-1'>
                    {campaign.joined || 0}/{campaign.creator_count}
                </span>
                <span className='glasssss  text-xs font-medium text-black rounded-full px-2 py-1'>
                    {getChatTime(new Date(campaign.start_date))}
                </span>
            </div>
        </Link>
    );
}

export default CampaignCard;
